import { Component } from '@angular/core';
import { AuthService } from '../_services/auth.service';

@Component({
    selector: 'app-epl-login',
    templateUrl: './epl-login.component.html',
    styleUrls: ['./epl-login.component.css']
})
export class EplLoginComponent {
    constructor(public authService: AuthService) { }
}
