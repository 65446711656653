<h2>Last {{ limit }} API Errors</h2>
<div>
    <mat-form-field>
        <mat-label>Limit</mat-label>
        <input matInput type="number" [(ngModel)]="limit" (ngModelChange)="onLimitChange()">
    </mat-form-field>
</div>
<table *ngIf="!selectedError" mat-table [dataSource]="apiErrors | async">
    <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let error">{{ error.createdDate.toDate() | date:'medium' }}</td>
    </ng-container>
    <ng-container matColumnDef="schoolId">
        <th mat-header-cell *matHeaderCellDef>School ID</th>
        <td mat-cell *matCellDef="let error">{{ error.schoolId }}</td>
    </ng-container>
    <ng-container matColumnDef="errorMsg">
        <th mat-header-cell *matHeaderCellDef>Error Message</th>
        <td mat-cell *matCellDef="let error">{{ error.errorMsg }}</td>
    </ng-container>
    <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef>Details</th>
        <td mat-cell *matCellDef="let error">
            <button mat-raised-button color="primary" (click)="onSelect(error)">View Details</button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['details','createdDate', 'schoolId', 'errorMsg']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['details','createdDate', 'schoolId', 'errorMsg']"></tr>
</table>
<div *ngIf="selectedError">
    <h3>{{ selectedError.errorMsg }}</h3>
    <button (click)="onClose()">Close</button>
    <a [href]="'https://console.firebase.google.com/u/0/project/rewirenpc/firestore/data/~2FapiErrors~2F' + selectedError.id" target="_blank">Go to Console</a>
    <p>{{ selectedError.createdDate.toDate() | date:'medium' }}</p>
    <p>{{ selectedError.apiResponseMessage | json}}</p>
    <table mat-table [dataSource]="selectedError.answers">
        <ng-container matColumnDef="questionId">
            <th mat-header-cell *matHeaderCellDef>Question ID</th>
            <td mat-cell *matCellDef="let answer">{{ answer.questionId }}</td>
        </ng-container>
        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <td mat-cell *matCellDef="let answer">{{ answer.value }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['questionId', 'value']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['questionId', 'value']"></tr>
    </table>

</div>