import { Component, Inject } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export interface DialogData {
  title: string;
  helpVideoName: string;
}

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.css']
})
export class HelpDialogComponent {
  helpVideoUrl: Observable<string>;
  constructor(
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<HelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

    this.helpVideoUrl = this.storage.ref('_help/' + this.data.helpVideoName + '.mp4').getDownloadURL();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
