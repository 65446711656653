<!-- TODO: Add accessible-form from Student app -->
<form fireForm [path]="documentPath" [formGroup]="poForm" novalidate (stateChange)="changeHandler($event)">

  <!-- <mat-form-field [hideRequiredMarker]="true" style="width: calc(100% - 40px);" class="form-font "> -->
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Set Category Caps and Floors</h2>
      </mat-card-title>
      <mat-card-subtitle>Optional caps and floors applied to funding categories after package assignments
      </mat-card-subtitle>
    </mat-card-header>
    <div>
      <mat-card-content>
        <!-- <div formArrayName="overrides"> -->
        <table class="table">
          <tr>
            <th scope="col">Funding Category</th>
            <th scope="col">Cap</th>
            <th scope="col">Floor</th>
          </tr>


          <tr formGroupName="institutionalScholarship">

            <th scope="row"> Institutional Scholarship</th>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="cap" aria-label="Institutional Scholarship Cap">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="floor" aria-label="Institutional Scholarship Floor">
              </mat-form-field>
            </td>

          </tr>


          <tr formGroupName="institutionalGrant">
            <th scope="row"> Institutional Grant </th>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="cap" aria-label="Institutional Grant Cap">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="floor" aria-label="Institutional Grant Floor">
              </mat-form-field>
            </td>
          </tr>
          <tr formGroupName="stateGrant">
            <th scope="row"> State Grant </th>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="cap" aria-label="State Grant Cap">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="floor" aria-label="State Grant Floor">
              </mat-form-field>
            </td>
          </tr>
          <tr formGroupName="studentLoan">
            <th scope="row"> Student Loan </th>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="cap" aria-label="Student Loan Cap">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="floor" aria-label="Student Loan Floor">
              </mat-form-field>
            </td>
          </tr>
          <tr formGroupName="schoolDefinedFund1">
            <th scope="row"> School Defined Fund 1</th>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="cap" aria-label="School Fund 1 Cap">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="floor" aria-label="School Fund 1 Floor">
              </mat-form-field>
            </td>
          </tr>
          <tr formGroupName="schoolDefinedFund2">
            <th scope="row"> School Defined Fund 2</th>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="cap" aria-label="School Fund 2 Cap">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input matInput placeholder="Edit" type="number" formControlName="floor" aria-label="School Fund 2 Floor">
              </mat-form-field>
            </td>
          </tr>

        </table>
        <!-- </div> -->
      </mat-card-content>
    </div>

    <div *ngIf="state=='error'" class="alert alert-warning" role="alert">
      An error has occurred attempting to save changes
    </div>
    <button mat-raised-button color="primary" type="submit" [disabled]="!poForm.valid || state != 'modified'" class="btn btn-primary">
      {{state == 'modified' ? 'Save Changes' : 'No unsaved changes'}}
    </button>
  </mat-card>

</form>