import { Component, OnChanges, Input } from '@angular/core';
import { UsedBy, CalcConfigService } from '_services/calc-config.service';
import { filter } from 'lodash-es';

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss']
})
export class UsageComponent implements OnChanges {
  @Input() entityType: 'Population' | 'Table' | 'Formula' | 'Question';
  @Input() entityId: string;

  // filteredList: UsedBy[] = [];

  constructor(
    public cc: CalcConfigService
  ) { }

  ngOnChanges() {
    // this.filteredList = filter(this.cc.usedBy.value, {'entityId': this.entityId, 'entityType': this.entityType});
  }

  filteredList(usedBy: UsedBy[]): UsedBy[] {
    return filter(usedBy, { 'entityId': this.entityId, 'entityType': this.entityType });
  }

  getColor(item: UsedBy): any {
    // Current palette is level 700 from this page: https://material.io/design/color/the-color-system.html#tools-for-picking-colors
    switch (item.useType) {
      case 'Package': return '#00599d';
      case 'Cost': return '#512DA8';
      case 'Table': return '#006617';
      case 'Formula': return '#E64A19';
      case 'Population': return '#C2185B';
      case 'Next Step': return '#5D4037';
    }
  }

  getIcon(item: UsedBy): any {
    switch (item.useType) {
      case 'Package': return './assets/images/icons8-open_box.svg';
      case 'Cost': return './assets/images/icons8-money.svg';
      case 'Table': return './assets/images/icons8-data_sheet_filled.svg';
      case 'Formula': return './assets/images/icons8-math.svg';
      case 'Population': return './assets/images/icons8-groups.svg';
      case 'Next Step': return './assets/images/icons8-web_design.svg';
    }
  }

}
