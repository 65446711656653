<div class="form">
    <h3>NPC Admin</h3>
    <hr>
    @if (authService.eplLoginError$ | async; as error) {
    <div class="alert alert-warning" role="alert">
        <p>We were unable to log you in.</p>
        <p>{{error}}</p>
        <p>Please contact <a href="mailto:npc_support@rewiretech.com">npc_support&#64;rewiretech.com</a> for assistance</p>
    </div>
    } @else {
    <div class="spinner-container">
        <mat-spinner [diameter]="75"></mat-spinner>
        <p>Signing you in...</p>
    </div>
    }
</div>