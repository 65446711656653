import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CloudInasResponse } from 'app/_models/result';


interface displayObject {
  key: string,
  draftValue: string | number | null,
  publishedValue: string | number | null,
  class: 'subtotal' | 'total' | 'single-row' | ''
}

@Component({
  selector: 'app-inas-comparison',
  templateUrl: './inas-comparison.component.html',
  styleUrls: ['./inas-comparison.component.css']
})


export class InasComparisonComponent implements OnChanges {
  @Input() draft: CloudInasResponse;
  @Input() published: CloudInasResponse;
  @Input() institutionalMethod: boolean = false;

  keys: displayObject[] = [];
  isNotNumber = isNaN;

  resultTypes: { typeName: string, displayObjects: displayObject[] }[] = [];

  miscCalcs: displayObject[] = [];
  imResults: displayObject[] = [];


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.draft || changes.published) {
      this.miscCalcs = this.populateObects('MiscCalculations', 0);

      // Only add parent information if it exists
      if (this.populateObects('EfmParent', 5).length > 0) {
        if (this.institutionalMethod) { this.resultTypes.push({ typeName: 'IM Parents', displayObjects: this.populateObects('ImParent', 2) }) };
        this.resultTypes.push({ typeName: 'EFM Parents', displayObjects: this.populateObects('EfmParent', 5) });
      }


      if (this.institutionalMethod) { this.resultTypes.push({ typeName: 'IM Student', displayObjects: this.populateObects('ImStudent', 4) }) };
      this.resultTypes.push({ typeName: 'EFM Student', displayObjects: this.populateObects('EfmStudent', 6) });

      if (this.institutionalMethod) {
        const imParents = this.resultTypes.find(x => x.typeName === 'IM Parents')?.displayObjects.find(x => x.key === 'Contribution For Student');
        const imStudent = this.resultTypes.find(x => x.typeName === 'IM Student')?.displayObjects.find(x => x.key === 'Contribution For Student');

        if (imParents && imStudent) {
          this.imResults.push({
            key: 'Parent Contribution for Student',
            draftValue: imParents.draftValue,
            publishedValue: imParents.publishedValue,
            class: null
          });
          this.imResults.push({
            key: 'Student Contribution for Student',
            draftValue: imStudent.draftValue,
            publishedValue: imStudent.publishedValue,
            class: null
          });
        }
      }
    }
  }

  populateObects(keyName: string, resultType: number): displayObject[] {
    try {
      if (keyName === 'MiscCalculations') {
        const draftObject = this.draft.RunNeedAnalysisResult.NeedAnalysisData.MiscCalculations || this.draft.RunNeedAnalysisResult.NeedAnalysisData[0].MiscCalculations;
        const publishedObject = this.published.RunNeedAnalysisResult.NeedAnalysisData.MiscCalculations || this.published.RunNeedAnalysisResult.NeedAnalysisData[0].MiscCalculations;
        return this.getAllKeys(draftObject, publishedObject, keyName);
      }

      const draftObject = this.draft.RunNeedAnalysisResult.NeedAnalysisData.NeedAnalysisResultSet?.[keyName] || this.draft.RunNeedAnalysisResult.NeedAnalysisData[0].NeedAnalysisResultSet?.NeedAnalysisResult.find(x => x.ResultType === resultType);
      const publishedObject = this.published.RunNeedAnalysisResult.NeedAnalysisData.NeedAnalysisResultSet?.[keyName] || this.published.RunNeedAnalysisResult.NeedAnalysisData[0].NeedAnalysisResultSet?.NeedAnalysisResult.find(x => x.ResultType === resultType);
      return this.getAllKeys(draftObject, publishedObject);
    } catch (error) {
      return [];
    }

  }


  getAllKeys(obj1: any, obj2: any, resultType?: string): displayObject[] {
    if (obj1 === undefined || obj2 === undefined) {
      console.log('obj1', obj1);
      console.log('obj2', obj2);
      return [];
    }

    function convertNumberToString(value: number | string) {

      if (typeof value === 'number') {
        return value.toLocaleString('en-US', { maximumFractionDigits: 2 })
      }
      return value;
    }

    function getDisplayKey(key: string): string {
      const keyWithSpaces = key.replace(/([a-z])([A-Z])/g, '$1 $2')
      // Add in custom capitalization for certain keys
      switch (keyWithSpaces) {
        case 'Efm SAI': return 'EFM SAI';
        case 'Im Allocation Percent': return 'Number in College Adjustment';
        case 'Ira Retirement': return 'IRA Retirement';
        case 'Aesa Allowance': return 'AESA Allowance';
        case 'Cesa Allowance': return 'CESA Allowance';
        case 'USIncome Tax': return 'US Income Tax';
        default: return keyWithSpaces;
      }
    }

    function getClass(key: string): 'subtotal' | 'total' | 'single-row' | '' {
      const subtotalKeys = ['TotalIncome', 'TotalAllowances', 'NetWorth',];
      const totalKeys = ['AvailableIncome', 'DiscretionaryNetWorth', 'ContributionForStudent'];
      const singleRowKeys = ['ContributionFromIncome'];
      if (singleRowKeys.includes(key)) {
        return 'single-row';
      }
      if (subtotalKeys.includes(key)) {
        return 'subtotal';
      }
      if (totalKeys.includes(key)) {
        return 'total';
      }
      return '';
    }

    const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

    // Remove keys that are not needed
    allKeys.delete('ResultType');
    allKeys.delete('Methodology');
    allKeys.delete('UniqueProcessingIdentifier');
    allKeys.delete('InasVersion');
    allKeys.delete('ValueOfOtherAssets'); // per email from Zayda 8/29/23

    if (resultType === 'MiscCalculations') {
      if (allKeys.has('EfmSAI')) {
        // Only use the keys EfmSai and MetPovertyGuidelines
        allKeys.clear();
        allKeys.add('EfmSAI');
        allKeys.add('MetPovertyGuidelines');
      } else {
        allKeys.clear();
      }
    }

    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    const allKeyArray = Array.from(allKeys)
      .sort((a, b) => {

        const indexA = Math.min(obj1Keys.indexOf(a) === -1 ? 100 : obj1Keys.indexOf(a), obj2Keys.indexOf(a) === -1 ? 100 : obj2Keys.indexOf(a));
        const indexB = Math.min(obj1Keys.indexOf(b) === -1 ? 100 : obj1Keys.indexOf(b), obj2Keys.indexOf(b) === -1 ? 100 : obj2Keys.indexOf(b));
        return indexA - indexB;
      });


    // If values end of being different types, we can add a property to the object to indicate that
    return allKeyArray.map(key => ({
      // key: obj2Keys.indexOf(key) + ' ' + obj1Keys.indexOf(key) + ' ' + getDisplayKey(key),
      key: getDisplayKey(key),
      draftValue: convertNumberToString(obj1[key]),
      publishedValue: convertNumberToString(obj2[key]),
      class: getClass(key)
    }));

  }
}