<div class="title-bar">
  <h1>FORMULAS</h1>
  <app-help screen="formulas"></app-help>
  <ng-container *ngIf="!editMode">
    <button mat-flat-button color="primary" [disabled]="tabIndex === 0" (click)="setMode('edit')">
      {{tabIndex == 0 ? 'Select Formula to Edit' : 'Edit Formula'}}
    </button>
  </ng-container>
  <ng-container *ngIf="editMode">
    <button mat-flat-button color="accent" [disabled]="tabIndex === 0" (click)="setMode(delete.checked ? 'delete' : 'save')">
      <span *ngIf="delete.checked">Delete Formula</span>
      <span *ngIf="!delete.checked">Save Formula</span>
    </button>
    <button mat-flat-button color="warn" (click)="setMode('cancel')">Cancel</button>
    <mat-checkbox style="margin-left: 2rem;" #delete>Delete Formula</mat-checkbox>
  </ng-container>
  <app-aid-year style="margin-left:auto;" [disabled]="editMode"></app-aid-year>
</div>

<mat-tab-group mat-stretch-tabs="false" dynamicHeight [selectedIndex]="tabIndex" (selectedIndexChange)="changeTab($event)">
  <mat-tab label="Listing">
    <mat-card appearance="outlined">
      <div *ngIf="cc.formulas | async as formulas">
        <div *ngIf="formulas.length > 0">
          <table class="table table-hover" style="width:auto">
            <thead>
              <th scope="col">Name</th>
              <!-- <th scope="col">Formula</th> -->
              <th scope="col">Usage
                <br>
                <span class="legend-population">Population</span>
                <span class="legend-cost">Cost</span>
                <br>
                <span class="legend-package">Package</span>
                <span class="legend-table">Table</span>
              </th>
            </thead>
            <tbody>

              <tr *ngFor="let formula of formulas; let i = index" (click)="clickedRow(formula)">
                <th scope="row">
                  <span class="link">{{formula.name}}</span>
                  <div class="description" [innerHTML]="formula.description"></div>
                </th>
                <!-- <td>
                {{formula.formula}}
              </td> -->
                <td>
                  <app-usage entityType="Formula" [entityId]="formula.formulaId"></app-usage>
                </td>
              </tr>
              <tr>
                <td>
                  <button mat-button color="secondary" (click)="addFormula(formulas)">add new formula</button>
                </td>
                <!-- <td></td> -->
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <br>
        <div *ngIf="formulas.length === 0">
          There are no formulas <button mat-button color="secondary" (click)="addFormula(formulas)">add new
            formula</button>
        </div>
      </div>
    </mat-card>
  </mat-tab>

  <mat-tab [label]="formulaName" [disabled]="formulaName === ''">
    <app-formula *ngIf="formulaName !== '' && !editMode" [formula]="formula" [editMode]="editMode"></app-formula>
    <app-formula *ngIf="formulaName !== '' && editMode" [formula]="formulaCopy" [editMode]="editMode"></app-formula>
  </mat-tab>

</mat-tab-group>