import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';

@Component({
  selector: 'app-help',
  template: `
  <button mat-button color="primary" (click)="openHelp()">help</button>
  `,
  styles: []
})
export class HelpComponent implements OnInit {

  constructor(
    public dialog: MatDialog
  ) { }

  // tslint:disable-next-line:max-line-length
  @Input() screen: 'config' | 'branding' | 'content' | 'costs' | 'dashboard' | 'formulas' | 'inspector' | 'packaging' | 'populations' | 'questions' | 'tables';

  ngOnInit() {
  }

  openHelp(): void {
    let title = 'Help';
    let helpVideoName = '';

    switch (this.screen) {
      case 'config':
        title = 'Configuration Help';
        helpVideoName = 'configuration';
        break;
      case 'branding':
        title = 'Branding Help';
        helpVideoName = 'branding';
        break;
      case 'content':
        title = 'Content Help';
        helpVideoName = 'content';
        break;
      case 'costs':
        title = 'Costs Help';
        helpVideoName = 'costs';
        break;
      case 'dashboard':
        title = 'Dashboard Help';
        helpVideoName = 'dashboard';
        break;
      case 'formulas':
        title = 'Formulas Help';
        helpVideoName = 'formulas';
        break;
      case 'inspector':
        title = 'Inspector Help';
        helpVideoName = 'inspector';
        break;
      case 'packaging':
        title = 'Packaging Help';
        helpVideoName = 'packaging';
        break;
      case 'populations':
        title = 'Populations Help';
        helpVideoName = 'population';
        break;
      case 'questions':
        title = 'Questions Help';
        helpVideoName = 'questions';
        break;
      case 'tables':
        title = 'Tables Help';
        helpVideoName = 'tables';
        break;
    }



    this.dialog.open(HelpDialogComponent, {
      width: '1050px',
      data: { title: title, helpVideoName: helpVideoName }
    });
  }
}
