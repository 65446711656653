<div class="title-bar">
  <h1>CONTENT</h1>
  <app-help screen="content"></app-help>
  <button mat-raised-button color="primary" type="submit" [disabled]="!contentForm.valid || state != 'modified'">
    {{state == 'modified' ? 'Save Changes' : 'No unsaved changes'}}
  </button>
  <app-draft-mode></app-draft-mode>
</div>
<span *ngIf="state=='error'" class="alert alert-warning" role="alert">
  An error has occurred attempting to save changes
</span>
<app-publish-preferences></app-publish-preferences>
<form fireForm [path]="documentPath" [formGroup]="contentForm" novalidate (stateChange)="changeHandler($event)">
  <mat-card appearance="outlined">
    <div formGroupName="content" style="max-width:800px; margin-left: 1rem;">

      <!-- <div formGroupName="content" style="max-width:800px"> -->
      <mat-tab-group mat-stretch-tabs="false" dynamicHeight (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Welcome">
          <p>The content displayed on the first page of your net price calculator.
          </p>
          <quill-editor [modules]="modules" theme="snow" formControlName="welcomeIntro"></quill-editor>
        </mat-tab>
        <mat-tab label="Welcome Notes">

          <br>
          <p>A set of considerations for the user prior to them starting the application.</p>

          <quill-editor [modules]="modules" theme="snow" formControlName="welcomeDisclaimer"></quill-editor>

        </mat-tab>
        <mat-tab label="Next Steps">

          <br>
          <p>Instructions for what the student can do next in the process.</p>
          <quill-editor [modules]="modules" theme="snow" formControlName="nextSteps"></quill-editor>

        </mat-tab>
        <!-- <mat-tab label="School Question Header">

          <br>
          <p>The text that appears on the page of your school specific questions.</p>
          <quill-editor [modules]="modules" formControlName="schoolQuestionHeader"></quill-editor>

        </mat-tab> -->

      </mat-tab-group>
    </div>
  </mat-card>
</form>