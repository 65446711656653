
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { Result, AdminSession } from '../_models/result';

@Injectable()
export class InspectorService {
  sessionCollection: AngularFirestoreCollection<AdminSession>;
  sessions: Observable<AdminSession[]>;
  loadedSchoolId = '';
  token = '';

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private fns: AngularFireFunctions,
  ) { }

  public async loadSessions(schoolId: string, all: boolean) {
    if (schoolId !== this.loadedSchoolId) {
      if (all) {
        this.loadAll(schoolId);
        // this.sessions = this.afs.collection<Session>('sessions_admin',
        //   ref => ref.where('univName', '==', schoolId)).valueChanges();
      } else {
        const user = await this.auth.currentUser();

        this.sessions = this.afs.collection<AdminSession>('sessions_admin',
          ref => ref.where('userId', '==', user.uid)
            .where('univName', '==', schoolId)).valueChanges();
      }
      this.loadedSchoolId = schoolId;
    }
  }


  public async loadAll(schoolId) {
    const sessionCol = this.afs.collection<AdminSession>('sessions_admin',
      ref => ref.where('univName', '==', schoolId).orderBy('savedAt', 'desc'));
    this.sessions = sessionCol.snapshotChanges().pipe(
      map(qs => {
        return qs.map(a => {
          const data = a.payload.doc.data() as AdminSession;
          const id = a.payload.doc.id; // This is needed if we remove "id" from the record
          const sessionName = data.answers.find(a => a.questionId === 'student.firstName')?.value || '';
          return { id, sessionName, ...data };
        });
      }));
  }

  public deleteAdminSession(sessionId: string) {
    this.afs.collection('sessions_admin').doc(sessionId).delete();
  }


  public cloudCalc(schoolId: string, sessionId: string, publishedRules: boolean): Observable<Result> {

    const callable = this.fns.httpsCallable('calcApiCall');
    const adminSession = true;
    const adminUsePublishedRules = publishedRules;
    return callable({ schoolId, sessionId, adminSession, adminUsePublishedRules })
    // .pipe(
    //   catchError((error) => {
    //     console.error('Error getting session from cloud function:', error);
    //     return of(null);
    //   })
    // );
  }


  public async createAdminSession(sessionId: string) {
    const sessionPromise = await this.afs.doc('sessions/' + sessionId).get().toPromise();
    const session: AdminSession = sessionPromise.data() as AdminSession;
    if (session) {
      if (session.univName !== this.loadedSchoolId) {
        return 'This session is for a different school';
      }
      const user = await this.auth.currentUser();

      const adminSession = {
        answers: session.answers,
        preparedAnswers: session.preparedAnswers,
        cbUserId: session.cbUserId,
        savedAt: session.savedAt,
        univName: session.univName,
        userId: user.uid
      };
      this.afs.collection('sessions_admin').add(adminSession);
      return 'Success';
    } else {
      return 'No session found';
    }
  }

}
