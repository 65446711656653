import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { convertTimestamp } from 'app/_pipes/convert-firestore-timestamp.pipe';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface Timestamp {
  toDate(): Date;
}

interface ApiError {
  id: string;
  answers: [];
  errorMsg: string;
  apiResponseMessage: any;
  createdDate: Timestamp;
}

@Component({
  selector: 'app-api-errors',
  templateUrl: './api-errors.component.html',
  styleUrls: ['./api-errors.component.css']
})
export class ApiErrorsComponent implements OnInit {
  private apiErrorsCollection: AngularFirestoreCollection<ApiError>;
  apiErrors: Observable<ApiError[]>;
  selectedError: ApiError | null = null;
  limit = 30;

  constructor(private afs: AngularFirestore) {
    this.apiErrorsCollection = afs.collection<ApiError>('apiErrors', ref => ref.orderBy('createdDate', 'desc').limit(this.limit));
    this.getApiErrors();
    // this.apiErrors = this.apiErrorsCollection.snapshotChanges().pipe(
    //   map(actions => actions.map(a => {
    //     const data = a.payload.doc.data() as ApiError;
    //     const id = a.payload.doc.id;
    //     return { id, ...data };
    //   }))
    // );
  }

  getApiErrors() {
    this.apiErrors = this.afs.collection<ApiError>('apiErrors', ref => ref.orderBy('createdDate', 'desc').limit(this.limit)).valueChanges();
  }

  ngOnInit(): void {
  }

  onSelect(error: ApiError): void {
    this.selectedError = error;
  }

  onLimitChange() {
    if (this.limit > 0 && this.limit <= 1000) {
      this.getApiErrors();
    }
  }

  onClose(): void {
    this.selectedError = null;
  }
}