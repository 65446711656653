<div *ngIf="cc.formulas | async as formulas">
  <div *ngIf="cc.tables | async as tables">
    <div style="display: flex; flex-direction: row">
      <mat-form-field style="width: 8rem;">
        <mat-select placeholder="Input Type" [(value)]="key.type" [aria-labelledby]="'Input Type ' + key.type">
          <mat-option value="Question">Question</mat-option>
          <mat-option value="Calculation">Calculation</mat-option>
          <mat-option *ngIf="caller !== 'Formula'" value="Formula" [disabled]="formulas.length === 0">
            {{formulas.length !== 0 ? 'Formula' : 'No Formulas'}}</mat-option>
          <mat-option *ngIf="caller === 'Formula'" value="Table">Table</mat-option>
        </mat-select>

      </mat-form-field>
      <app-key-select *ngIf="key.type ==='Calculation'" style="margin-left: 1em" [value]="key.keyId" (change)="setKey($event)"></app-key-select>

      <mat-form-field *ngIf="key.type ==='Question' && (caller === 'Formula' || caller === 'Package')" style="margin-left: 1em; width: 30rem;">
        <mat-select placeholder="Question" [(value)]="key.keyId" [aria-labelledby]="'Question ' + getQuestionName(key.keyId)" (selectionChange)="asPart(key).question=qs.getQuestion(key.keyId)">
          <mat-option *ngFor="let q of qs.numericDraftQuestions | async" [value]="q.id">{{q.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="key.type ==='Question' && (caller === 'Population' || caller === 'Table')" style="margin-left: 1em; width: 30rem;">
        <mat-select placeholder="Question" [(value)]="key.keyId" [aria-labelledby]="'Question ' + getQuestionName(key.keyId)" (selectionChange)="asPart(key).question=qs.getQuestion(key.keyId)">
          <mat-option *ngFor="let q of qs.draftQuestions | async" [value]="q.id">{{q.label}}</mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field *ngIf="key.type ==='Formula'" style="margin-left: 1em; width: 30rem;">
        <mat-select placeholder="Formula" [(value)]="asRule(key).formulaId" (selectionChange)="setFormula(asRule(key).formulaId)" [aria-labelledby]="'Formula ' + getFormulaName(asRule(key).formulaId)">
          <mat-option *ngFor="let f of formulas" [value]="f.formulaId">{{f.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Only used when called by Formula -->
      <mat-form-field *ngIf="key.type ==='Table'" style="margin-left: 1em; width: 30rem;">
        <mat-select placeholder="Table" [(value)]="asTable(key).tableId" [aria-labelledby]="'Table ' + getTableName(asTable(key).tableId)">
          <mat-option *ngFor="let t of tables" [value]="t.tableId">{{t.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>