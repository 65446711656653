import { Component, OnInit } from '@angular/core';
import { Chart, ChartDataset, ChartOptions } from 'chart.js';
import { BehaviorSubject } from 'rxjs';
import { TrafficService } from '_services/traffic.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';


@Component({
  selector: 'app-traffic-chart',
  templateUrl: './traffic-chart.component.html',
  styleUrls: ['./traffic-chart.component.css']
})
export class TrafficChartComponent implements OnInit {
  public lineChartData: ChartDataset[];
  public lineChartOptions: ChartOptions;


  public lineData = new BehaviorSubject<ChartDataset[]>([]);
  constructor(public ts: TrafficService) {
    Chart.register(ChartDataLabels);
   }

  ngOnInit() {

    this.formatChart();
  }



  private formatChart() {
    this.lineData.next([]);

    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 20
        },
      },
      scales: {
        'Results': {
          type: 'linear',
          position: 'left',
        },
        'Completion': {
          type: 'linear',
          position: 'right',
          max: 100,
          min: 0
        }
      },
      plugins: {
        datalabels: {
          backgroundColor: 'rgba(255,255,255,1)',
          borderColor: 'rgba(0,0,0,.2)',
          borderRadius: 6,
          borderWidth: 1,
          color: 'black',
          font: {
            size: 10
          },
          formatter: Math.round
          // Add commas - https://github.com/chartjs/Chart.js/issues/4536
        }
      }
    };

    // this.lineChartColors = [
    //   {
    //     backgroundColor: 'rgba(0,63,125,0.2)',
    //     borderColor: 'rgba(0,63,125,1)',
    //     pointBackgroundColor: 'rgba(148,159,177,1)',
    //     pointBorderColor: '#fff',
    //     pointHoverBackgroundColor: '#fff',
    //     pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    //   },
    //   {
    //     backgroundColor: 'rgba(77,83,96,0.2)',
    //     borderColor: 'rgba(77,83,96,0)',
    //     pointBackgroundColor: 'rgba(77,83,96,1)',
    //     pointBorderColor: '#fff',
    //     pointHoverBackgroundColor: '#fff',
    //     pointHoverBorderColor: 'rgba(77,83,96,1)'
    //   }
    // ];
  }
}
