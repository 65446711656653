<div style="display: flex">
  <ng-container *ngIf="cc.aidYearChanges | async as aidYearChanges">
    <button mat-icon-button matTooltip="Aid Year Changes" (click)="openDialog(aidYearChanges)">
      <mat-icon svgIcon="aid-year" aria-hidden="false" aria-label="Example thumbs up SVG icon"></mat-icon>
    </button>
  </ng-container>
  <div class="aid-year">
    <mat-form-field style="width: 10rem" appearance="outline" subscriptSizing="dynamic">
      <mat-label>Aid Year</mat-label>
      <mat-select [(value)]="selectedAidYear" (selectionChange)="selectionChange()" [disabled]="disabled">
        <mat-option *ngFor="let option of aidYearOptions" [value]="option.value" [disabled]="option.hide">{{ option.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
