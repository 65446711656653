<div class="title-bar">
  <h1>CONFIGURATION</h1>
  <app-help screen="config"></app-help>
  <button mat-raised-button color="primary" type="submit" [disabled]="!configForm.valid || state != 'modified'">
    {{ state == "modified" ? "Save Changes" : "No unsaved changes" }}
  </button>
  <app-draft-mode></app-draft-mode>
</div>
<span *ngIf="state == 'error'" class="alert alert-warning" role="alert"> An error has occurred attempting to save changes </span>
<app-publish-preferences></app-publish-preferences>
<!-- TODO: Add accessible-form from Student app -->
<form fireForm [path]="documentPath" [formGroup]="configForm" novalidate (stateChange)="changeHandler($event)">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>General Settings</h2>
      </mat-card-title>
      <mat-card-subtitle>Settings used to define the student application</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <mat-form-field [hideRequiredMarker]="true" class="form-field">
        <!-- <mat-label>University Name</mat-label> -->
        <input matInput placeholder="University Name" type="text" formControlName="universityName" />
        <mat-error>Please enter a name</mat-error>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" class="form-field">
        <input matInput #shortName placeholder="Short Name used for Navigtation Menu" maxlength="40" type="text" formControlName="shortName" />
        <mat-hint align="start">20 character limit</mat-hint>
        <mat-hint align="end">{{ shortName.value.length }} / 20</mat-hint>
        <mat-error>Please enter a short name</mat-error>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" class="form-field">
        <input matInput placeholder="Exit URL" type="text" formControlName="npcExitUrl" />
        <mat-error>Please enter a URL</mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="showEFC">Show SAI or Family Contribution on Results</mat-checkbox>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Package Name Overrides</h2>
      </mat-card-title>
      <mat-card-subtitle>Customize the names that display for your packages on the results page</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <mat-form-field class="form-field">
        <input matInput placeholder="State Grant" type="text" formControlName="stateGrant" />
      </mat-form-field>

      <mat-form-field class="form-field">
        <input matInput placeholder="Institutional Grant" type="text" formControlName="institutionalGrant" />
      </mat-form-field>

      <mat-form-field class="form-field">
        <input matInput placeholder="Institutional Scholarship" type="text" formControlName="institutionalScholarship" />
      </mat-form-field>

      <mat-form-field class="form-field">
        <input matInput placeholder="Consolidated Self-Help" type="text" formControlName="consolidatedSelfHelp" />
      </mat-form-field>

      <mat-form-field class="form-field">
        <input matInput placeholder="School Defined Fund 1" type="text" formControlName="schoolDefinedFund1" />
      </mat-form-field>

      <mat-form-field class="form-field">
        <input matInput placeholder="School Defined Fund 2" type="text" formControlName="schoolDefinedFund2" />
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Rounding Settings</h2>
      </mat-card-title>
      <mat-card-subtitle>Rounding is recommended as results are all estimates</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <mat-checkbox formControlName="useRounding" (change)="changeRounding()">Round results</mat-checkbox>
      <ng-container *ngIf="configForm.controls['useRounding']?.value === true">
        <mat-form-field style="margin-left: 1rem; width: 8rem">
          <mat-select placeholder="Round to nearest" formControlName="roundingValue" (selectionChange)="changeRounding()">
            <mat-option value="50">50</mat-option>
            <mat-option value="100">100</mat-option>
            <mat-option value="200">200</mat-option>
            <mat-option value="500">500</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <br />
      <mat-checkbox formControlName="efcRounding" (change)="changeRounding()">Round SAI or Family Contribution</mat-checkbox>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Cohort Phrase</h2>
      </mat-card-title>
      <mat-card-subtitle>This phrase is displayed on the bottom of the welcome page</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <blockquote>
        In
        <mat-form-field style="margin-left: 5px; width: 120px">
          <mat-select placeholder="Aid Year" formControlName="cohortAidPercentYear" [aria-labelledby]="configForm.value.cohortAidPercentYear">
            <mat-option value="2024-2025">2024-2025</mat-option>
            <mat-option value="2023-2024">2023-2024</mat-option>
            <mat-option value="2022-2023">2022-2023</mat-option>
            <mat-option value="2021-2022">2021-2022</mat-option>
            <mat-option value="2020-2021">2020-2021</mat-option>
            <mat-option value="2019-2020">2019-2020</mat-option>
            <mat-option value="2018-2019">2018-2019</mat-option>
            <mat-option value="2017-2018">2017-2018</mat-option>
            <mat-option value="2016-2017">2016-2017</mat-option>
            <mat-option value="2015-2016">2015-2016</mat-option>
            <mat-option value="2014-2015">2014-2015</mat-option>
            <mat-option value="2013-2014">2013-2014</mat-option>
          </mat-select>
        </mat-form-field>
        ,
        <mat-form-field style="margin-left: 5px; margin-right: 5px; width: 100px">
          <input matInput placeholder="Aid Percent" type="number" formControlName="cohortAidPercent" />
          <span matSuffix>%</span>
          <mat-error>Add a percent</mat-error>
        </mat-form-field>
        of our full-time beginning undergraduates <br />received grant/scholarship aid.
      </blockquote>
    </mat-card-content>
  </mat-card>

  <ng-container *ngIf="ups.activeUsers | async as activeList">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>
          <h2>Active Users</h2>
        </mat-card-title>
        <mat-card-subtitle>This list shows all users with access to your NPC</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <ol>
          <li *ngFor="let activeUser of activeList" style="margin-bottom: 0.5rem">{{ activeUser.email }}</li>
        </ol>
      </mat-card-content>
    </mat-card>
  </ng-container>
</form>
