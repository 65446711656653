import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { LoggingService } from '_services/logging.service';
import { tap, map, take } from 'rxjs/operators';

@Injectable()
export class AuthGuard {

  constructor(
    private auth: AuthService,
    private router: Router,
    private logging: LoggingService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    const schoolId = next.paramMap.get('schoolId') ?? '';

    return this.auth.user.pipe(
      take(1),
      map(user => {

        // TODO: If user is an admin and the school doesn't exist may want to redirect here.

        if (user) {
          const nextPathName = next.routeConfig?.path || '';
          this.logging.logUser(user, schoolId, nextPathName);
          // If user is an admin then they can access everything
          if (user.admin) {
            return true;
          }
          // If user has no schools then they can't access anything
          if (user.schools === null) {
            return false;
          }
          // If user has multiple schools then they can access the schools page
          if (user.schools.length > 1 && nextPathName === 'schools') {
            return true;
          }
          // If user has the school then they can access everything except admin routes
          if (user.schools.indexOf(schoolId) !== -1) {
            // Don't allow access to admin routes (superadmin and questions-admin)
            if (nextPathName.includes('admin')) {
              return false;
            }
            return true;
          }
        }
        return false;
      }),
      tap(isAllowed => {
        if (!isAllowed) {
          this.router.navigate(['/admin-login']);
          console.error('Access denied. You do not have access to this school');
        }
      })
    );
  }
}
