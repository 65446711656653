<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>
            <h2>Look & Feel</h2>
        </mat-card-title>
        <mat-card-subtitle>Changes are automatically saved to a draft copy of settings. View these changes by launching your NPC Preview.
            When everything looks good, publish your changes to your public application.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style="margin-top: 1rem;">
        <div *ngIf="ups.draftChanges | async; else noChanges">

            <button mat-flat-button color="primary" (click)="publishPreferences()">Publish Changes</button>
            <button mat-flat-button color="basic" style="margin-left:1rem" (click)="resetPreferences()">Reset Changes</button>

        </div>
        <ng-template #noChanges>
            There are no changes to publish
        </ng-template>
    </mat-card-content>
</mat-card>