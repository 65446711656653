// Shared module
// Use this module to export components, directives, and pipes.
// Do not provide services here

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { HeaderComponent } from './header/header.component';
import { NavmenuComponent } from './navmenu/navmenu.component';
import { HelpComponent } from './help/help.component';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';

import { FireFormDirective } from './fire-form.directive';
import { FormulaOnlyDirective } from './formula.directive';
import { UserFormComponent } from '../user-form/user-form.component';


import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AidYearComponent } from './aid-year/aid-year.component';
import { SelectorChangesDialogComponent } from './selector-changes-dialog/selector-changes-dialog.component';

const materialModules = [
    MatInputModule, MatFormFieldModule, MatButtonModule, MatExpansionModule, MatDialogModule, MatTableModule, MatPaginatorModule, MatSelectModule, MatSlideToggleModule, MatIconModule, MatTooltipModule, MatProgressSpinnerModule
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        materialModules
    ],
    declarations: [
        HeaderComponent,
        NavmenuComponent,
        FireFormDirective,
        FormulaOnlyDirective,
        UserFormComponent,
        HelpComponent,
        HelpDialogComponent,
        AidYearComponent,
        SelectorChangesDialogComponent
    ],
    exports: [
        HeaderComponent,
        NavmenuComponent,
        UserFormComponent,
        FireFormDirective,
        FormulaOnlyDirective,
        HelpComponent,
        AidYearComponent,
        materialModules
    ]
})
export class SharedModule { }
