import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment'; // Add this import

declare var cb: any;
interface AuthSession {
    basicProfile: {
        dataOrigin: string;
        emailAddress: string;
        firstName: string;
        hashedId: string;
        isCSR: boolean;
        isProfessional: boolean;
        isStudent: boolean;
        lastName: string;
        namespace: string;
        status: string;
        userName: string;
    };
    createTimeInMS: number;
    csrId: number;
    expireTimeInMS: number;
    isLoggedIn: boolean;
    loginTokenType: string;
    sessionId: string;
}

export interface EPLParsedAuthToken {
    cb: {
        aid: string;
        authnLevel: string;
        dp: {
            jobTitleCode: string;
            orgId: string;
        };
        em: string;
        env: string;
        ltt: string;
        ns: string;
        oktaAccountId: string;
        pid: string;
        un: string;
    };
    exp: number;
    iat: number;
    iss: string;
    sub: string;
}


@Injectable({
    providedIn: 'root'
})
export class EplService {
    authService = inject(AuthService);
    appId: '488' // NPC Admin App ID
    cb: any;
    // Redirect here if they do not have the NPC role 
    redirectUrl = 'https://cbaccount.collegeboard.org/professional/enroll?prgCode=NPCAdmin&appId=488';
    // TODO : Redirect somewhere else on logout
    logoutRedirectUrl = 'https://highered.collegeboard.org/financial-aid/current-user-resources'
    serviceInitiated = false;

    loggedIn = new BehaviorSubject(false);
    cbUserEmail = new BehaviorSubject('');
    eplServiceInitialized = new BehaviorSubject(false);
    constructor() { }

    // Called from external login guard
    initiateEpl() {
        if (this.serviceInitiated) {
            return;
        }

        this.authService.logout(false);

        if (environment.production) {
            try {
                console.log('EPL initialization started');
                console.log('Current cb object:', cb);
                console.log('Current DeploymentProfile:', cb.core.utils.DeploymentProfile);

                const iamBus = cb?.core?.iam?.getEventBus();
                console.log('IAM Event Bus:', iamBus);

                // Register events
                iamBus.on(cb?.core?.iam?.events?.AWSLogin, () => { this.onLogin(); });
                iamBus.on(cb?.core?.iam?.events?.Logout, () => { this.onLogout(); });
                iamBus.on(cb?.core?.iam?.events?.AWSLoginFailed, () => { this.onLoginFailed(); });

                this.eplServiceInitialized.next(true);
                console.log('EPL initialization complete');
            } catch (err) {
                this.eplServiceInitialized.next(false);
                console.error('Error initiating EPL:', err);
            }
        } else {
            console.log('EPL initialization skipped in non-production environment');
            this.eplServiceInitialized.next(false);
        }

        // Call onLogin regardless of the environment
        if (environment.production) {
            this.onLogin();
        } else {
            this.developmentLogin();
        }
        this.serviceInitiated = true; // Set to true as external login guard will not try to re-initiate EPL service
    }

    private onLogin() {
        console.log('ECL Logged in');

        const parsedAuthToken = this.getParsedAuthToken();
        console.log('ParsedAuth token:', parsedAuthToken);
        if (!parsedAuthToken) {
            window.location.href = this.redirectUrl;
            return;
        }

        this.authService.eplLogin(parsedAuthToken);
        return;
    }

    // Currently not used
    private developmentLogin() {
        this.authService.eplLogin(this.getSampleParsedAuthToken());
    }

    private onLogout() {
        console.log('ECL Logged out');
        this.loggedIn.next(false);
        this.cbUserEmail.next('');
        console.log('Auth session after logout:', cb.core.iam.getAuthSession());
        this.authService.logout();
    }




    private onLoginFailed() {
        console.log('ECL Login failed');
        this.loggedIn.next(false);
        this.cbUserEmail.next('');
        console.log('Auth session after login failed:', cb.core.iam.getAuthSession());
    }


    // Currently not used
    // Do we need the user email for any reason? 
    private parseAndSetUserEmail(): boolean {
        const token = cb?.core?.iam?.getAuthorizationToken();
        console.log('Authorization token:', token);
        if (token) {
            try {
                const parsed = JSON.parse(atob(token.split('.')[1]));
                console.log('Parsed token:', parsed);
                this.cbUserEmail.next(parsed.cb.em);
                // Check for specific roles or permissions here
                return true; // Replace with actual authorization logic
            } catch (e) {
                console.error('Error parsing token:', e);
            }
        }
        return false;
    }

    private getParsedAuthToken(): EPLParsedAuthToken {
        const token = cb?.core?.iam?.getAuthorizationToken();
        console.log('Authorization token:', token);
        if (token) {
            try {
                return JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
                console.error('Error parsing token:', e);
            }
        }
    }

    getSampleAuthSession(): AuthSession {
        return {
            basicProfile: {
                dataOrigin: 'CB',
                emailAddress: '',
                firstName: 'Production',
                hashedId: '1798351',
                isCSR: false,
                isProfessional: true,
                isStudent: false,
                lastName: 'Testingone',
                namespace: 'pf',
                status: 'A',
                userName: 'PRODUCTION15'
            },
            createTimeInMS: 1725277041352,
            csrId: null,
            expireTimeInMS: 1725284240490,
            isLoggedIn: true,
            loginTokenType: 'CBLogin',
            sessionId: '987850ED-352E-91EE-08EA-D5EB0FAA2E2B'
        }
    }
    getSampleParsedAuthToken(): EPLParsedAuthToken {
        return {
            cb: {
                aid: '963826',
                authnLevel: '21',
                dp: {
                    jobTitleCode: 'H7',
                    orgId: '2354',
                },
                em: 'production15@epsilon.cbreston.org',
                env: 'pine',
                ltt: 'CBLogin',
                ns: 'pf',
                oktaAccountId: '00uht7n84owKkrjtc5d7',
                pid: '834525',
                un: 'PRODUCTION15'
            },
            exp: 1725277943,
            iat: 1725277043,
            iss: 'catapult.collegeboard.org',
            sub: 'us-east-1:6637faee-1c1e-439f-a5de-97fbd242225e'
        }
    }


}
