import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, first, map, tap, take, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { User } from '../_models/user';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { EPLParsedAuthToken } from './epl.service';

@Injectable()
export class AuthService {

  user: Observable<User>;
  noUser = new BehaviorSubject(false);
  loggedInWithEPL = false;
  eplLoginError$ = new BehaviorSubject<string | null>(null);

  constructor(
    private firebaseAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private router: Router) {
    this.user = firebaseAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          if (user.isAnonymous) {
            // logs out a user that was cached from the student application
            this.logout();
          }
          this.noUser.next(false);
          return this.afs.doc<User>(`admin_users/${user.uid}`).valueChanges();
        } else {
          this.noUser.next(true);
          return of(null);
        }
      })
    );
  }


  currentUser(): Promise<User> {
    return this.user.pipe(first()).toPromise();
  }

  navigateUser(user: User) {

    if (!user) {
      return;
    }
    if (user.admin) {
      this.router.navigate(['schools']);
      return;
    }
    if (user.schools) {
      // If user only has one school, navigate to that school.
      if (user.schools.length === 1) {
        this.router.navigate([user.schools[0] + '/dashboard']);
        return;
      }
      // If user has multiple schools or is an admin, navigate to the schools page.
      if (user.schools.length > 1) {
        this.router.navigate(['schools']);
        return;
      }
    }

  }



  signup(email: string, password: string): Promise<void> {
    return this.firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then(newUser => {
        this.loggedInWithEPL = false;
        return this.createUserDoc(newUser.user);
      });
  }

  // 1. Enforce admin false
  // 2. Enforce schools null

  createUserDoc(aUser) {
    const userRef = this.afs.doc(`admin_users/${aUser.uid}`);
    const data: User = {
      uid: aUser.uid,
      name: aUser.email,
      email: aUser.email,
      schools: null,
      admin: false
    };
    return userRef.set(data);
  }

  login(email: string, password: string) {
    this.firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(value => {
        this.loggedInWithEPL = false;
        console.log('User logged in successfully');
        // Redirect to the authenticated root component after successful login
        this.router.navigate(['/']);
      })
      .catch(err => {
        console.log('Auth server says:', err.message);
        alert(err.message);
      });
  }

  logout(reroute: boolean = true): Promise<void> {
    this.noUser.next(true);
    return this.firebaseAuth
      .signOut()
      .then(() => {
        if (reroute) {
          if (this.loggedInWithEPL) {
            window.location.href = 'https://cbaccount.collegeboard.org/professional/enroll?prgCode=NPCAdmin&appId=488';
          } else {
            this.router.navigate(['/admin-login']);
            window.location.reload();
          }
        }
      });
  }

  resetPassword(email: string): Promise<void> {
    return this.firebaseAuth.sendPasswordResetEmail(email);
  }

  /**
   * Performs login using External Partner Login (EPL) token.
   * 
   * This function:
   * 1. Calls a Firebase Cloud Function to get a custom token
   * 2. Signs in the user with the custom token
   * 3. Sets Firebase persistence to 'session'
   * 4. Navigates the user to the dashboard of the specified school
   * 
   * If any errors occur during the process, they are logged to the console.
   * 
   * @param eplToken - The parsed EPL authentication token
   */

  async eplLogin(eplToken: EPLParsedAuthToken) {
    console.log('EPL Login: ', eplToken);

    if (!eplToken?.cb?.dp?.orgId) {
      console.error('EPL Login: No organization ID found in token');
      return;
    }

    const callable = this.fns.httpsCallable('adminCustomToken');
    callable(eplToken).pipe(
      take(1),
      map(async tokenData => {
        console.log(tokenData);
        const { customToken, schoolId } = tokenData;

        await this.firebaseAuth.signInWithCustomToken(customToken);
        this.loggedInWithEPL = true;
        // Set persistence to session to logout user on page/tab closed. HostListener in app.component.ts handles on navigating away but not closing tab
        this.firebaseAuth.setPersistence('session');
        this.router.navigate([schoolId + '/dashboard']);
      }),
      catchError(error => {
        // TODO: do something with error
        this.eplLoginError$.next(error.message);
        console.error('Error during EPL login:', error);
        return of(null);
      })
    ).subscribe();
  }

}
