<h1 mat-dialog-title id="helpTitle"><small>QUESTION:</small> {{ question.label }}</h1>

<mat-dialog-content>
  <p>Last Updated: {{ question.dateUpdated | date : "short" }}</p>
  <div class="question-text">
    <mat-form-field style="width: 100%">
      <mat-label>Question Text</mat-label>
      <input matInput id="question-text" [disabled]="!editMode" type="text" [value]="question.label" [(ngModel)]="question.label" />
    </mat-form-field>
  </div>

  <mat-form-field>
    <mat-label>Question ID</mat-label>
    <input matInput [disabled]="!!data" placeholder="Question ID" type="text" [value]="question.id" [(ngModel)]="question.id" />
  </mat-form-field>

  <mat-form-field style="margin-left: 2rem">
    <mat-label>Question Format</mat-label>
    <mat-select [disabled]="!editMode" [(value)]="question.format">
      <mat-option value="Code">Code</mat-option>
      <mat-option value="Number">Number</mat-option>
      <mat-option value="Integer">Integer</mat-option>
      <mat-option value="Boolean">Boolean</mat-option>
      <mat-option value="Text">Text</mat-option>
      <mat-option value="NumberAllowNegative">NumberAllowNegative</mat-option>
      <mat-option value="TextAsNumber">TextAsNumber</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field style="margin-left: 2rem">
    <mat-label>Section</mat-label>
    <mat-select [disabled]="!editMode" [(value)]="question.section_id">
      <mat-option value="ST">Starting</mat-option>
      <mat-option value="DS">Dependency</mat-option>
      <mat-option value="HP">Parent Household</mat-option>
      <mat-option value="PI">Parent Income</mat-option>
      <mat-option value="PA">Parent Assets</mat-option>
      <mat-option value="SF">Student Finances</mat-option>
      <mat-option value="SI">Custom</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field style="margin-left: 2rem">
    <mat-label>Order</mat-label>
    <input matInput [disabled]="!editMode" type="number" [value]="question.ordinal" [(ngModel)]="question.ordinal" />
  </mat-form-field>

  <div>
    Options:
    <mat-checkbox [disabled]="!editMode" [(ngModel)]="question.dependent">Dependent</mat-checkbox>
    <mat-checkbox [disabled]="!editMode" [(ngModel)]="question.independent">Independent</mat-checkbox>
    <mat-checkbox [disabled]="!editMode" [(ngModel)]="question.availableCustom">Available Custom</mat-checkbox>
    <mat-checkbox [disabled]="!editMode" [(ngModel)]="question.schoolSpecific">School Specific</mat-checkbox>
    <mat-form-field *ngIf="question.schoolSpecific" subscriptSizing="dynamic" style="margin-left: 1rem">
      <mat-label>School Code</mat-label>
      <input matInput [disabled]="!editMode" type="text" [value]="question.schoolCode" [(ngModel)]="question.schoolCode" />
    </mat-form-field>
  </div>
  <div>
    Requirements:
    <mat-checkbox [disabled]="!editMode" [(ngModel)]="question.imRequired">IM Required</mat-checkbox>
    <mat-checkbox [disabled]="!editMode" [(ngModel)]="question.fmRequired">FM Required</mat-checkbox>
    <mat-checkbox [disabled]="!editMode" [(ngModel)]="question.enhanceCalc">Refine Calculation</mat-checkbox>
  </div>

  <div>
    Triggered Question:
    <!-- create editable field for question.triggerId-->
    <mat-form-field style="margin-left: 2rem">
      <mat-label>Trigger ID</mat-label>
      <input matInput [disabled]="!editMode" type="text" [value]="question.triggerQuestionId" [(ngModel)]="question.triggerQuestionId" />
    </mat-form-field>
    <!-- create editable field for question.triggerValue-->
    <mat-form-field style="margin-left: 2rem">
      <mat-label>Trigger Value</mat-label>
      <input matInput [disabled]="!editMode" type="text" [value]="question.triggerShowValue" [(ngModel)]="question.triggerShowValue" />
    </mat-form-field>
  </div>

  <hr />

  <h2>Help Text</h2>

  <quill-editor *ngIf="editMode" [modules]="modules" placeholder="(Optional) Help text..." theme="snow" bounds="self" [(ngModel)]="question.help"></quill-editor>
  <div *ngIf="!editMode" [innerHTML]="question.help"></div>

  <hr />

  <ng-container *ngIf="question.format === 'Code'">
    <h2>Choices</h2>
    <table class="table table-sm" style="width: auto">
      <thead>
        <tr>
          <!-- <th scope="col">Order</th> -->
          <th scope="col">Label</th>
          <th scope="col">Value</th>
          <th *ngIf="editMode" scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let choice of question.choices; let i = index">
          <!-- <td>
            <mat-form-field style="top: -1rem; margin-bottom: -1rem; width: 100%">
              <input matInput [disabled]="!editMode" type="number" [value]="choice.ord" [(ngModel)]="choice.ord">
            </mat-form-field>
          </td> -->
          <td>
            <mat-form-field style="top: -1rem; margin-bottom: -1rem; width: 100%">
              <input matInput [disabled]="!editMode" type="text" [value]="choice.label" [(ngModel)]="choice.label" />
            </mat-form-field>
          </td>
          <td>
            <mat-form-field style="top: -1rem; margin-bottom: -1rem; width: 100%">
              <input matInput [disabled]="!editMode" type="text" [value]="choice.value" [(ngModel)]="choice.value" />
            </mat-form-field>
          </td>
          <td *ngIf="editMode">
            <button mat-button color="warn" (click)="deleteRow(question.choices, i)">delete row</button>
            <button *ngIf="i === question.choices.length - 1" mat-button color="secondary" (click)="addRow(question.choices)">add row</button>
          </td>
        </tr>
        <tr *ngIf="question.choices.length === 0 && editMode">
          <td>
            <button mat-button color="secondary" (click)="addRow(question.choices)">add row</button>
          </td>
        </tr>
      </tbody>
    </table>
    <button mat-button (click)="sortChoices(question.choices)">Sort Choices</button>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div *ngIf="!editMode">
    <button mat-raised-button color="secondary" (click)="editMode = true">edit</button>
    <button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial style="margin-left: 1rem">close</button>
  </div>
  <div *ngIf="editMode">
    <button mat-raised-button color="warn" [mat-dialog-close]="question" (click)="deleteQuestion()" style="margin-left: 1rem">delete</button>
    <button mat-raised-button color="secondary" mat-dialog-close (click)="editMode = false" style="margin-left: 1rem">cancel</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="question" style="margin-left: 1rem">save</button>
  </div>
</mat-dialog-actions>
