import { Injectable } from '@angular/core';
import { User } from '../_models/user';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private afs: AngularFirestore) { }

  public logUser(user: User, schoolId: string, path: string) {
    if (environment.production) {
      if (user) {
        const userLog = {
          email: user.email || '',
          schoolId: schoolId || '',
          path: path || '',
          time: new Date(Date.now()),
          id: user.uid || ''
        };
        this.afs.collection('logging').add(userLog);
        if (!user.admin) {
          this.afs.doc('schools/' + schoolId).set({ lastLogIn: new Date(Date.now()) }, { merge: true });
        }
      }
    }
  }
}
