import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { CalcConfigService } from '_services/calc-config.service';
import { QuestionsService } from '_services/questions.service';
import { Question, Choice } from '../../_models/question';
import { MatTabGroup } from '@angular/material/tabs';
import { filter, differenceBy, findIndex, isEqual } from 'lodash-es';
import { trigger, style, animate, transition } from '@angular/animations';


import { User } from '../../_models/user';
import { QuestionComponent } from '../question/question.component';
import { MatDialog } from '@angular/material/dialog';

// TODO: Animate new items: https://stackoverflow.com/questions/42847920/animation-for-newly-rendered-elements-but-not-on-page-load

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('.5s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ],
})
export class QuestionsComponent implements OnInit {
  @ViewChild('tabgroup') tabGroup: MatTabGroup;

  user: Promise<User>;

  filterFunction: any;
  unusedFilterFunction: any;

  schoolId = '';
  showHelp = false;
  questionsCopy: Question[] = [];
  editMode = false;
  tabs = [{ label: 'Starting', code: 'ST' },
  { label: 'Dependency', code: 'DS' },
  { label: 'Parent Household', code: 'HP' },
  { label: 'Parent Income', code: 'PI' },
  { label: 'Parent Assets', code: 'PA' },
  { label: 'Student Finances', code: 'SF' },
  { label: 'School Specific', code: 'SI' },
  { label: 'All', code: 'ALL' }
  ];

  tabCode = this.tabs[0].code;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public qs: QuestionsService,
    private cc: CalcConfigService,
    private auth: AuthService,
    public dialog: MatDialog
  ) {
    this.titleService.setTitle('Questions' + ' | NPC Admin');
  }

  ngOnInit() {
    this.schoolId = this.route.snapshot.parent?.paramMap.get('schoolId') ?? '';
    this.setFilter(this.tabCode);
    this.user = this.auth.currentUser();
  }

  private setFilter(code: string): void {
    this.filterFunction = code === 'ALL' ? null : item => item.section_id === code;
    this.unusedFilterFunction = this.filterFunction;
    if (code === 'SI') {
      this.unusedFilterFunction = q => q.section_id === 'SI' && (q.availableCustom === true || q.schoolCode === this.schoolId);
    }
  }

  onTabChanged($event: any) {
    this.tabCode = this.tabs.find(tab => tab.label === $event.tab.textLabel).code;
    this.setFilter(this.tabCode);
  }

  addQuestion(q: Question) {

    const triggeredQuestions = this.qs.allQuestions.value.filter(tq => tq.triggerQuestionId === q.id);

    // Set Method Required Status
    const im = this.cc.draftRules.value.schoolProperties.institutionalMethod;
    q.methodRequired = im ? q.imRequired || false : q.fmRequired || false;


    const questions = this.qs.draftQuestions.value;
    // add question
    questions.push(q);
    // add triggered questions
    triggeredQuestions.forEach(tq => {
      questions.push(tq);
    });

    // Sort questions after adding a new question according to their sort order
    questions.sort((a, b) => a.ordinal - b.ordinal);
    this.qs.draftQuestions.next(questions);
    this.qs.populateUnusedQuestions();
    this.setFilter(this.tabCode);
  }

  removeQuestion(q: Question) {
    // Make sure there are no triggered questions that are being used prior to removal
    const triggeredQuestions = this.qs.draftQuestions.value.filter(tq => tq.triggerQuestionId === q.id);
    let usedBy = false;
    triggeredQuestions.forEach(tq => {
      const index = this.cc.usedBy.value.findIndex(ub => ub.entityId === tq.id);
      usedBy = usedBy || index !== -1;
      if (index !== -1) {
        alert('The question: ' + tq.label + ' - is triggered by the question you are trying to remove and being used in your calculations.  Please clear any uses before removing.');
      }
    });

    if (!usedBy) {
      if (filter(this.cc.usedBy.value, { 'entityId': q.id, 'entityType': 'Question' }).length === 0) {
        const questions = this.qs.draftQuestions.value;

        // Remove question
        const index = questions.findIndex(question => question.id === q.id);
        questions.splice(index, 1);

        // Remove any triggered questions
        triggeredQuestions.forEach(tq => {
          const tqIndex = questions.findIndex(question => question.id === tq.id);
          questions.splice(tqIndex, 1);
        });

        this.qs.draftQuestions.next(questions);
        this.qs.populateUnusedQuestions();
        this.setFilter(this.tabCode);
      } else {
        alert('This question is being used. Please clear any uses before removing the question');
      }
    }
  }

  async clickedRow(question?: Question) {
    if (question && !this.editMode) {

      const user = await this.auth.currentUser();

      if (user.admin) {
        const dialogRef = this.dialog.open(QuestionComponent, {
          width: '1050px',
          data: { question: question }
        });

        dialogRef.afterClosed().subscribe(saveQuestion => {
          if (saveQuestion?.id) {
            const questions = this.qs.draftQuestions.value;
            const index = findIndex(questions, ['id', saveQuestion.id]);
            questions[index] = saveQuestion;
            this.qs.saveQuestions(this.schoolId, questions, this.qs.loadedAidYear);
            this.setFilter(this.tabCode);
          }
        });

      }
    }
  }


  manuallyChangeOptions() {
    const questions = this.qs.draftQuestions.value;

    const index = findIndex(questions, ['id', 'student.majorCodeCustomizable']);
    if (index !== -1) {
      const q = questions[index];
      const choiceArray = [
        'Business',
        'Education',
        'Health Sciences – Biomedical Engineering',
        'Health Sciences – Health Administration',
        'Health Sciences – Public Health',
        'Health Sciences – BS',
        'Health Sciences – Occupational Therapy',
        'Health Sciences – Physical Therapy',
        'Health Sciences – Athletic Training',
        'Health Sciences – Physician Assistant',
        'Health Sciences – Speech-Language Pathology',
        'Liberal Arts',
        'Music',
        'Natural and Environmental Sciences',
        'Nursing',
        'Pharmacy'
      ];
      const newChoices: Choice[] = [];
      let order = 1;
      choiceArray.forEach(choice => {
        newChoices.push({ label: choice, ord: order++, value: choice });
      });
      // q.choices = q.choices.concat(newChoices);
      q.choices = newChoices;
      // q.choices.sort((a, b) => (a.label > b.label) ? 1 : -1);
      console.log(questions[index]);
      this.qs.draftQuestions.next(questions);

    }
  }
  setMode(mode: 'edit' | 'save' | 'cancel') {
    switch (mode) {
      case 'edit':
        this.questionsCopy = JSON.parse(JSON.stringify(this.qs.draftQuestions.value)); // lodash has a deep clone function
        // this.manuallyChangeOptions();
        this.editMode = true;
        break;
      case 'cancel':
        this.qs.draftQuestions.next(this.questionsCopy);
        this.editMode = false;
        break;
      case 'save':
        if (!isEqual(this.questionsCopy, this.qs.draftQuestions.value)) {
          this.qs.saveQuestions(this.schoolId, this.qs.draftQuestions.value, this.qs.loadedAidYear);
        }
        this.editMode = false;
        break;
    }
  }
}
