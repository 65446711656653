import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CalcConfigService } from '_services/calc-config.service';
import { Formula } from '../../_models/formula';
import { filter } from 'lodash-es';

@Component({
  selector: 'app-formulas',
  templateUrl: './formulas.component.html',
  styleUrls: ['./formulas.component.css']
})
export class FormulasComponent implements OnInit {
  formulaName = '';
  formula: Formula;
  formulaCopy: Formula;
  tabIndex = 0;
  editMode = false;
  schoolId: string;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public cc: CalcConfigService
  ) {
    this.titleService.setTitle('Formulas' + ' | NPC Admin');
  }

  ngOnInit() {
    this.schoolId = this.route.snapshot.parent?.paramMap.get('schoolId') ?? '';
  }

  setMode(mode: 'edit' | 'save' | 'cancel' | 'delete') {
    switch (mode) {
      case 'edit':
        this.formulaCopy = JSON.parse(JSON.stringify(this.formula)); // lodash has a deep clone function
        this.editMode = true;
        break;
      case 'cancel':
        // automatically loses the formula copy
        this.editMode = false;
        if (this.formulaCopy.formula === undefined) {
          // Cancelled on a formula that was not set up - remove formula
          this.clickedRow();
        }
        break;
      case 'delete':
        this.editMode = false;
        // Check to see if formula is being used before deletion
        if (filter(this.cc.usedBy.value, { 'entityId': this.formula.formulaId, 'entityType': 'Formula' }).length === 0) {
          this.cc.deleteFormula(this.schoolId, this.formula);
        } else {
          alert('This formula is being used. You need to remove uses before deleting the formula');
        }
        this.clickedRow();
        break;
      case 'save':
        if (this.formulaCopy.valid) {
          this.formula = this.formulaCopy;
          if (this.formulaCopy.formula !== undefined) {
            this.cc.saveFormula(this.schoolId, this.formulaCopy);
          } else {
            this.clickedRow();
          }
          this.editMode = false;
        } else {
          alert('Formula must be valid in order to save.  Please fix or cancel.');
        }
        break;
    }
  }

  changeTab(event: any) {
    this.tabIndex = event;
    if (this.editMode && this.tabIndex === 0) {
      this.setMode('cancel');
    }
  }

  clickedRow(formula?: Formula) {
    if (formula) {
      this.formulaName = formula.name;
      this.formula = formula;
      this.tabIndex = 1;
    } else {
      this.formulaName = '';
      this.tabIndex = 0;
    }
  }

  addFormula(formulas: Formula[]) {
    const aFormula: Formula = {
      formulaId: this.cc.getUniqueId(),
      name: 'New formula',
      description: '',
      formula: '',
      valid: false
    };
    // Automatically edit this formula
    this.clickedRow(aFormula);
    this.setMode('edit');
  }


}
