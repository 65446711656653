<div *ngIf="!editMode">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title><h2>{{table.name}}</h2></mat-card-title>
      <mat-card-subtitle>{{table.description}}</mat-card-subtitle>
    </mat-card-header>
    <div class="input-label">
      <strong>{{table.type}}</strong>
      <span style="margin-left: 1rem;">{{inputLabel}}</span>
    </div>
    <hr>

    <mat-card-content>
      <!-- Display Lookup Table -->
      <ng-container *ngIf="!table.rangeTest">
        <table class="table" style="width:auto">
          <thead>
            <tr>
              <!-- <th scope="col">Lookup Label</th> -->
              <th scope="col">Lookup Value</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let test of table.lookupTests">
              <!-- <td>{{test.label}}</td> -->
              <th scope="row">{{test.lookupValue}}</th>
              <td>{{test.result | number}}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>

      <!-- Display Range Table -->
      <ng-container *ngIf="table.rangeTest">
        <table class="table" style="width:auto">
          <thead>
            <tr>
              <th scope="col">Start</th>
              <th scope="col">End</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let test of table.rangeTests">
              <td>{{test.rangeStart | number}}</td>
              <td>{{test.rangeFinish | number}}</td>
              <td>{{test.result | number}}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>



    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="editMode">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title><h2>
        Edit Table Information
      </h2></mat-card-title>
      <mat-card-subtitle>
        Changing the input will erase all of your amounts
      </mat-card-subtitle>

    </mat-card-header>
    <mat-form-field style="margin-left: 1rem;width: 30%;">
      <input matInput placeholder="Name" type="text" [value]="table.name" [(ngModel)]="table.name">
    </mat-form-field>
    <mat-form-field style="margin-left: 1rem;width: 70%">
      <input matInput placeholder="Description" type="text" [value]="table.description" [(ngModel)]="table.description">
    </mat-form-field>
    <div *ngIf="!changeStatus" class="input-label">

      <strong>{{table.type}}</strong>
      <span style="margin-left: 1rem;">{{inputLabel}}</span>

      <button mat-stroked-button color="primary" style="margin-left: 2em" (click)="changeInput('edit')">change input</button>
    </div>

    <!-- Question/Calculation/Formula Selector -->
    <div *ngIf="changeStatus" style="margin-left: 1rem;">
      <input-selector [key]="table" [caller]="'Table'"></input-selector>
      <button mat-stroked-button color="primary" style="margin-left: 2em" (click)="changeInput('save')">change input</button>
      <button mat-stroked-button color="warn" style="margin-left: 2em" (click)="changeInput('cancel')">cancel change</button>
    </div>
    <hr>

    <mat-card-content *ngIf="!changeStatus">

      <!-- Edit Lookup Table -->
      <ng-container *ngIf="!table.rangeTest">
        <table class="table" style="width:auto">
          <thead>
            <tr>
              <!-- <th scope="col">Lookup Label</th> -->
              <th scope="col">Lookup Value</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let test of table.lookupTests">
              <!-- <td>{{test.label}}</td> -->
              <th scope="row">{{test.lookupValue}}</th>
              <td>
                <mat-form-field style="top: -1rem; margin-bottom: -1rem; width: 5em">
                  <input matInput type="number" [value]="test.result" [(ngModel)]="test.result">
                </mat-form-field>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>


      <!-- Edit Range Table -->
      <ng-container *ngIf="table.rangeTest">
        <table class="table" style="width:auto">
          <thead>
            <tr>
              <th scope="col">Start</th>
              <th scope="col">End</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let test of table.rangeTests;let i = index">
              <td>{{test.rangeStart | number}}</td>
              <td>
                <mat-form-field style="top: -1rem; margin-bottom: -1rem; width: 5em">
                  <input matInput type="number" [value]="test.rangeFinish" [(ngModel)]="test.rangeFinish" (change)="calcRanges(table.rangeTests)">
                  <mat-hint *ngIf="test.rangeFinish < test.rangeStart">
                    Amount too low
                  </mat-hint>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field style="top: -1rem; margin-bottom: -1rem; width: 5em">
                  <input matInput type="number" [value]="test.result" [(ngModel)]="test.result">
                </mat-form-field>
              </td>
              <td>
                <button mat-button color="warn" (click)="deleteRow(table.rangeTests, i)">delete row</button>
                <button *ngIf="i === (table.rangeTests.length - 1)" mat-button color="secondary" (click)="addRow(table.rangeTests)">add
                  row</button>
              </td>
            </tr>
            <tr *ngIf="table.rangeTests === undefined">
              <td>
                <button mat-button color="secondary" (click)="addRow(table.rangeTests)">add row</button>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>


    </mat-card-content>
  </mat-card>
</div>
