import { Table } from '../_models/table';
import { Population } from '../_models/population';

export interface Expense {
    type: 'TuitionAndFees' | 'RoomAndBoard' | 'BooksAndSupplies' | 'Transportation' | 'PersonalExpenses';
    name: string; // TODO: Not used, can removed.  Use Expense Name Map instead
    order: number;
    rules: ExpenseRule[];
}

export const ExpenseNameMap = {
    'TuitionAndFees': 'Tuition & Fees',
    'RoomAndBoard': 'Food & Housing',
    'BooksAndSupplies': 'Books, Course Materials, Supplies, and Equipment',
    'Transportation': 'Transportation',
    'PersonalExpenses': 'Miscellaneous Personal Expenses'
};
export const ExpenseOrderMap = {
    'TuitionAndFees': 1,
    'RoomAndBoard': 2,
    'BooksAndSupplies': 3,
    'Transportation': 4,
    'PersonalExpenses': 5
};


export interface ExpenseRule {
    populationId: string;
    population?: Population;
    // type = "fixed" means no lookup table
    // type = "table" means use table found at table_id
    tableId?: string; // Document ID of a table
    table?: Table;
    amount?: number; // dollar amount of expense
}
/*
"code": "amherst",
"amount": 54310,
"population_id": 1,
"population_operator": "EQ",  -- ALWAYS EQ
"type": "Fixed",
"ruleset_id": 2136,

"expense_code": "TuitionAndFees",
"subscriber_id": "3003"


            "code": "amherst",
            "amount": 0,
            "population_id": 3570,
            "population_operator": "EQ",
            "type": "Table",
            "table_id": 2449,
            "ruleset_id": 2139,
            "expense_code": "Transportation",
            "subscriber_id": "3003"

*/
