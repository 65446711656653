<div class="title-bar">
  <h1>BRANDING</h1>
  <app-help screen="branding"></app-help>
  <button mat-flat-button color="primary" type="submit" [disabled]="!brandingForm.valid || state != 'modified'">
    {{state == 'modified' ? 'Autosaving ...' : 'No unsaved changes'}}
  </button>
  <app-draft-mode></app-draft-mode>
</div>
<span *ngIf="state=='error'" class="alert alert-warning" role="alert">
  An error has occurred attempting to save changes
</span>
<app-publish-preferences></app-publish-preferences>
<form fireForm [path]="documentPath" [formGroup]="brandingForm" novalidate (stateChange)="changeHandler($event)">

  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Header Background</h2>
      </mat-card-title>
      <mat-card-subtitle id="headerTypeLabel">The header background can be a solid color, a gradient, or a repeating image</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <mat-radio-group formControlName="headerType" role="radiogroup" aria-labelledby="headerTypeLabel">
        <mat-radio-button aria-label="Solid" value="hSolid" class="radio-option">Solid</mat-radio-button>
        <mat-radio-button aria-label="Gradient" value="hGradient" class="radio-option">Gradient</mat-radio-button>
        <mat-radio-button aria-label="Image" value="hImage" class="radio-option">Image</mat-radio-button>
      </mat-radio-group>

      <p></p>

      <div *ngIf="headerTypeValue==='hSolid'">
        <p>Select a solid color for the background of the header</p>
        <mat-form-field appearance="outline">
          <mat-label>Header Color</mat-label>
          <input matInput formControlName="headerColor" [value]="headerColorValue" />
        </mat-form-field>
        <mat-form-field appearance="outline" style="width:5rem">
          <input matInput type="color" formControlName="headerColor" [value]="headerColorValue" />
        </mat-form-field>
      </div>

      <div *ngIf="headerTypeValue==='hGradient'">
        <p>Build a gradient at a site like:
          <a href="https://cssgradient.io/" target="_blank">cssgradient.io</a> then copy the code
        </p>
        <mat-form-field style="width:80%">
          <mat-label>Header Gradient</mat-label>
          <input matInput type="text" formControlName="headerGradient" placeholder="format: linear-gradient(#0077cc,#004876)" />
        </mat-form-field>
        <div id="colorBox" [ngStyle]="{'background-image': headerGradientValue}"></div>
      </div>

      <div *ngIf="headerTypeValue==='hImage'">
        <p>Select a small tiled imaged to be repeated in the background of the header</p>
        <img class="logo" [src]="(ups.headerBgImageURL | async) || ups.loadingLogo" alt="Header Logo">
        <button mat-raised-button (click)="headerBgUpload.click()"> Upload Image
          <input #headerBgUpload type="file" (change)="uploadFile($event, 'header')" style="display:none">
        </button>
        <button mat-raised-button color="warn" style="margin-left: 10px" *ngIf="!(ups.headerBgImageURL | async)?.includes('/assets/')" (click)="removeImageRef('headerBgImage')">Remove Image</button>
      </div>

      <p>(optional) Add a border to the header</p>
      <mat-form-field style="width:80%">
        <mat-label>Header Bottom Border</mat-label>
        <input matInput type="text" formControlName="headerBottomBorderStyle" placeholder="format: 1px solid #07c" />
      </mat-form-field>
      <div id="colorBox" [ngStyle]="{'border-bottom': headerBottomBorderValue}"></div>

      <p></p>

      <!-- <mat-checkbox formControlName="headerShrink">Disable shrinking header</mat-checkbox> -->

      <label id="shrinkHeader">Choose how the header reacts when window is scrolled</label>
      <br>
      <mat-radio-group aria-labelledby="headerShrink" formControlName="headerShrink" role="radiogroup">
        <mat-radio-button aria-label="shrink on scroll" [value]="false" class="radio-option">Shrink on Scroll</mat-radio-button>
        <mat-radio-button aria-label="never shrink" [value]="true" class="radio-option">Never Shrink</mat-radio-button>
      </mat-radio-group>



    </mat-card-content>

  </mat-card>

  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Page Background</h2>
      </mat-card-title>
      <mat-card-subtitle id="pageBackgroundLabel">The page background can be a solid color, a gradient, or a repeating image</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-radio-group formControlName="backgroundType" role="radiogroup" aria-labelledby="pageBackgroundLabel">
        <mat-radio-button aria-label="Solid" value="bgSolid" class="radio-option">Solid</mat-radio-button>
        <mat-radio-button aria-label="Gradient" value="bgGradient" class="radio-option">Gradient</mat-radio-button>
        <mat-radio-button aria-label="Image" value="bgImage" class="radio-option">Image</mat-radio-button>
      </mat-radio-group>
      <p></p>
      <div *ngIf="bkgTypeValue==='bgSolid'">
        <p>Select a solid color for the background of the Page</p>
        <mat-form-field appearance="outline">
          <mat-label>Page Color</mat-label>
          <input matInput formControlName="backgroundColor" [value]="backgroundColorValue" />
        </mat-form-field>
        <mat-form-field appearance="outline" style="width:5rem">
          <input matInput type="color" formControlName="backgroundColor" [value]="backgroundColorValue" />
        </mat-form-field>
      </div>

      <div *ngIf="bkgTypeValue==='bgGradient'">
        <p>Build a gradient at a site like:
          <a href="https://cssgradient.io/" target="_blank">cssgradient.io</a> then copy the code
        </p>
        <mat-form-field style="width:80%">
          <mat-label>Background Gradient</mat-label>
          <input matInput type="text" formControlName="backgroundGradient" placeholder="format: linear-gradient(#0077cc,#004876)" />
        </mat-form-field>
        <div id="colorBox" [ngStyle]="{'background-image': bkgGradientValue}"></div>
      </div>

      <div *ngIf="bkgTypeValue==='bgImage'">
        <p>Select a small tiled imaged to be repeated in the background of the header</p>
        <img class="logo" [src]="(ups.pageBgImageURL | async) || ups.loadingLogo" alt="Background Image">
        <button mat-raised-button (click)="pageBgUpload.click()"> Upload Image
          <input #pageBgUpload type="file" (change)="uploadFile($event, 'page')" style="display:none">
        </button>
        <button mat-raised-button color="warn" style="margin-left: 10px" *ngIf="!(ups.pageBgImageURL | async)?.includes('/assets/')" (click)="removeImageRef('pageBgImage')">Remove Image</button>
      </div>
    </mat-card-content>
  </mat-card>


  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>School Logo</h2>
      </mat-card-title>
      <mat-card-subtitle>
        The school logo is placed in the header of the application. (Height limit: 80px)
        <br> Use <a href="https://squoosh.app/" target="_blank">Squoosh</a> to resize and minimize your picture
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <img class="logo" (load)="check($event, 'logo')" [ngStyle]="{'background-color': headerColorValue}" [src]="(ups.logoURL | async) || ups.loadingLogo" alt="School Logo">
      <br>
      <span>height: {{(logoSize | async)?.height}} width: {{(logoSize | async)?.width}}</span>
      <div style="margin-top: 1rem;">
        <button mat-raised-button (click)="logoUpload.click()"> Upload Logo
          <input #logoUpload type="file" (change)="uploadFile($event, 'logo')" style="display:none">
        </button>
        <button mat-raised-button color="warn" style="margin-left: 10px" *ngIf="!(ups.logoURL | async)?.includes('/assets/')" (click)="removeImageRef('logo')">Remove Logo</button>
      </div>

    </mat-card-content>
  </mat-card>


  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Welcome Page Image</h2>
      </mat-card-title>
      <mat-card-subtitle>
        Set an image at the top of your welcome page. (Height limit: 300px)
        <br> Use <a href="https://squoosh.app/" target="_blank">Squoosh</a> to resize and minimize your picture
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <img class="welcome-image" (load)="check($event, 'welcome')" [src]="(ups.welcomeImageURL | async) || ups.loadingLogo" alt="Welcome Page Image">
      <br>
      <span>height: {{(welcomeSize | async)?.height}} width: {{(welcomeSize | async)?.width}}</span>
      <div style="margin-top: 1rem;">
        <button mat-raised-button (click)="welcomeImageUpload.click()"> Upload Welcome Image
          <input #welcomeImageUpload type="file" (change)="uploadFile($event, 'welcome')" style="display:none">
        </button>
        <button mat-raised-button color="warn" style="margin-left: 10px" *ngIf="!(ups.welcomeImageURL | async)?.includes('/assets/')" (click)="removeImageRef('welcome')">Remove Welcome Image</button>
      </div>
    </mat-card-content>
  </mat-card>



  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Other Color Options</h2>
      </mat-card-title>
      <mat-card-subtitle>Typically best to keep these at default settings for accessabilty.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div style="margin-top: 2rem;">
        <mat-form-field appearance="outline">
          <mat-label>Panel Color</mat-label>
          <input matInput formControlName="panelColor" [value]="panelColorValue" />
        </mat-form-field>
        <mat-form-field appearance="outline" style="width:5rem">
          <input matInput type="color" formControlName="panelColor" [value]="panelColorValue" />
        </mat-form-field>
      </div>
      <div style="margin-top: 1rem;">
        <mat-form-field appearance="outline">
          <mat-label>Footer Color</mat-label>
          <input matInput formControlName="footerColor" [value]="footerColorValue" />
        </mat-form-field>
        <mat-form-field appearance="outline" style="width:5rem">
          <input matInput type="color" formControlName="footerColor" [value]="footerColorValue" />
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>

</form>