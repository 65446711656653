import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { InspectorService } from '../../_services/inspector.service';
import { CalcConfigService } from '_services/calc-config.service';
import { AuthService } from '../../_services/auth.service';

import { AdminSession, NpcResult, Result } from '../../_models/result';

import { environment } from 'environments/environment';
import { User } from '../../_models/user';

import { Observable, BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';



interface ResultComparison {
  type: string;
  base: number;
  changed: number;
  diff: number;
}

interface PopulationComparison {
  name: string;
  description: string;
  id: string;
  base: boolean;
  changed: boolean;
}


@Component({
  selector: 'app-inspector',
  templateUrl: './inspector.component.html',
  styleUrls: ['./inspector.component.css']
})
export class InspectorComponent implements OnInit {
  schoolId = '';
  selected: AdminSession = undefined;
  draftModeUrl = '';

  user: Promise<User>;

  cloudResults = new Observable();
  newInasResults = new Observable();
  legacyResults = new Observable();
  draftResults = new BehaviorSubject<Result>(null);
  baseResults = new BehaviorSubject<Result>(null);
  costComparison = new BehaviorSubject<ResultComparison[]>(null);
  aidComparison = new BehaviorSubject<ResultComparison[]>(null);
  popComparison = new BehaviorSubject<PopulationComparison[]>(null);


  constructor(
    private titleService: Title,
    public is: InspectorService,
    private route: ActivatedRoute,
    public cc: CalcConfigService,
    private auth: AuthService
  ) {
    this.titleService.setTitle('Inspector' + ' | NPC Admin');
  }

  ngOnInit() {
    this.schoolId = this.route.snapshot.parent?.paramMap.get('schoolId') ?? '';
    this.cc.loadPublishedCalcRules(this.schoolId);
    this.is.loadSessions(this.schoolId, true);
    this.draftModeUrl = environment.draftModeUrl;
    this.user = this.auth.currentUser();
    this.setInasFlag();
  }

  async setInasFlag() {
    const user = await this.auth.currentUser();
  }

  cloudSessions(event: any) {
    if (event && event.value === 'new') {
      this.createNewSession();
      return;
    }

    const adminSession = event.value;
    this.clearComparisons();

    if (!this.schoolId || !adminSession?.id) {
      return
    }
    const draftResults$ = this.is.cloudCalc(this.schoolId, adminSession.id, false).pipe(take(1), catchError(err => { return this.cloudError(err); }));
    const baseResults$ = this.is.cloudCalc(this.schoolId, adminSession.id, true).pipe(take(1), catchError(err => { return this.cloudError(err); }));

    forkJoin([draftResults$, baseResults$]).subscribe(([draftResults, baseResults]) => {
      if (!draftResults || !baseResults) {
        return;
      }

      this.draftResults.next(draftResults);
      this.baseResults.next(baseResults);

      // Build presentation
      this.buildComparison(this.baseResults.value.npcResult, this.draftResults.value.npcResult);
      this.comparePopulations();

    });

  }

  cloudError(err: any) {
    console.log(err);
    alert(err);
    this.selected = null;
    return of();
  }

  private async createNewSession() {
    this.selected = null;
    const user = await this.auth.currentUser();
    const url = this.draftModeUrl + this.schoolId + '?draft=' + user.uid;
    window.open(url, '_blank');
  }


  async createSession(sessionId: string) {
    const message = await this.is.createAdminSession(sessionId);
    alert(message);
  }
  deleteSession(sessionId: string) {
    this.is.deleteAdminSession(sessionId);
    this.clearComparisons();
    this.selected = null;
  }

  //#region Presentation Logic
  comparePopulations() {
    const basePop = this.baseResults.value.calcPopulations;
    const changedPop = this.draftResults.value.calcPopulations;
    // Use current (draft) populations for list of available populations
    const populations = this.cc.populations.value;

    const popComps: PopulationComparison[] = [];
    for (const pop of populations) {
      popComps.push({
        name: pop.name,
        id: pop.id,
        description: pop.description,
        base: basePop[pop.id],
        changed: changedPop[pop.id]
      });
    }
    this.popComparison.next(popComps);
  }

  private clearComparisons() {
    this.draftResults.next(null);
    this.baseResults.next(null);
    // clear Comparisons
    this.costComparison.next(null);
    this.aidComparison.next(null);
    this.popComparison.next(null);
  }

  // Build Results Presentation Table
  private buildComparison(base: NpcResult, changed: NpcResult) {
    const cost: ResultComparison[] = [];

    cost.push({ type: 'Tuition & Fees', base: base.coa.tuition, changed: changed.coa.tuition, diff: changed.coa.tuition - base.coa.tuition });
    cost.push({ type: 'Food & Housing', base: base.coa.housing, changed: changed.coa.housing, diff: changed.coa.housing - base.coa.housing });
    cost.push({ type: 'Books & Supplies', base: base.coa.books, changed: changed.coa.books, diff: changed.coa.books - base.coa.books });
    cost.push({
      type: 'Transportation', base: base.coa.transportation, changed: changed.coa.transportation,
      diff: changed.coa.transportation - base.coa.transportation
    });
    cost.push({ type: 'Personal Expenses', base: base.coa.personal, changed: changed.coa.personal, diff: changed.coa.personal - base.coa.personal });
    cost.push({ type: 'Total Cost', base: base.totalCost, changed: changed.totalCost, diff: changed.totalCost - base.totalCost });
    this.costComparison.next(cost);

    const aid: ResultComparison[] = [];

    if (base.sai || changed.sai) {
      aid.push({ type: 'Student Aid Index', base: base.sai, changed: changed.sai, diff: changed.sai - base.sai });
    }

    aid.push({
      type: 'Pell Grant', base: base.pellGrant, changed: changed.pellGrant,
      diff: changed.pellGrant - base.pellGrant
    });
    aid.push({
      type: 'Institutional Scholarship', base: base.institutionalScholarship, changed: changed.institutionalScholarship,
      diff: changed.institutionalScholarship - base.institutionalScholarship
    });
    aid.push({
      type: 'Institutional Grant', base: base.institutionalGrant, changed: changed.institutionalGrant,
      diff: changed.institutionalGrant - base.institutionalGrant
    });
    aid.push({
      type: 'State Grant', base: base.stateGrant, changed: changed.stateGrant,
      diff: changed.stateGrant - base.stateGrant
    });
    aid.push({
      type: 'School Defined Fund 1', base: base.schoolDefinedFund1, changed: changed.schoolDefinedFund1,
      diff: changed.schoolDefinedFund1 - base.schoolDefinedFund1
    });
    aid.push({
      type: 'School Defined Fund 2', base: base.schoolDefinedFund2, changed: changed.schoolDefinedFund2,
      diff: changed.schoolDefinedFund2 - base.schoolDefinedFund2
    });
    aid.push({
      type: 'Total Grant Aid', base: base.totalGrantAid, changed: changed.totalGrantAid,
      diff: changed.totalGrantAid - base.totalGrantAid
    });
    aid.push({
      type: 'Student Contribution', base: base.studentContribution, changed: changed.studentContribution,
      diff: changed.studentContribution - base.studentContribution
    });
    aid.push({
      type: 'Parent Contribution', base: base.parentContribution, changed: changed.parentContribution,
      diff: changed.parentContribution - base.parentContribution
    });
    aid.push({
      type: 'Consolidated Self Help', base: base.consolidatedSelfHelp, changed: changed.consolidatedSelfHelp,
      diff: changed.consolidatedSelfHelp - base.consolidatedSelfHelp
    });
    aid.push({ type: 'Student Loan', base: base.studentLoan, changed: changed.studentLoan, diff: changed.studentLoan - base.studentLoan });
    aid.push({ type: 'Student Work', base: base.studentWork, changed: changed.studentWork, diff: changed.studentWork - base.studentWork });
    aid.push({ type: 'Parent Loan', base: base.parentLoan, changed: changed.parentLoan, diff: changed.parentLoan - base.parentLoan });
    aid.push({ type: 'Net Price', base: base.netPrice, changed: changed.netPrice, diff: changed.netPrice - base.netPrice });


    this.aidComparison.next(aid);
  }

  //#endregion

  getCode(code: string) {
    prompt('Code to copy', JSON.stringify(code));
  }
}
