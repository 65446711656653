<ng-container *ngIf="cc.draftRuleInfo | async as draftRuleInfo">
    <ng-container *ngIf="cc.publishedRuleInfo | async as publishedRuleInfo">

        <ng-container *ngIf="draftRuleInfo.lastUpdated > publishedRuleInfo.publishedDate || draftRuleInfo.aidYear !== publishedRuleInfo.aidYear; else norulechanges">
            <div style="display:flex; align-items:center;">

                <button mat-flat-button color="primary" style="margin-left: 1em" (click)="publishRules()" [disabled]="publishedRuleInfo.publishedDate >= draftRuleInfo.lastUpdated && draftRuleInfo.aidYear === publishedRuleInfo.aidYear">
                    {{'Publish ' + draftRuleInfo.aidYear + ' Rules'}}
                </button>
                <button mat-flat-button color="warn" style="margin-left: 1em" (click)="resetRules()">
                    Reset Draft Rules
                </button>

                <div style="flex-direction: column;text-align: right;margin-left: 2rem">
                    <div>Draft Rules [{{draftRuleInfo.aidYear}}]</div>
                    <div>Published Rules [{{publishedRuleInfo.aidYear}}]</div>
                </div>
                <div style="flex-direction: column;margin-left: 0.3rem">
                    <div>last Modified {{draftRuleInfo.lastUpdated | date:'short'}}</div>
                    <div>last Published {{publishedRuleInfo.publishedDate | date:'short'}}</div>
                </div>
            </div>
        </ng-container>

        <ng-template #norulechanges>
            <div>All changes have been published for aid year {{publishedRuleInfo.aidYear}} on {{publishedRuleInfo.publishedDate | date:'short'}}</div>
        </ng-template>

    </ng-container>
</ng-container>