<div class="title-bar">
  <h1 style="color: red">ALL QUESTIONS</h1>
  <button mat-button color="accent" disabled>help</button>
  <ng-container *ngIf="!editMode">
    <button mat-flat-button color="primary" [disabled]="this.tabGroup && this.tabGroup.selectedIndex === 8" (click)="setMode('edit')">Edit Questions</button>
    <button mat-flat-button color="secondary" (click)="addQuestion()">Add Question</button>
  </ng-container>
  <ng-container *ngIf="editMode">
    <button mat-flat-button color="accent" (click)="setMode('save')">Save Questions</button>
    <button mat-flat-button color="warn" (click)="setMode('cancel')">Cancel</button>
  </ng-container>

  <div>
    <mat-form-field>
      <mat-label>Section</mat-label>
      <mat-select [formControl]="questionsControl">
        <mat-option *ngFor="let tab of tabs" [value]="tab">{{ tab.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-slide-toggle [(ngModel)]="showDeleted" style="margin-left: auto" labelPosition="before" (change)="setFilter(tabCode)">Show Deleted Questions</mat-slide-toggle>
  <mat-slide-toggle [(ngModel)]="showHelp" labelPosition="before">Show Help Text</mat-slide-toggle>
  <app-aid-year *ngIf="!editMode" [disabled]="editMode"></app-aid-year>
</div>

<mat-card appearance="outlined">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0 table table-hover" #questionsTable>
    <ng-container matColumnDef="question">
      <th scope="col" mat-header-cell *matHeaderCellDef>Question</th>
      <td mat-cell *matCellDef="let q" [ngClass]="{ 'triggered-question': q.triggerQuestionId !== undefined, 'deleted-question': q.dateDeleted }">
        {{ q.dateDeleted ? "Deleted: " : "" }}{{ q.label }}
        <small style="color: rgba(59, 62, 250, 0.8)"><br />(ID: {{ q.id }}) (ord: {{ q.ordinal }})</small>
        <div *ngIf="showHelp">
          <span class="text-muted" style="font-size: 90%" [innerHTML]="q.help"></span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="independent">
      <th scope="col" mat-header-cell *matHeaderCellDef>Ind/Dep-endent</th>
      <td mat-cell *matCellDef="let q" width="130">
        <mat-checkbox [disabled]="!editMode" [(ngModel)]="q.independent"></mat-checkbox>
        <mat-checkbox [disabled]="!editMode" [(ngModel)]="q.dependent" style="margin-left: 1rem"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="imRequired">
      <th scope="col" mat-header-cell *matHeaderCellDef>IM Required</th>
      <td mat-cell *matCellDef="let q" width="80">
        <mat-checkbox [disabled]="!editMode" [(ngModel)]="q.imRequired"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="fmRequired">
      <th scope="col" mat-header-cell *matHeaderCellDef>FM Required</th>
      <td mat-cell *matCellDef="let q" width="80">
        <mat-checkbox [disabled]="!editMode" [(ngModel)]="q.fmRequired"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="refineCalc">
      <th scope="col" mat-header-cell *matHeaderCellDef>Refine Calc</th>
      <td mat-cell *matCellDef="let q" width="80">
        <mat-checkbox [disabled]="!editMode" [(ngModel)]="q.enhanceCalc"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="custom">
      <th scope="col" mat-header-cell *matHeaderCellDef>Custom Available</th>
      <td mat-cell *matCellDef="let q" width="80">
        <mat-checkbox [disabled]="!editMode" [(ngModel)]="q.availableCustom"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="schoolSpecific">
      <th scope="col" mat-header-cell *matHeaderCellDef>School Specific</th>
      <td mat-cell *matCellDef="let q" width="80">
        <mat-checkbox [disabled]="!editMode" [(ngModel)]="q.schoolSpecific"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="schoolCode">
      <th scope="col" mat-header-cell *matHeaderCellDef>School Code</th>
      <td mat-cell *matCellDef="let q" width="80">
        <!-- TODO: Make this a dropdown of school codes or text field -->
        {{ q.schoolCode }}
      </td>
    </ng-container>

    <ng-container style="padding-left: 24px" matColumnDef="format">
      <th scope="col" mat-header-cell *matHeaderCellDef>Format</th>
      <td mat-cell *matCellDef="let q">
        <div *ngIf="q.format !== 'Code'" style="padding-left: 0.75rem">{{ q.format === "Boolean" ? "Yes/No" : q.format }}</div>
        <div *ngIf="q.format === 'Code'">
          <mat-form-field appearance="outline" class="dropdown" subscriptSizing="dynamic">
            <mat-label><span style="color: rgb(0, 0, 0)">Choices</span></mat-label>
            <mat-select>
              <div *ngFor="let choices of q.choices">
                <mat-option>{{ choices.label }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="section">
      <th scope="col" mat-header-cell *matHeaderCellDef>Section</th>
      <td mat-cell *matCellDef="let q">
        {{ q.section_id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dateUpdated">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Updated</th>
      <td mat-cell *matCellDef="let q">
        {{ q.dateUpdated | date : "short" }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
    <tr mat-row #questionRow *matRowDef="let q; columns: getDisplayedColumns()" (click)="clickedRow(q)"></tr>

    <tr class="mat-row no-results" *matNoDataRow>
      <td class="mat-cell" colspan="12">No results for search criteria</td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[30, 50, 100, 250]" showFirstLastButtons></mat-paginator>
</mat-card>
