import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { tap, take } from 'rxjs/operators';

@Injectable()
export class PublishingService {

  constructor(private afs: AngularFirestore) { }

  // TODO: Does not currently copy embedded collections like Questions

  // Publish draft preferences to make them live in the student application
  publishDraft(schoolId: string) {
    const publishedDoc = this.afs.doc('preferences/' + schoolId);
    const draftDoc = this.afs.doc('preferences_draft/' + schoolId);

    draftDoc.valueChanges().pipe(
      take(1),
      tap(data => {
        publishedDoc.set(data);
      })
    ).subscribe();
  }

  // Copy live preferences from live application to draft
  copyToDraft(schoolId: string) {
    const publishedDoc = this.afs.doc('preferences/' + schoolId);
    const draftDoc = this.afs.doc('preferences_draft/' + schoolId);

    publishedDoc.valueChanges().pipe(
      take(1),
      tap(async (data) => {
        await draftDoc.set(data);
        location.reload();
      })
    ).subscribe();
  }

  // Copy document from one path to the next
  utilityCopyDocument(fromDocPath: string, toDocPath: string) {
    const fromDoc = this.afs.doc(fromDocPath);
    const toDoc = this.afs.doc(toDocPath);

    fromDoc.valueChanges().pipe(
      take(1),
      tap(data => {
        toDoc.set(data);
      })
    ).subscribe();
  }

  // Works for expenses - have not tested others
  utilityCopyCollection(fromSchool, toSchool, collectionName, collectionId) {
    const fromCol = this.afs.collection('calcConfig/' + fromSchool + '/' + collectionName);
    const toCol = this.afs.collection('calcConfig/' + toSchool + '/' + collectionName);

    fromCol.valueChanges().pipe(
      take(1),
      tap(res => {
        for (const doc of res) {
          toCol.doc(doc[collectionId]).set(doc);
        }
      })
    ).subscribe();

  }
}
