import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Preferences } from '../_models/preferences';
import { tap, finalize, catchError } from 'rxjs/operators';
import { User } from '../_models/user';
import { deleteField } from '@firebase/firestore';

import isEqual from 'lodash-es/isEqual';

const defaultLogo = './assets/images/College-Board-Logo_White.png';
const defaultImage = './assets/images/icons8-upload.svg';

@Injectable()
export class UnivPrefsService {
  draftPrefs = new BehaviorSubject(null);
  publishedPrefs = new BehaviorSubject(null);
  draftSubscription: Subscription;
  publishSubscription: Subscription;
  draftChanges = new BehaviorSubject(false);
  // URL (location) of each image (where present)
  logoURL = new BehaviorSubject(defaultLogo);
  headerBgImageURL = new BehaviorSubject(defaultImage);
  pageBgImageURL = new BehaviorSubject(defaultImage);
  welcomeImageURL = new BehaviorSubject(defaultImage);

  // CSS for background-image property
  headerBgImageCss = new BehaviorSubject('');  // URL or gradient used by header style
  // not currently used in the admin app
  pageBgImageCss = new BehaviorSubject('');  //  URL or gradient used by page background style

  loadedSchoolId: string;

  activeUsers: Observable<User[]>;

  loadingLogo = './assets/images/loader.gif';

  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }

  loadPreferences(schoolId: string) {
    schoolId = (schoolId === '') ? 'faber' : schoolId;
    if (schoolId !== this.loadedSchoolId) {
      this.logoURL.next('');

      if (this.draftSubscription) { this.draftSubscription.unsubscribe(); }
      this.draftSubscription = this.afs.doc<Preferences>('preferences_draft/' + schoolId).valueChanges().pipe(
        tap(prefs => {
          this.loadedSchoolId = schoolId;
          if (prefs) {
            this.loadImages(prefs);
            // this.calcConfigSerivce.useRounding = prefs.useRounding || false;
            // this.calcConfigSerivce.efcRounding = prefs.efcRounding || false;
            // this.calcConfigSerivce.roundingValue = prefs.roundingValue || 50;
            this.draftPrefs.next(prefs);
            this.checkPreferences();
          }
        })
      ).subscribe();

      if (this.publishSubscription) { this.publishSubscription.unsubscribe(); }
      this.publishSubscription = this.afs.doc<Preferences>('preferences/' + schoolId).valueChanges().pipe(
        tap(prefs => {
          // this.loadedSchoolId = schoolId;
          if (prefs) {
            this.publishedPrefs.next(prefs);
            this.checkPreferences();
          } else {
            this.draftChanges.next(true);
          }
        })
      ).subscribe();
    }

    const activeUsersCol = this.afs.collection<User>('admin_users',
      ref => ref.where('schools', 'array-contains', schoolId).where('admin', '==', false));
    this.activeUsers = activeUsersCol.valueChanges();
  }

  checkPreferences() {
    const draftPrefs = this.draftPrefs.value;
    const pubPrefs = this.publishedPrefs.value;
    if (pubPrefs && draftPrefs) {
      this.draftChanges.next(!isEqual(draftPrefs, pubPrefs));
    }
  }

  savePreferences(prefs: Partial<Preferences>, draft: boolean): void {
    const schoolId = this.loadedSchoolId;
    const prefsRef = draft ? 'preferences_draft/' : 'preferences/';
    this.afs.doc<Preferences>(prefsRef + schoolId).update(prefs);
  }



  unload() {
    if (this.draftSubscription) { this.draftSubscription.unsubscribe(); }
    if (this.publishSubscription) { this.publishSubscription.unsubscribe(); }
    this.draftPrefs.next(null);
    this.publishedPrefs.next(null);
    this.loadedSchoolId = '';
  }

  // private loadImages(prefs: Preferences) {
  private loadImageFromStorage(imagePath: string, defaultImage: string, imageSubject: BehaviorSubject<string>, cssSubject: BehaviorSubject<string>, cssType: string) {
    if (!imagePath) {
      imageSubject.next(defaultImage);
      return;
    }

    this.storage.ref(imagePath).getDownloadURL().pipe(
      tap(url => {
        imageSubject.next(url);
        if (cssType === 'hImage' || cssType === 'bgImage') {
          cssSubject.next('url(' + url + ')');
        }
      }),
      catchError(error => {
        if (error.code === 'storage/object-not-found') {
          console.warn('Missing image: ' + imagePath);
        } else {
          console.error('Error getting download URL:', error);
        }
        // Handle the error here, for example, by emitting a default URL
        imageSubject.next(defaultImage);
        return of(null);
      })
    ).subscribe();
  }

  loadImages(prefs: Preferences) {
    this.loadImageFromStorage(prefs.logoPath, defaultLogo, this.logoURL, null, null);
    this.loadImageFromStorage(prefs.headerBgImagePath, defaultImage, this.headerBgImageURL, this.headerBgImageCss, prefs.headerType);
    this.loadImageFromStorage(prefs.pageBgImagePath, defaultImage, this.pageBgImageURL, this.pageBgImageCss, prefs.backgroundType);
    this.loadImageFromStorage(prefs.welcomeImagePath, defaultImage, this.welcomeImageURL, null, null);

    switch (prefs.headerType) {
      case 'hSolid':
        this.headerBgImageCss.next('');
        break;
      case 'hGradient':
        this.headerBgImageCss.next(prefs.headerGradient);
        break;
    }

    // Not currently used in admin app
    switch (prefs.backgroundType) {
      case 'bgSolid':
        this.pageBgImageCss.next('');
        break;
      case 'bgGradient':
        this.pageBgImageCss.next(prefs.backgroundGradient);
        break;
    }
  }




  setWelcomeImageClass(schoolId, url: string) {
    const objUpdate = {
      welcomeImageClass: {
        'background-image': 'url(' + url + ')',
        'background-size': 'cover',
        'background-position': 'center',
        'margin-bottom': '20px',
        'width': '100%',
        'height': '300px'
      }
    };
    this.afs.doc('preferences_draft/' + schoolId).update(objUpdate);
  }

  uploadImageFile(schoolId: string, file: File, type: 'logo' | 'header' | 'page' | 'welcome'): void {
    const filePath = `${schoolId}/${file.name}`;
    const task = this.storage.upload(filePath, file);

    let objUpdate = {};
    switch (type) {
      case 'logo': objUpdate = { logoPath: filePath }; break;
      case 'header': objUpdate = { headerBgImagePath: filePath }; break;
      case 'page': objUpdate = { pageBgImagePath: filePath }; break;
      case 'welcome': objUpdate = { welcomeImagePath: filePath }; break;
    }

    task.snapshotChanges().pipe(
      tap(url => { }),
      finalize(() => {
        this.afs.doc('preferences_draft/' + schoolId).update(objUpdate);
        this.storage.ref(filePath).getDownloadURL().pipe(
          tap(url => {
            switch (type) {
              case 'logo': this.logoURL.next(url); break;
              case 'header': this.headerBgImageURL.next(url); break;
              case 'page': this.pageBgImageURL.next(url); break;
              case 'welcome':
                this.welcomeImageURL.next(url);
                this.setWelcomeImageClass(schoolId, url);
                break;
            }
          })
        ).subscribe();
      })
    ).subscribe();
  }

  async removeImageRef(schoolId: string, type: string) {
    let obj = {};
    switch (type) {
      case 'logo':
        obj = { logoPath: deleteField() };
        this.logoURL.next(defaultLogo);
        break;
      case 'headerBgImage':
        obj = { headerBgImagePath: deleteField() };
        this.headerBgImageURL.next(defaultImage);
        break;
      case 'pageBgImage':
        obj = { pageBgImagePath: deleteField() };
        this.pageBgImageURL.next(defaultImage);
        break;
      case 'welcome':
        obj = { welcomeImagePath: deleteField(), welcomeImageClass: deleteField() };
        this.welcomeImageURL.next(defaultImage);
        break;
    }
    await this.afs.doc('preferences_draft/' + schoolId).update(obj);

  }

}
