import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Traffic } from '../_models/traffic';
import { ChartDataset, ChartOptions } from 'chart.js';
import { Timestamp } from '@firebase/firestore-types';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TrafficService {
  traffic = new BehaviorSubject<Traffic>(null);
  trafficSubscription: Subscription;
  isLoaded = new BehaviorSubject(false);
  loadedSchoolId = '';
  trafficLineData = new BehaviorSubject<ChartDataset[]>(
    [
      { data: [], label: 'Results', type: 'line' },
      { data: [], label: 'Completion %', type: 'bar' },
    ]

  );
  trafficLabels = new BehaviorSubject<string[]>([]);

  constructor(
    private afs: AngularFirestore
  ) { }


  loadTraffic(schoolId: string) {
    if (schoolId !== this.loadedSchoolId) {
      this.isLoaded.next(false);
      if (this.trafficSubscription) { this.trafficSubscription.unsubscribe(); }
      this.trafficSubscription = this.afs.doc<Traffic>('traffic/' + schoolId).valueChanges().pipe(
        tap(res => {
          if (res) {
            console.log('Traffic loaded (or-reloaded)', schoolId);
            this.traffic.next(res);
            this.populateChartData(12);
            this.loadedSchoolId = schoolId;
            this.isLoaded.next(true);
          } else {
            console.log('No Traffic exists for', schoolId);
          }
        })
      ).subscribe();
    }
  }


  downloadButtonPush() {
    const csvData = this.convertToCSV(this.traffic.value.trafficData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const filename = this.loadedSchoolId + '-traffic.csv';


    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  }

  convertToCSV(objArray: any): string {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    str = 'Date, Results, Visitors\r\n';
    array.forEach(row => {
      str += formatDate(row.date.toDate(), 'MM/dd/yyyy', 'en-US') + ',' + row.results + ',' + row.visitors + '\r\n';
    });
    return str;
  }


  populateChartData(periods?: number, startDate?: Timestamp, endDate?: Timestamp) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const data = this.traffic.value.trafficData;

    if (!startDate) {
      startDate = this.traffic.value.earliestPeriod;
    }

    if (!endDate) {
      endDate = this.traffic.value.latestPeriod;
    }

    if (periods) {
      if (data.length - periods > 0) {
        startDate = data[data.length - periods].date;
      }
    }

    const vWelcome: any[] = data.filter(d => d.date >= startDate && d.date <= endDate)
      .map(x => Math.round(x.results / x.visitors * 100));

    const vNetPrice: any[] = data.filter(d => d.date >= startDate && d.date <= endDate)
      .map(x => x.results);

    const vLabels: any[] = data.filter(d => d.date >= startDate && d.date <= endDate)
      .map(x => months[x.date.toDate().getMonth()] + '-' + x.date.toDate().getFullYear());

    const chartData: ChartDataset[] = [];
    chartData.push({
      label: 'Results', data: vNetPrice, yAxisID: 'Results', type: 'line',
      backgroundColor: 'rgba(0,63,125,0.2)',
      borderColor: 'rgba(0,63,125,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      fill: true,
      tension: .5,
    });
    chartData.push({
      label: 'Completion %', data: vWelcome, yAxisID: 'Completion', type: 'bar',
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,0)',
      datalabels: {
        display: false
      },
    });
    this.trafficLineData.next(chartData);

    this.trafficLabels.next(vLabels);



  }
}
