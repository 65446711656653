<div class="title-bar">
  <h1>PACKAGING</h1>
  <app-help screen="packaging"></app-help>
  <ng-container *ngIf="!editMode">
    <button mat-flat-button color="primary" (click)="setMode('edit')" [disabled]="tabIndex !== 0">Edit Packages </button>
  </ng-container>
  <ng-container *ngIf="editMode">
    <button mat-flat-button color="accent" (click)="setMode('save')">Save Packages</button>
    <button mat-flat-button color="warn" (click)="setMode('cancel')">Cancel</button>
  </ng-container>
  <app-aid-year style="margin-left:auto;" [disabled]="editMode"></app-aid-year>
</div>

<ng-container *ngIf="auth.user | async as user">
  <mat-tab-group mat-stretch-tabs="false" dynamicHeight [selectedIndex]="tabIndex" (selectedIndexChange)="tabIndex = $event">
    <mat-tab label="Listing">
      <mat-card appearance="outlined">
        <div *ngIf="cc.packages | async as packages">

          <table class="table table-hover" style="width:auto" cdkDropList (cdkDropListDropped)="drop($event, packages, user.admin)">
            <thead>
              <tr>
                <th id="population" scope="col" width="500px" style="padding-left: 2rem;">Population</th>
                <th id="category" scope="col">Category</th>
                <th id="type" scope="col">Type</th>
                <th id="amount" scope="col">Amount</th>
                <th id="input" scope="col">Input or Formula</th>
              </tr>
            </thead>

            <tbody *ngFor="let pack of packages; let i = index;" [id]="pack.id" cdkDrag [cdkDragDisabled]="(PackageNamesMap.get(pack.id).required && !user.admin) || !editMode">

              <!-- Package View -->
              <tr [ngClass]="{'disabled-package': !pack.enabled, 'required-package': editMode && PackageNamesMap.get(pack.id).required}">
                <th [id]="pack.id" colspan="5">
                  {{PackageNamesMap.get(pack.id).name}}
                  {{!pack.enabled ? '(disabled)' : ''}}
                  <span *ngIf="editMode">
                    {{PackageNamesMap.get(pack.id).required ? '(required)' : ''}}
                    <span *ngIf="!PackageNamesMap.get(pack.id).required">
                      <mat-button-toggle style="margin-left: 1rem;" [checked]="pack.enabled" (change)="pack.enabled = $event.source.checked">{{!pack.enabled ? 'enable' : 'disable'}}</mat-button-toggle>
                      <button mat-button *ngIf="!pack.enabled" color="warn" style="margin-left: 1rem;" (click)="packages.splice(i,1)">remove package</button>
                    </span>
                  </span>
                </th>
              </tr>


              <!-- 
                  <button *ngIf="i > 3" mat-icon-button (click)="movePackage('up',packages, i)"><img src="./assets/images/icons8-slide-up.svg"
                      height="25px" width="25px"></button>
                  <button *ngIf="i < packages.length - 1" mat-icon-button (click)="movePackage('down',packages, i)"><img
                      src="./assets/images/icons8-down-button.svg" height="25px" width="25px"></button> -->



              <!-- Package Rules -->
              <ng-container *ngFor="let r of pack.rules; let ruleIndex = index;">
                <tr *ngIf="editRule !== r" [ngClass]="{'disabled-package': !pack.enabled}" (keypress)="clickedRow($event, r)" (click)="clickedRow($event, r)" tabindex="0">
                  <td [headers]="pack.id + ' population'">
                    {{r.populationId === '1' ? 'All' : ''}}
                    <div *ngIf="r.population as pop;">{{pop.name}}
                      <div class="description" *ngIf="!editMode">
                        {{pop.description}}</div>
                    </div>
                  </td>
                  <td [headers]="pack.id + ' category'">{{r.category}}</td>
                  <td [headers]="pack.id + ' type'">{{r.assignType}}</td>
                  <td [headers]="pack.id + ' amount'">
                    {{r.assignType === 'Fixed' ? (r.amount | number) : ''}}
                    {{r.assignType === 'Factor' ? r.factor + '%' : ''}}
                  </td>
                  <td [headers]="pack.id + ' input'">
                    <div *ngIf="r.keyId">
                      {{getSelectorName(r.keyId)}}
                    </div>
                    <div *ngIf="r.formulaId">
                      {{r.formula.name}}
                      <br>
                      <span class="description" *ngIf="!editMode">{{r.formula.description}}</span>
                    </div>
                    <div *ngIf="r.assignType === 'Table'" class="link">{{r.table?.name}}</div>
                  </td>
                </tr>

                <!-- Edit a package rule -->
                <tr *ngIf="editRule === r" (keypress)="clickedRow($event, r)" (click)="clickedRow($event, r)" tabindex="0">
                  <td [headers]="pack.id + ' population'">
                    <mat-form-field class="form-field" style="width: 20rem;">
                      <mat-select placeholder="Select Population" [(value)]="r.populationId" (selectionChange)="setRulePopulation(r)" [aria-labelledby]="'Select Population ' + getPopulationName(r.populationId)">
                        <mat-option value='1' id="All">All</mat-option>
                        <mat-option *ngFor="let pop of cc.populations | async" [value]=pop.id>
                          {{pop.name}}
                        </mat-option>
                      </mat-select>
                      <mat-hint *ngIf="r.population as pop;">{{pop.description.substring(0,100) + (pop.description.length > 100 ? '...' : '')}}</mat-hint>
                    </mat-form-field>


                    <div>
                      <button mat-button color="warn" style="padding-left: 0;" (click)="pack.rules.splice(ruleIndex,1)">remove rule</button>
                    </div>
                  </td>
                  <td [headers]="pack.id + ' category'">
                    <mat-form-field style="width: 8rem;">
                      <mat-select placeholder="Category" [(value)]="r.category" [aria-labelledby]="'Category ' + r.category">
                        <mat-option value="Assignment">Assignment</mat-option>
                        <mat-option value="Cap">Cap</mat-option>
                        <!-- TODO: Disable if there is already a floor (or move floor rules to package) -->
                        <mat-option value="Floor" [disabled]="false">Floor</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td [headers]="pack.id + ' type'">
                    <mat-form-field style="width: 8rem;">
                      <mat-select placeholder="Type" [(value)]="r.assignType" (selectionChange)="onAssignTypeChange(r)" [aria-labelledby]="'Type ' + r.assignType">
                        <mat-option value="Factor">Factor</mat-option>
                        <mat-option value="Fixed">Fixed</mat-option>
                        <mat-option value="Table">Table</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td [headers]="pack.id + ' amount'">
                    <div *ngIf="r.assignType === 'Fixed'">
                      <mat-form-field style="width: 5em;">
                        <input matInput placeholder="Amount" type="number" [(ngModel)]="r.amount">
                      </mat-form-field>
                    </div>
                    <div *ngIf="r.assignType === 'Factor'">
                      <mat-form-field style="width: 5em;">
                        <input matInput placeholder="Factor" type="number" [(ngModel)]="r.factor" min="0" max="100" pattern="^$|^([0-9]|[1-9][0-9]|[1][0][0])?">
                        <span matSuffix>%</span>
                      </mat-form-field>
                    </div>
                  </td>
                  <td [headers]="pack.id + ' input'">
                    <div *ngIf="r.assignType === 'Factor'">
                      <input-selector [key]="r" [caller]="'Package'"></input-selector>
                    </div>
                    <div *ngIf="r.assignType === 'Table'">
                      <mat-form-field>
                        <mat-select placeholder="Select Table" [(value)]="r.table" (selectionChange)="r.tableId = r.table.tableId" [aria-labelledby]="'Select Table ' + (r.table ? r.table.name : '')">
                          <mat-option *ngFor="let table of cc.tables | async" [value]=table>
                            {{table.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
              </ng-container>
              <tr class="table-sm" *ngIf="editMode">
                <td colspan="5" style="padding-left: 1rem;"><button mat-button color="accent" style="line-height: 24px;" (click)="addRule(pack)">add rule</button></td>
              </tr>



            </tbody>

          </table>



          <div *ngIf="editMode">
            <mat-form-field style="margin-left: 1rem;">
              <mat-select placeholder="Add Unused Package">
                <mat-option (onSelectionChange)="addPackage(pack.id, packages)" *ngFor="let pack of getUnusedPackages(packages)">
                  {{pack.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <p></p>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Overrides">
      <p></p>
      <app-package-overrides></app-package-overrides>
    </mat-tab>


    <mat-tab [label]="tableName" [disabled]="tableName === ''">
      <app-table *ngIf="tableName !== ''" [table]="table"></app-table>
    </mat-tab>

  </mat-tab-group>
</ng-container>