<h2 mat-dialog-title>Selector Changes</h2>
<div mat-dialog-content>
    <p>The following changes were automatically made during the aid year switch.</p>
    <table class="table">
        <thead>
            <th scope="col">Entity</th>
            <th scope="col">Name</th>
            <th scope="col">Old Selector</th>
            <th scope="col">New Selector</th>
        </thead>
        <tr *ngFor="let aidYearChange of data">
            <td>{{aidYearChange.entity}}</td>
            <td>{{aidYearChange.name}}</td>
            <td>{{aidYearChange.oldSelector}}</td>
            <td>{{aidYearChange.newSelector}}</td>
        </tr>
    </table>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" style="margin-left:8px;">Cancel</button>
    <button mat-button (click)="copyToClipboard()" style="margin-left:8px;">Copy to Clipboard</button>
</div>