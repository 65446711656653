import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../_shared/shared.module';

/* Components */
import { ConfigurationComponent } from './configuration/configuration.component';
import { NoAccessComponent } from '../no-access/no-access.component';
import { BrandingComponent } from './branding/branding.component';
import { ContentComponent } from './content/content.component';
import { QuestionsComponent } from './questions/questions.component';
import { PopulationsComponent } from './populations/populations.component';
import { SuperadminComponent } from './superadmin/superadmin.component';
import { PopulationComponent } from './population/population.component';
import { PackagingComponent } from './packaging/packaging.component';
import { CostsComponent } from './costs/costs.component';
import { InspectorComponent } from './inspector/inspector.component';
import { TableComponent } from './table/table.component';
import { TablesComponent } from './tables/tables.component';
import { PackageOverridesComponent } from './package-overrides/package-overrides.component';
import { KeySelectComponent } from './key-select/key-select.component';
import { InputSelectorComponent } from './input-selector/input-selector.component';
import { FormulasComponent } from './formulas/formulas.component';
import { FormulaComponent } from './formula/formula.component';
import { UsageComponent } from './usage/usage.component';
import { QuestionsAdminComponent } from './questions-admin/questions-admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SessionsComponent } from './sessions/sessions.component';
import { DraftModeComponent } from './draft-mode/draft-mode.component';
import { PublishPreferencesComponent } from './publish-preferences/publish-preferences.component';
import { TrafficChartComponent } from './traffic-chart/traffic-chart.component';
import { QuestionComponent } from './question/question.component';
import { PublishComponent } from './publish/publish.component';
import { ApiErrorsComponent } from './api-errors/api-errors.component';
import { InasComparisonComponent } from './inas-comparison/inas-comparison.component';

// Third Party components
import { QuillModule } from 'ngx-quill';
import { FilterPipe } from '../_pipes/filter.pipe';
import { NgChartsModule } from 'ng2-charts';

import { DragDropModule } from '@angular/cdk/drag-drop';

// TODO: Check to see if we need all of these
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';




const materialModules = [
    MatSelectModule, MatCheckboxModule,
    MatOptionModule, MatRadioModule,
    MatCardModule, MatTabsModule, MatAutocompleteModule, MatButtonToggleModule,
    MatSortModule
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        QuillModule.forRoot(),
        materialModules,
        NgChartsModule
    ],
    declarations: [
        BrandingComponent, ConfigurationComponent, QuestionsComponent,
        NoAccessComponent, PopulationsComponent, SuperadminComponent, ContentComponent,
        PopulationComponent, PackagingComponent, CostsComponent, InspectorComponent,
        PackageOverridesComponent, TableComponent, KeySelectComponent,
        TablesComponent, InputSelectorComponent,
        FormulasComponent, FormulaComponent, UsageComponent, QuestionsAdminComponent,
        DashboardComponent, SessionsComponent,
        DraftModeComponent, PublishPreferencesComponent, TrafficChartComponent, QuestionComponent,
        FilterPipe,
        PublishComponent,
        ApiErrorsComponent,
        InasComparisonComponent
    ],
    exports: [
        materialModules,
        DragDropModule
    ]
})
export class DashboardModule { }
