import { Formula } from '../_models/formula';
import { Question } from '../_models/question';

export const ConditionMap = ['is', '>', '>=', '<', '<=', 'is not', 'between'];

export interface Population {
    id: string;
    name: string;
    description: string;
    nextStep: string;
    groupOperatorAnd: boolean; // true is and, false is or - operator on condition group
    blocks: Block[];
}

export interface Block {
    blockOperatorAnd: boolean;
    conditions: Condition[];
}
export interface Condition {
    type: 'Question' | 'Calculation' | 'Formula';
    keyId?: string; // e.x. maps to questionId if NPCInput
    formulaId?: string;
    formula?: Formula;
    question?: Question;

    format: 'Code' | 'Number' | 'Text' | 'Formula';
    operator: number;   // should be interface tied to numbers (use ConditionMap)
    value: string | number;      // Can be comma separated values - multi-selected answers from drop down
    value2?: string;     // used for "between" comparisons as upper bound
    values?: string[];  // If value has comma separated values than split them into this array
    order: number;
}
