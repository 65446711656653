import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { User } from '../_models/user'; // Adjust the import path as needed

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.user.pipe(
            take(1),
            map(user => this.navigateUser(user))
        );
    }

    private navigateUser(user: User | null): UrlTree {
        if (!user) {
            return this.router.createUrlTree(['/admin-login']);
        }

        if (user.admin) {
            return this.router.createUrlTree(['/schools']);
        }

        if (user.schools.length === 0) {
            return this.router.createUrlTree(['/no-access']);
        }

        if (user.schools.length === 1) {
            const schoolId = user.schools[0];
            return this.router.createUrlTree([`/${schoolId}/dashboard`]);
        }

    }
}
