import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { QuestionsService } from '_services/questions.service';
import { Question, Choice } from '../../_models/question';

import { filter, findIndex } from 'lodash-es';

import { MatTabGroup } from '@angular/material/tabs';

import { MatDialog } from '@angular/material/dialog';
import { QuestionComponent } from '../question/question.component';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

interface Column {
  def: string;
  label: string;
  show: boolean;
}

@Component({
  selector: 'app-questions-admin',
  templateUrl: './questions-admin.component.html',
  styleUrls: ['./questions-admin.component.css']
})
export class QuestionsAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tabgroup') tabGroup: MatTabGroup;
  showHelp = false;
  showDeleted = false;
  showSchoolSpecific = false;
  showFlagged = false;  // Used for annual updates
  questionsCopy: Question[] = [];
  editMode = false;
  tabIndex = 0;
  questionId = '';
  question: Question;
  questionCopy: Question;

  filterFunction: any;

  tabs = [
    { label: 'Universal', code: 'UN' },
    { label: 'Starting', code: 'ST' },
    { label: 'Dependency', code: 'DS' },
    { label: 'Parent Household', code: 'HP' },
    { label: 'Parent Income', code: 'PI' },
    { label: 'Parent Assets', code: 'PA' },
    { label: 'Student Finances', code: 'SF' },
    { label: 'Custom Available', code: 'SI' },
    { label: 'School Specific', code: 'SS' },
    { label: 'All', code: 'ALL' },
    { label: 'Updated', code: 'UPD' },
    // { label: 'Flagged', code: 'FLAG' }
  ];

  startingTabIndex = 0; // Start on Universal tab
  tabCode = this.tabs[this.startingTabIndex].code;

  questionsControl = new UntypedFormControl();
  questionsControlSubscription: Subscription;

  filteredQuestions: BehaviorSubject<Question[]> = new BehaviorSubject<Question[]>(null);

  dataSource: MatTableDataSource<Question>;
  dataSourceSubscription: Subscription;
  questionsSubscription: Subscription;

  columnDefinitions: Column[] = [
    { def: 'question', label: 'Questions', show: true },
    { def: 'independent', label: 'Ind/Dep-endent', show: this.tabCode === 'SF' || this.tabCode === 'FLAG' },
    { def: 'imRequired', label: 'IM Required', show: this.tabCode !== 'SI' && this.tabCode !== 'SS' },
    { def: 'fmRequired', label: 'FM Required', show: this.tabCode !== 'SI' && this.tabCode !== 'SS' },
    { def: 'refineCalc', label: 'Refine Calc', show: this.tabCode !== 'SI' && this.tabCode !== 'SS' },
    { def: 'custom', label: 'Custom Available', show: this.tabCode === 'SI' },
    { def: 'schoolSpecific', label: 'School Specific', show: this.tabCode === 'SI' && this.editMode },
    { def: 'schoolCode', label: 'School Code', show: this.tabCode === 'SS' },
    { def: 'format', label: 'Format', show: true },
    { def: 'section', label: 'Section', show: this.tabCode === 'ALL' || this.tabCode === 'UN' },
    { def: 'dateUpdated', label: 'Updated', show: true },
  ]

  displayedColumns: string[] = ['question', 'independent', 'imRequired', 'fmRequired', 'refineCalc', 'custom', 'schoolSpecific', 'schoolCode', 'format', 'section', 'dateUpdated'];

  public selection: SelectionModel<Question>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public qs: QuestionsService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {
    this.titleService.setTitle('Admin Questions' + ' | NPC Admin');
  }


  ngOnInit() {
    const schoolId = this.route.snapshot.parent?.paramMap.get('schoolId') ?? '';

    // initialize dropdown to starting
    this.questionsControl.setValue(this.tabs[this.startingTabIndex]);
    this.tabCode = this.tabs[this.startingTabIndex].code;

    this.questionsControlSubscription = this.questionsControl.valueChanges.subscribe(value => {
      const questionCategory = value ? value : null;
      this.tabCode = questionCategory.code;
      this.setFilter(this.tabCode);
      this.setColShows();
    });

    this.questionsSubscription = this.qs.allQuestions.subscribe(res => {
      if (res) {
        this.setFilter(this.tabCode);
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSourceSubscription = this.filteredQuestions.subscribe(res => {
      if (res) {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.selection = new SelectionModel<Question>(true);
        // this.setSort();

        this.cd.detectChanges();
      }
    });
  }

  private setColShows(): void {
    this.columnDefinitions[1].show = this.tabCode === 'SF' || this.tabCode === 'FLAG';
    this.columnDefinitions[2].show = this.tabCode !== 'SI' && this.tabCode !== 'SS';
    this.columnDefinitions[3].show = this.tabCode !== 'SI' && this.tabCode !== 'SS';
    this.columnDefinitions[4].show = this.tabCode !== 'SI' && this.tabCode !== 'SS';
    this.columnDefinitions[5].show = this.tabCode === 'SI';
    this.columnDefinitions[6].show = this.tabCode === 'SI' && this.editMode;
    this.columnDefinitions[7].show = this.tabCode === 'SS';
    this.columnDefinitions[9].show = this.tabCode === 'ALL';
  }

  public setFilter(code: string): void {
    const filteredQs = this.qs.allQuestions.value.filter(q => !q.dateDeleted || this.showDeleted);

    switch (code) {
      case 'UN':
        this.filteredQuestions.next(filteredQs.filter(q => q.section_id !== 'SI')); break;
      case 'ALL':
        this.filteredQuestions.next(filteredQs); break;
      case 'FLAG':
        this.filteredQuestions.next(filteredQs.filter(q => q.flag === true)); break;
      case 'SI':
        this.filteredQuestions.next(filteredQs.filter(q => q.section_id === code && q.schoolSpecific !== true)); break;
      case 'SS':
        this.filteredQuestions.next(filteredQs.filter(q => q.section_id === 'SI' && q.schoolSpecific === true)); break;
      case 'UPD':
        this.filteredQuestions.next(filteredQs.filter(q => q.dateUpdated)); break;
      default:
        this.filteredQuestions.next(filteredQs.filter(q => q.section_id === code)); break;
    }
  }

  getDisplayedColumns(): string[] {
    return this.columnDefinitions.filter(col => col.show).map(col => col.def);
  }

  qTrack(index, item) {
    return index;
  }

  addQuestion() {
    const dialogRef = this.dialog.open(QuestionComponent, {
      width: '1050px'
    });

    dialogRef.afterClosed().subscribe(saveQuestion => {
      if (saveQuestion?.id) {
        saveQuestion.dateCreated = new Date(Date.now());
        saveQuestion.dateUpdated = new Date(Date.now());
        const questions = this.qs.allQuestions.value;
        questions.push(saveQuestion);
        questions.sort((n1, n2) => n1.ordinal - n2.ordinal);
        this.qs.saveAllQuestions(questions, this.qs.allQuestionsLoadedAidYear);
        this.setFilter(this.tabCode);
      }
    });
  }

  clickedRow(question?: Question) {
    if (question && !this.editMode) {
      const dialogRef = this.dialog.open(QuestionComponent, {
        width: '1050px',
        data: { question: question }
      });

      dialogRef.afterClosed().subscribe(saveQuestion => {
        if (saveQuestion?.id) {
          const questions = this.qs.allQuestions.value;
          const index = findIndex(questions, ['id', saveQuestion.id]);

          // compare to see if any changes were made
          if (JSON.stringify(saveQuestion) !== JSON.stringify(questions[index])) {
            saveQuestion.dateUpdated = new Date(Date.now());
            questions[index] = saveQuestion;
            this.qs.saveAllQuestions(questions, this.qs.allQuestionsLoadedAidYear);
          }
          this.setFilter(this.tabCode);
        }
      });

    }
  }


  manuallyRemoveQuestions() {
    const questionIDs = ['student.trying'];
    const questions = this.qs.allQuestions.value;

    questionIDs.forEach(qID => {
      const index = findIndex(questions, ['id', qID]);
      questions.splice(index, 1);
    });
    this.qs.allQuestions.next(questions);
  }


  setMode(mode: 'edit' | 'save' | 'cancel') {
    switch (mode) {
      case 'edit':
        // this.tabIndex = 0;
        this.questionsCopy = JSON.parse(JSON.stringify(this.qs.allQuestions.value)); // lodash has a deep clone function
        this.editMode = true;
        // this.manuallyAddQuestion();
        // this.manuallyRemoveQuestions();
        break;
      case 'cancel':
        this.qs.allQuestions.next(this.questionsCopy);
        this.editMode = false;
        break;
      case 'save':

        const newQuestions = this.qs.allQuestions.value

        this.compareQuestions(this.questionsCopy, newQuestions);

        this.qs.saveAllQuestions(newQuestions, this.qs.allQuestionsLoadedAidYear);
        this.editMode = false;
        break;
    }
  }

  compareQuestions(oldCopy: Question[], newCopy: Question[]) {
    // compare each question in the array and set the flag to true if they are different
    newCopy.forEach((newQuestion) => {
      newQuestion.flag = false;
    });
  }


  ngOnDestroy() {
    this.dataSourceSubscription?.unsubscribe();
    this.questionsSubscription?.unsubscribe();
    this.questionsControlSubscription?.unsubscribe();
  }
}


