import { Component, Inject } from '@angular/core';
import { Question, Choice } from '../../_models/question';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '_services/auth.service';

export interface QuestionData {
  question: Question;
}
@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent {
  editMode = false;
  asc = 1;
  question: Question;

  bindings = {
    // This will overwrite the default binding also named 'tab'
    tab: {
      key: 9,
      handler: function () {
        return true;
      }
    }
  };


  modules = {
    clipboard: {
      matchVisual: false
    },
    keyboard: {
      bindings: this.bindings
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      ['link']                         // link and image, video
    ]
  };

  constructor(
    public dialogRef: MatDialogRef<QuestionComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: QuestionData
  ) {
    this.setDefaults();
  }

  private setDefaults() {
    if (this.data && this.data.question) {
      this.question = JSON.parse(JSON.stringify(this.data.question));
    } else {
      this.question = {
        section_id: 'SI',

        format: 'Number',
        id: '',
        label: '',
        ordinal: 15000,
        choices: [],
        help: '',

        value: '',

        hidden: false,
        indent: false,
        required: true,

        dependent: true,
        independent: true,

        methodRequired: false,

        flag: false // Used to flag questions changed in annual review
      };
      this.editMode = true;
    }
  }

  deleteQuestion() {
    this.question.dateDeleted = new Date(Date.now());
    this.editMode = false;
  }

  sortChoices(choices: Choice[]) {
    choices.sort((a, b) => (a.value > b.value) ? 1 * this.asc : -1 * this.asc);
    let ord = 1;
    choices.forEach(choice => choice.ord = ord++);
    this.asc *= -1;
  }

  addRow(choices: Choice[]) {
    if (choices.length === 0) {
      this.question.choices = [{
        label: '',
        value: '',
        ord: 1
      }];
    } else {
      const aChoice: Choice = {
        label: '',
        value: '',
        ord: choices[choices.length - 1].ord + 1
      };
      choices.push(aChoice);
    }
  }


  deleteRow(choices: Choice[], index: number) {
    if (choices.length > 1) {
      choices.splice(index, 1);
    } else {
      alert('Cannot delete last row');
    }
  }

}
