import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectorChangesDialogComponent } from '_shared/selector-changes-dialog/selector-changes-dialog.component';
import { CalcConfigService } from './calc-config.service';
import { QuestionsService } from './questions.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { AidYearChange, CalcRules } from '../_models/calcPackage';
import { UnivPrefsService } from './univ-prefs.service';
import { AuthService } from './auth.service';
import { Question, SchoolQuestions } from 'app/_models/question';

@Injectable({
  providedIn: 'root'
})
export class AidYearChangeService {

  constructor(
    private dialog: MatDialog,
    private ups: UnivPrefsService,
    private cc: CalcConfigService,
    private qs: QuestionsService,
    private afs: AngularFirestore,
    private auth: AuthService
  ) { }


  // Aid Year switching logic
  public async switchAidYear(aidYear: number) {
    const schoolId = this.cc.loadedSchoolId;
    if (!schoolId) { return; }
    const draftRules = this.cc.draftRules.value;
    if (!draftRules) { return; }
    const draftAidYear = draftRules.schoolProperties?.aidYear;
    if (!draftAidYear) { return; }
    // If the draft rules are already in this aid year, do nothing
    if (draftAidYear === aidYear) { return; }
    // Before switching rules, save the current draft rules as aid year rules
    await this.afs.doc<CalcRules>('calcConfig/' + schoolId + '/calcRules/' + draftAidYear).set(draftRules);
    // If the draft rules are not in this aid year, check to see if rules for this aid year exist
    const aidYearRules = await firstValueFrom(this.afs.doc<CalcRules>('calcConfig/' + schoolId + '/calcRules/' + aidYear).valueChanges());
    // Turn off subscription while we update
    if (this.cc.draftSubscription) { this.cc.draftSubscription.unsubscribe(); }
    // If they do, switch the draft rules to that aid year
    if (aidYearRules) {
      await this.afs.doc<CalcRules>('calcConfig/' + schoolId + '/calcRules/draft').set(aidYearRules);
    }
    const forceWrite = false;
    const removedQuestionIds = await this.switchAidYearQuestions(aidYear, forceWrite);
    // If they do not, create a new draft rule for that aid year
    if (!aidYearRules || (forceWrite && aidYear === 2425)) {
      draftRules.schoolProperties.aidYear = aidYear;
      draftRules.lastUpdated = new Date();
      draftRules.lastUpdatedBy = (await this.auth.currentUser()).uid;
      // If switching to the 2425 aid year, we need to auto-fix rules using selectors that are no longer valid
      if (aidYear === 2425) {
        this.fixRulesUsing2425Selectors(draftRules, aidYear, removedQuestionIds);
      }
      await this.afs.doc<CalcRules>('calcConfig/' + schoolId + '/calcRules/draft').set(draftRules);
    }
    // Update the aid year in the prefrerences document
    const stringAidYear = `20${aidYear.toString().slice(0, 2)}-20${aidYear.toString().slice(2)}`;
    this.ups.savePreferences({ aidYear: stringAidYear }, true);
    // Update the school's aid year in the school document
    await this.afs.doc('schools/' + schoolId).set({ schoolProperties: { draftAidYear: aidYear } }, { merge: true });
    // Reload the draft rules
    this.cc.loadDraftCalcRules(schoolId, true);
  }

  private fixRulesUsing2425Selectors(rules: CalcRules, aidYear: number, removedQuestionIds?: string[]) {
    const populations = rules.populations;
    const formulas = rules.formulas;
    const tables = rules.tables;
    const packages = rules.packages;
    // Expenses do not have selectors, so we don't need to fix them
    const aidYearChanges: AidYearChange[] = [];

    const keyChanges = [
      { oldKey: 'inarParentEfmResults.AdjustedGrossIncome', newKey: 'inarParentEfmResults.IncomeAdditions' },
      { oldKey: 'inarParentEfmResults.ContributionForStudent', newKey: 'inarMiscCalculations.efmSAINoNegative' },
      { oldKey: 'inarParentEfmResults.TotalContribution', newKey: 'inarMiscCalculations.efmSAINoNegative' },
      { oldKey: 'inarStudentEfmResults.AdjustedGrossIncome', newKey: 'inarStudentEfmResults.IncomeAdditions' },
      { oldKey: 'inarStudentEfmResults.ContributionForStudent', newKey: 'inarMiscCalculations.efmSAINoNegative' },
      { oldKey: 'inarStudentEfmResults.TotalContribution', newKey: 'inarMiscCalculations.efmSAINoNegative' },
    ]

    if (removedQuestionIds) {
      removedQuestionIds.forEach(id => {
        keyChanges.push({ oldKey: id, newKey: id });
        aidYearChanges.push({ aidYear: aidYear, entity: 'question', id: id, name: id, oldSelector: id, newSelector: 'deleted' });
      });
    }

    // Fix populations
    populations.forEach(pop => {
      pop.blocks.forEach(block => {
        block.conditions.forEach(cond => {
          keyChanges.forEach(change => {
            if (cond.keyId === change.oldKey) {
              cond.keyId = change.newKey;
              aidYearChanges.push({ aidYear: aidYear, entity: 'population', id: pop.id, name: pop.name, oldSelector: change.oldKey, newSelector: change.newKey });
            }
          })
        })
      })
    });

    // Fix tables
    tables.forEach(table => {
      keyChanges.forEach(change => {
        if (table.keyId === change.oldKey) {
          table.keyId = change.newKey;
          aidYearChanges.push({ aidYear: aidYear, entity: 'table', id: table.tableId, name: table.name, oldSelector: change.oldKey, newSelector: change.newKey });
        }
      })
    });

    // Fix packages
    packages.forEach(pkg => {
      pkg.rules.forEach(rule => {
        keyChanges.forEach(change => {
          if (rule.keyId === change.oldKey) {
            rule.keyId = change.newKey;
            aidYearChanges.push({ aidYear: aidYear, entity: 'package', id: pkg.id, name: pkg.id, oldSelector: change.oldKey, newSelector: change.newKey });
          }
        })
      })
    });

    // Fix formulas
    formulas.forEach(formula => {
      keyChanges.forEach(change => {
        if (formula.formula.includes(change.oldKey)) {
          formula.formula = formula.formula.replace(change.oldKey, change.newKey);
          aidYearChanges.push({ aidYear: aidYear, entity: 'formula', id: formula.formulaId, name: formula.name, oldSelector: change.oldKey, newSelector: change.newKey });
        }
      })
    });

    // Update draft rules with changes
    rules.populations = populations;
    rules.tables = tables;
    rules.packages = packages;
    rules.formulas = formulas;

    // Show dialog box with changes
    if (aidYearChanges.length > 0) {
      this.afs.doc('calcConfig/' + this.cc.loadedSchoolId + '/aidYearChanges/' + aidYear).set({ aidYearChanges: aidYearChanges, lastUpdated: new Date() });
      this.dialog.open(SelectorChangesDialogComponent, { data: aidYearChanges });
    }
  }


  private async switchAidYearQuestions(aidYear: number, forceWrite: boolean): Promise<string[]> {
    console.log('Switching aid year for schoolId', this.qs.loadedSchoolId, 'aidYear', this.qs.loadedAidYear, 'switching to', aidYear);

    // If no school is loaded, do nothing
    const schoolId = this.qs.loadedSchoolId;
    if (!schoolId) { return; }

    // If no draft questions are loaded, do nothing
    const draftQuestions = this.qs.draftQuestions.value;
    if (!draftQuestions || draftQuestions.length === 0) { return; }

    // If no draft aid year is loaded, do nothing
    const draftAidYear = this.qs.loadedAidYear;
    if (!draftAidYear) { return; }

    // If the draft rules are already in this aid year, do nothing
    if (draftAidYear === aidYear) { return; }

    const schoolQuestions: SchoolQuestions = {
      schoolId: schoolId,
      aidYear: draftAidYear,
      questions: draftQuestions,
      lastUpdated: new Date(Date.now()),
      lastUpdatedBy: (await this.auth.currentUser()).uid
    };

    // Before switching rules, save the current draft rules as aid year rules
    await this.afs.doc<SchoolQuestions>('calcConfig/' + schoolId + '/questions/' + draftAidYear).set(schoolQuestions);

    // Draft rules are not loaded for this aid year, check to see if rules for this aid year exist
    const aidYearQuestions = await firstValueFrom(this.afs.doc<SchoolQuestions>('calcConfig/' + schoolId + '/questions/' + aidYear).valueChanges());

    // If they do, switch the draft rules to that aid year
    if (aidYearQuestions) {
      await this.afs.doc<SchoolQuestions>('calcConfig/' + schoolId + '/questions/draft').set(aidYearQuestions);
    }

    let removedIds = [];

    // If they do not, create a new draft rule for that aid year
    if (!aidYearQuestions || (forceWrite && aidYear >= 2425)) {
      // If switching to the 2425 aid year, we need to auto-fix rules using selectors that are no longer valid
      if (aidYear >= 2425) {
        const { questions: modifiedQuestions, removedQuestionIds } = await this.checkForModifiedQuestions(draftQuestions, aidYear);
        removedIds = removedQuestionIds;
        schoolQuestions.questions = modifiedQuestions;
      }
      await this.afs.doc<SchoolQuestions>('calcConfig/' + schoolId + '/questions/draft').set(schoolQuestions);
    }

    // Legacy support until and unless we switch to using the calcConfig collection to power the NPC
    if (this.qs.draftSubscription) { this.qs.draftSubscription.unsubscribe(); }
    await this.afs.doc('questions_draft/' + schoolId).set({ questions: aidYearQuestions ? aidYearQuestions.questions : schoolQuestions.questions });
    this.qs.loadQuestions(schoolId, aidYear);

    return removedIds;
  }

  private async checkForModifiedQuestions(questions: Question[], movingToAidYear: number): Promise<{ questions: Question[], removedQuestionIds: string[] }> {
    // Check to see if any questions have been changed or deleted and make modifications to the draft rules
    console.log('Checking for modified questions');
    const allQuestions = await this.qs.getAllQuestionsForAidYear(movingToAidYear);
    if (!allQuestions || allQuestions.length === 0) { return; }

    const modifiedQuestions = allQuestions.filter(q => q.dateDeleted || q.dateUpdated);

    if (modifiedQuestions.length === 0) { return; }

    const removedQuestionIds: string[] = [];

    modifiedQuestions.forEach(newQuestion => {
      const index = questions.findIndex(question => question.id === newQuestion.id);
      if (index === -1) { return; }
      // For deleted or updated questions, copy updates to the questions
      questions[index] = newQuestion;
      if (newQuestion.dateDeleted) {
        questions.splice(index, 1);
        removedQuestionIds.push(newQuestion.id);
      }
    });

    if (movingToAidYear === 2526) {
      const newQuestions = this.moveChildSupportQuestionstoTrigger(questions, allQuestions);
      return { questions: newQuestions, removedQuestionIds };
    }

    return { questions, removedQuestionIds };

  }

  // One time shift of child support questions to trigger for aid year 2526;
  private moveChildSupportQuestionstoTrigger(questions: Question[], allQuestions: Question[]): Question[] {

    const parentChildSupportQuestionsExist = ['parent.childSupportReceived', 'parent.childSupportPaidAmount'].some(q => questions.find(question => question.id === q));
    const studentChildSupportQuestionsExist = ['student.childSupportReceived', 'student.childSupportPaidAmount'].some(q => questions.find(question => question.id === q));

    if (!parentChildSupportQuestionsExist && !studentChildSupportQuestionsExist) {
      return questions;
    }

    // remove the questions from the questions array
    const questionsCopy = questions.filter(q => q.id !== 'parent.childSupportReceived' && q.id !== 'parent.childSupportPaidAmount' && q.id !== 'student.childSupportReceived' && q.id !== 'student.childSupportPaidAmount');

    if (parentChildSupportQuestionsExist) {
      const childSupportTrigger = allQuestions.find(q => q.id === 'parent.childSupportTrigger');
      questionsCopy.push(childSupportTrigger);
      const childSupportReceived = allQuestions.find(q => q.id === 'parent.childSupportReceived');
      questionsCopy.push(childSupportReceived);
      const childSupportPaidAmount = allQuestions.find(q => q.id === 'parent.childSupportPaidAmount');
      questionsCopy.push(childSupportPaidAmount);
    }

    if (studentChildSupportQuestionsExist) {
      const childSupportTrigger = allQuestions.find(q => q.id === 'student.childSupportTrigger');
      questionsCopy.push(childSupportTrigger);
      const childSupportReceived = allQuestions.find(q => q.id === 'student.childSupportReceived');
      questionsCopy.push(childSupportReceived);
      const childSupportPaidAmount = allQuestions.find(q => q.id === 'student.childSupportPaidAmount');
      questionsCopy.push(childSupportPaidAmount);
    }
    console.log('questions.length', questionsCopy.length);

    return questionsCopy;
  }


  // This is called when the aid year is not found in the calculation rules.  It is a fallback.
  public async setDraftAidYearFromPreferences() {
    // Wait for first non null value from draft Preferences
    const draftPrefs = await this.ups.draftPrefs.getValue();
    if (!draftPrefs) { return; }

    const aidYear = draftPrefs.aidYear
    if (aidYear) {
      const aidYearNumber = parseInt(aidYear.slice(2, 4) + aidYear.slice(7, 9));
      console.log('Updating draft Rules with aidyear found in preferences:', aidYearNumber);
      const draftProperties = this.cc.draftRules.value?.schoolProperties;
      draftProperties.aidYear = aidYearNumber;
      this.cc.saveDraftRules(this.cc.loadedDraftSchoolId, { schoolProperties: draftProperties });
    } else {
      alert('No aid year found in preferences');
    }
  }


}
