import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { AidYearChange } from 'app/_models/calcPackage';

@Component({
  selector: 'app-selector-changes-dialog',
  templateUrl: './selector-changes-dialog.component.html',
  styleUrls: ['./selector-changes-dialog.component.css']
})
export class SelectorChangesDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SelectorChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AidYearChange[],
    private clipboard: Clipboard
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


  copyToClipboard(): void {
    const headers = ['Entity', 'Name', 'Old Selector', 'New Selector'];
    const rows = this.data.map(aidYearChange => [aidYearChange.entity, aidYearChange.name, aidYearChange.oldSelector, aidYearChange.newSelector]);
    const table = [headers, ...rows].map(row => row.join('\t')).join('\n');
    this.clipboard.copy(table);
  }
}