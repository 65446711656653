import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SelectorsService, Selector, KeyGroup } from '../../_services/selectors.service';

import { isString } from 'lodash-es';

export const _filter = (opt: Selector[], value: string): Selector[] => {
  // Filter Selectors by their label or the group name
  const filterValue = value.toLowerCase();
  return opt.filter(item => (item.label.toLowerCase().indexOf(filterValue) >= 0 || item.group.toLowerCase().indexOf(filterValue) >= 0));
};

@Component({
  selector: 'app-key-select',
  templateUrl: './key-select.component.html',
  styleUrls: ['./key-select.component.css']
})
export class KeySelectComponent implements OnInit {

  @Input() value = '';
  @Output() change: EventEmitter<string> = new EventEmitter<string>();

  keySelect = new UntypedFormControl();
  filteredKeys: Observable<KeyGroup[]>;
  selectedKey: Selector;

  constructor(private fb: UntypedFormBuilder, private ss: SelectorsService) { }

  ngOnInit() {
    this.filteredKeys = this.keySelect.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value))
      );
    this.selectedKey = this.ss.getSelector(this.value);
    if (!this.selectedKey.isQuestion) {
      this.keySelect.setValue(this.selectedKey);
    }


  }
  emitChange(event: any) {
    this.selectedKey = event.option.value;
    this.change.emit(this.selectedKey.id);
  }
  checkField(panelShowing: boolean) {
    if (this.keySelect.value !== this.selectedKey && this.keySelect.value !== '' && !panelShowing ) {
      this.keySelect.setValue('');
    }
  }

  displayFn(id) {
    return id ? id.label : id;
  }

  private _filterGroup(value: string): KeyGroup[] {
    if (isString(value)) {
      return this.ss.groupedKeys
        .map(group => ({ section: group.section, selectors: _filter(group.selectors, value) }))
        .filter(group => group.selectors.length > 0);
    }

    return this.ss.groupedKeys;
  }
}
