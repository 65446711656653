<a [href]="router.url.includes('#content') ? router.url : router.url + '#content'" tabindex="0" accesskey="s"
  class="visually-hidden skip-link">Skip to Main Content</a>

<div *ngIf="ups.draftPrefs | async as prefs; else noSchool">
  <div class="header" role="banner" [ngStyle]="{ 
    'height': headerHeight + 'px',
    'background-color': prefs?.headerColor,
    'background-image': (ups.headerBgImageCss | async),
    'border-bottom': prefs?.headerBottomBorderStyle}">

    <img [ngStyle]="{ 
            'padding-top': (headerHeight / 10) + 'px', 
            'padding-bottom': (headerHeight / 10) + 'px'}"
      [src]="(ups.logoURL | async) || 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='"
      alt="School Logo">

    <div style="float:right; padding-right:1%; padding-top:1%">
      <span class="text-light user" *ngIf="authService.user | async">Welcome
        {{ (authService.user | async)?.email }}</span>
      <button mat-flat-button (click)="logout()" *ngIf="authService.user | async">
        Logout
      </button>
      <span class="text-light dev-label" *ngIf="devServer">DEV-SERVER</span>
    </div>
  </div>
</div>
<ng-template #noSchool>
  <div class="header" role="banner" [ngStyle]="{ 
        'height': headerHeight + 'px',
        'background': 'linear-gradient(0deg, rgba(98,121,228,0.45) 0%, rgba(98,121,228,.8) 25%, rgba(98,121,228,1) 50%, rgba(98,121,228,1) 100%)'
        }">
    <div style="float:right; padding-right:1%; padding-top:1%">
      <span class="text-light user" *ngIf="authService.user | async">Welcome
        {{ (authService.user | async)?.email }}</span>
      <button mat-flat-button (click)="logout()" *ngIf="authService.user | async">
        Logout
      </button>
      <span class="text-light dev-label" *ngIf="devServer">DEV-SERVER</span>
    </div>
  </div>

</ng-template>
