import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UnivPrefsService } from './univ-prefs.service';
import { CalcConfigService } from './calc-config.service';
import { TrafficService } from './traffic.service';

@Injectable({
    providedIn: 'root'
})
export class LoadingGuard {
    constructor(
        private ups: UnivPrefsService,
        private cc: CalcConfigService,
        private ts: TrafficService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // Extract schoolId from the parent route
        const schoolId = next.parent?.paramMap.get('schoolId') ?? '';
        const nextPathName = next.routeConfig?.path || '';

        this.ups.loadPreferences(schoolId);

        switch (nextPathName) {
            case 'questions':
            case 'costs':
            case 'packaging':
            case 'populations':
            case 'tables':
            case 'formulas':
            case 'inspector':
            case 'sessions':
            case 'superadmin':
            case 'questions-admin':
                this.cc.loadSchool(schoolId);
                break;
            case 'branding':
            case 'content':
            case 'configuration':
            case 'schools':
            case 'api-errors':
                break;
            case 'dashboard':
                this.ts.loadTraffic(schoolId);
                break;
            default:
                console.log('path: ' + nextPathName + ' not found! Not loading anything');
        }

        return true;
    }
}
