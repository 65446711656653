import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';

import * as Sentry from "@sentry/angular";
import { DateAgoPipe } from './_pipes/date-ago.pipe';


// Modules
import { CoreModule } from './_services/core.module';
import { SharedModule } from './_shared/shared.module';
import { DashboardModule } from './_dashboard/dashboard.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SchoolsComponent } from './schools/schools.component';
import { EplLoginComponent } from './epl-login/epl-login.component';
import { AuthenticatedLayoutComponent } from './layouts/authenticated-layout.component';

@NgModule({
  declarations: [
    AppComponent,
    SchoolsComponent,
    DateAgoPipe,
    EplLoginComponent,
    AuthenticatedLayoutComponent,
  ],
  imports: [
    CoreModule,
    SharedModule,
    DashboardModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  providers: [
    // Sentry Error Handler
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
