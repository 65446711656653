<div style="padding: 2px">
  <div *ngIf="!editMode">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title><h2>{{formula.name}}</h2></mat-card-title>
        <mat-card-subtitle>{{formula.description}}</mat-card-subtitle>
      </mat-card-header>
      <hr>

      <span style="margin-left: 1rem;">
        <strong>Formula:&nbsp;</strong>
        <ng-container *ngFor="let part of formulaParts">
          {{part.math}} <span style="font-weight: 400">{{getName(part)}}</span>
        </ng-container>
      </span>
    </mat-card>
  </div>

  <div *ngIf="editMode">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title><h2>
          Edit Formula
        </h2></mat-card-title>
      </mat-card-header>
      <mat-form-field style="margin-left: 1rem;width: 30%;">
        <input matInput placeholder="Name" type="text" [value]="formula.name" [(ngModel)]="formula.name">
      </mat-form-field>
      <mat-form-field style="margin-left: 1rem;width: 70%">
        <input matInput placeholder="Description" type="text" [value]="formula.description" [(ngModel)]="formula.description">
      </mat-form-field>
      <hr>

      <table class="table table-sm table-borderless" style="width:auto;">
        <tr>
          <th scope="row">Formula:</th>
          <td style="margin-left: 1rem;font-weight: 400">

            <ng-container *ngFor="let part of formulaParts">
              {{part.math}}{{getName(part)}}
            </ng-container>

          </td>
        </tr>
        <tr>
          <th scope="row">Evaluation:</th>
          <td style="margin-left: 1rem;font-weight: 400">
            <span>{{formulaSolve()}}<strong>{{solution | async | number}}</strong></span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td style="margin-left: 1rem;">
            <em>Evaluation uses test values below to attempt a calculation</em>
            <br>
            <span><em>Parenthesis cannot be used to denote "multiply" you must use the "*" operator.</em></span>
          </td>
        </tr>
      </table>


      <table class="table" style="width:auto;">
        <thead>
          <th scope="col">Math<br><span class="description">Digits and operators only</span></th>
          <th scope="col">Input<br><span class="description">Incorporate results from question, tables, or calculations</span></th>
          <th scope="col">Test Value<br><span class="description">Sample to test function</span></th>
          <th scope="col">Action</th>
        </thead>
        <tr *ngFor="let part of formulaParts;let i = index">
          <td>
            <mat-form-field>
              <input matInput type="text" placeholder="Math Operator" [value]="part.math" [(ngModel)]="part.math" formulaOnly>
            </mat-form-field>
          </td>
          <td>
            <input-selector [key]="part" [caller]="'Formula'"></input-selector>
          </td>
          <td>
            <span *ngIf="part.type !== 'New'">
              <mat-form-field style="width: 8rem;">
                <input matInput type="number" placeholder="Test Value" [value]="part.value" [(ngModel)]="part.value">
              </mat-form-field>
            </span>
          </td>
          <td>
            <span *ngIf="i !== (formulaParts.length - 1) || part.type !== 'New'" style="line-height: 4rem;">
              <button mat-button color="warn" (click)="deletePart(i)">delete</button>
              <button mat-button color="secondary" (click)="formulaParts.splice(i + 1,0,{ math: '', type: 'New', value: 0 })">add
                input</button>
            </span>
          </td>
        </tr>

      </table>


    </mat-card>

  </div>
</div>
