<div class="title-bar">
  <h1>SUPER ADMIN</h1>
  <button mat-button color="accent" disabled>help</button>
  <button mat-raised-button color="primary" type="submit" [disabled]="!calcRulesForm.valid || state != 'modified'">
    {{state == 'modified' ? 'Save Changes' : 'No unsaved changes'}}
  </button>
  <app-draft-mode></app-draft-mode>
</div>
<span *ngIf="state=='error'" class="alert alert-warning" role="alert">
  An error has occurred attempting to save changes
</span>
<div class="flex">

  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>School Status</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form fireForm [path]="publishedPrefsPath" [formGroup]="publishedPrefsForm" novalidate (stateChange)="changeHandler($event)">
        <mat-form-field class="form-field">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status" (selectionChange)="setStatus()">
            <mat-option value="">LIVE</mat-option>
            <mat-option value="COMING">Coming Soon</mat-option>
            <mat-option value="HOLD">On Hold / Paused</mat-option>
            <mat-option value="DEACTIVATED">Deactivated</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-card-content>
  </mat-card>


  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>School Settings</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form fireForm [path]="calcRulesPath" [formGroup]="calcRulesForm" novalidate (stateChange)="changeHandler($event)" (savedChange)="processSavedChange($event)">
        <ng-container formGroupName="schoolProperties">
          <mat-form-field class="form-field">
            <mat-label>Organization ID</mat-label>
            <input matInput type="number" formControlName="orgId">
          </mat-form-field>
          <mat-form-field class="form-field">
            <mat-label>Institutional (CSS) ID</mat-label>
            <input matInput type="number" formControlName="cssId">
          </mat-form-field>
          <mat-form-field class="form-field">
            <mat-label>Methodology</mat-label>
            <mat-select formControlName="institutionalMethod">
              <mat-option [value]="true">Institutional Methodology</mat-option>
              <mat-option [value]="false">Federal Methodology</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </form>


    </mat-card-content>
  </mat-card>

  <div *ngIf="noSchoolUsers | async as noSchoolList">
    <div *ngIf="activeUsers | async as activeList">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>
            <h2>Permission Users</h2>
          </mat-card-title>
          <mat-card-subtitle>This list shows all users requesting access.</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

          <table class="table" style="width:auto">
            <tbody>
              <div class="h5" *ngIf="activeList.length > 0">Active For This School</div>
              <tr *ngFor="let activeUser of activeList">
                <td>
                  <button mat-raised-button color="warn" (click)="deactivate(activeUser)">Remove User</button>
                </td>
                <td>
                  {{activeUser.email}}
                </td>
              </tr>
              <br>
              <div class="h5" *ngIf="noSchoolList.length > 0">Users with no School Assignment</div>
              <tr *ngFor="let user of noSchoolList">
                <td>
                  <button mat-raised-button color="primary" (click)="activate(user)">Activate</button>
                  <button mat-button color="warn" (click)="deleteUser(user)" style="margin-left: 1rem;">Delete</button>
                </td>
                <td>
                  {{user.email}}
                </td>
              </tr>
            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
      <p>
      </p>
    </div>
  </div>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Create a new school</h2>
      </mat-card-title>
      <mat-card-subtitle>This will create a new school. Preferences of this school <strong>{{schoolId}}</strong> will be used
        along
        with expenses and the mandatory packages.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field style="margin-right: 10px;">
        <mat-label>School Code</mat-label>
        <input matInput type="text" #code>
      </mat-form-field>
      <mat-form-field style="margin-right: 10px;">
        <mat-label>School Name</mat-label>
        <input matInput type="text" #name>
      </mat-form-field>
      <mat-checkbox [(ngModel)]="demo" style="margin-right: 10px;">Demo School?</mat-checkbox>
      <div *ngIf="schoolId !== 'default'" style="color:red; font-size: 14px; font-weight: 700">
        New Schools are typically created from the default school.<br> You are not currently on the default school.
      </div>
      <br>
      <button mat-flat-button color="primary" (click)="newSchool(code.value, name.value,(demo || false))">
        {{schoolId === 'default' ? 'New School' : 'CAUTION!'}}</button>
    </mat-card-content>
  </mat-card>
  <p></p>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Data Privacy</h2>
      </mat-card-title>
      <mat-card-subtitle>Enter a College Board Username to search for any user data</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

      <mat-form-field class="form-field" style="width:14rem;">
        <mat-label>College Board User ID</mat-label>
        <input matInput #eclUserId>
      </mat-form-field>
      <button *ngIf="eclUserId.value" mat-button color="primary" (click)="getEclUserRecords(eclUserId.value)">Get
        Records</button>
      <p></p>
      <div *ngIf="eclUserSessions | async as userSessions">
        <p *ngIf="userSessions.length === 0">No Records</p>
        <div *ngIf="userSessions.length > 0">
          <textarea matInput rows="30">{{userSessions | json}}</textarea>
          <p></p>
          <button mat-raised-button color="warn" (click)="deleteUserSessions(userSessions)">Remove Records</button>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
  <p></p>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Utilities</h2>
      </mat-card-title>
      <mat-card-subtitle>General utilities only to be used by Rewire Tech Staff.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form fireForm [path]="schoolPath" [formGroup]="liveDatesForm" novalidate (stateChange)="changeHandler($event)">
        <mat-form-field class="form-field">
          <mat-label>Go Live Date</mat-label>
          <input matInput type="date" formControlName="live">
        </mat-form-field>
      </form>
      <button class="btn btn-danger" style="margin-left: 1rem" (click)="utility()">Temporary Utility</button>
      <button class="btn btn-danger" style="margin-left: 1rem" (click)="markQuestionsRequired()">Set Question
        Methodology</button>
    </mat-card-content>
  </mat-card>
</div>