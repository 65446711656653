<div class="title-bar">
  <h1>QUESTIONS</h1>
  <app-help screen="questions"></app-help>
  <ng-container *ngIf="!editMode">
    <button mat-flat-button color="primary" (click)="setMode('edit')" [disabled]="this.tabCode === 'ALL'">Edit Questions</button>
  </ng-container>
  <ng-container *ngIf="editMode">
    <button mat-flat-button color="accent" (click)="setMode('save')">Save Questions</button>
    <button mat-flat-button color="warn" (click)="setMode('cancel')">Cancel</button>
  </ng-container>
  <app-aid-year style="margin-left: auto" [disabled]="editMode"></app-aid-year>
</div>

<br />
<mat-tab-group mat-stretch-tabs="false" #tabgroup (selectedTabChange)="onTabChanged($event)">
  <ng-container *ngIf="qs.draftQuestions | async | filter : filterFunction as questions">
    <mat-tab *ngFor="let tab of tabs" [label]="tab.label" [disabled]="tab.code === 'ALL' && editMode">
      <mat-card appearance="outlined">
        <mat-card-content>
          <table class="table table-hover">
            <thead>
              <tr>
                <th *ngIf="editMode" scope="col" style="width: 10rem"></th>
                <th scope="col">Question</th>
                <th scope="col" style="padding-left: 24px; width: 10rem">Format</th>
                <th scope="col">
                  Custom Usage
                  <br />
                  <span class="legend-population">Population</span>
                  <span class="legend-table">Table</span>
                  <br />
                  <span class="legend-package">Package</span>
                  <span class="legend-formula">Formula</span>
                </th>
                <th *ngIf="tab.code === 'ALL'" scope="col" style="width: 10rem">Section</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let q of questions" (click)="clickedRow(q)">
                <td *ngIf="editMode">
                  <button [disabled]="q.methodRequired && !(user | async)?.dev" mat-button (click)="removeQuestion(q)">
                    {{ q.methodRequired ? "required" : "remove" }}
                  </button>
                  <span *ngIf="q.enhanceCalc"> <br /><small style="color: red; margin-left: 1rem">refine calc</small> </span>
                </td>

                <th
                  scope="row"
                  [ngClass]="{
                    'triggered-question': q.triggerQuestionId !== undefined,
                    'deleted-question': q.dateDeleted
                  }"
                >
                  {{ q.dateDeleted ? "Deleted: " : "" }}{{ q.label }}
                  <small *ngIf="editMode"><br />(ID: {{ q.id }})</small>
                  <div *ngIf="showHelp">
                    <span class="text-muted" style="font-size: 90%" [innerHTML]="q.help"></span>
                  </div>
                </th>

                <td>
                  <div *ngIf="q.format !== 'Code'" style="padding-left: 0.75rem">
                    {{ q.format === "Boolean" ? "Yes/No" : q.format }}
                  </div>
                  <div *ngIf="q.format === 'Code'">
                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                      <mat-label><span style="color: rgb(0, 0, 0)">Choices</span></mat-label>
                      <mat-select aria-readonly="true">
                        <mat-option *ngFor="let choices of q.choices">{{ choices.label }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </td>
                <td>
                  <app-usage entityType="Question" [entityId]="q.id"></app-usage>
                </td>
                <td *ngIf="tab.code === 'ALL'">{{ q.section_id }}</td>
              </tr>
            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
      <div *ngIf="editMode">
        <div *ngIf="qs.unusedQuestions | async | filter : unusedFilterFunction as unusedQuestions">
          <div *ngIf="unusedQuestions.length !== 0">
            <mat-card appearance="outlined">
              <mat-card-content>
                <mat-card-title>
                  <h2>Optional Questions</h2>
                </mat-card-title>
                <mat-card-subtitle>
                  Select which questions to add and then save your selections.<br />
                  Only add questions to be used in packages, populations, tables, formulas or are designated as questions that will
                  <span style="color: red">refine your calc</span>.<br />
                  <br />If you require a question that is not on our list for packaging or population definition, you can <a href="https://form.collegeboard.org/f/npc-supplemental-question-application" target="_blank" class="link">request a custom question</a>.
                </mat-card-subtitle>

                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 10rem"></th>
                      <th scope="col">Question</th>
                      <th scope="col" style="width: 10rem">Format</th>
                      <th *ngIf="tab.code === 'ALL'" scope="col" style="width: 10rem">Section</th>
                    </tr>
                  </thead>

                  <tbody>
                    <ng-container *ngFor="let q of unusedQuestions">
                      <tr>
                        <td>
                          <!-- TODO Allow for trigger questions to be added UNLESS trigger parent is in unused -->
                          <button mat-button [disabled]="q.triggerQuestionId" (click)="addQuestion(q)">
                            {{ q.triggerQuestionId ? "triggered" : "add" }}
                          </button>
                          <span *ngIf="q.enhanceCalc"> <br /><small style="color: red; margin-left: 0.2rem">refine calc</small> </span>
                        </td>
                        <th scope="row">
                          {{ q.label }}
                          <small *ngIf="editMode"><br />(ID: {{ q.id }})</small>
                          <div *ngIf="showHelp">
                            <span class="text-muted" style="font-size: 90%" [innerHTML]="q.help"></span>
                          </div>
                        </th>
                        <td>
                          <div *ngIf="q.format !== 'Code'" style="padding-left: 0.75rem">
                            {{ q.format === "Boolean" ? "Yes/No" : q.format }}
                          </div>
                          <div *ngIf="q.format === 'Code'">
                            <mat-form-field appearance="outline" class="dropdown" subscriptSizing="dynamic">
                              <mat-label><span style="color: rgb(0, 0, 0)">Choices</span></mat-label>
                              <mat-select aria-readonly="true">
                                <mat-option *ngFor="let choices of q.choices">{{ choices.label }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </td>
                        <td *ngIf="tab.code === 'ALL'">{{ q.section_id }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-tab>
  </ng-container>
</mat-tab-group>
<span style="float: right">
  <mat-checkbox [(ngModel)]="showHelp">show help</mat-checkbox>
</span>
