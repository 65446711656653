<div *ngIf="sessions | async; let sessions">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Select an NPC session to see the impact of any rule changes</h2>
      </mat-card-title>
      <mat-card-subtitle>When you are comfortable that the changes have the desired affect you can publish the changes
        to your live Net Price Calculator.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="form-field" style="margin-left: 1rem; width:20rem;">
        <mat-select placeholder="Session" [(value)]="selected">
          <mat-option *ngFor="let session of sessions" [value]=session>
            [{{session.savedAt.toDate() | date : 'MM/dd/yy HH:MM'}}] {{session.result ? session.result.netPrice : '*'}}
            {{session.answers[0].value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <a *ngIf="selected" href="{{'https://npc.rewiretech.com/app/' + schoolId + '?draft='+selected.userId+'&sessionId=' + selected.id}}"
        style="margin-left: 2em; font-size: 14px;" target="_blank">Go to session</a>
      <button *ngIf="selected" mat-button color="warn" (click)="deleteSession(selected.id)">Delete session</button> -->
      <div style="margin-top: 1rem;">
        <button mat-flat-button color="primary" style="margin-left: 1em" (click)="createAdminSession()">Create Admin
          Session</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>


<div *ngIf="selected">
  <p></p>
  <mat-tab-group mat-stretch-tabs="false" dynamicHeight>
    <ng-container *ngIf="selected.result">
      <mat-tab [label]=selected.answers[0].value.toString()>
        <div style="display:flex; flex-flow: row wrap;">
          <mat-card appearance="outlined">
            <mat-card-title>
              <h2>Cost of Attendance</h2>
            </mat-card-title>
            <table class="table" style="width: auto;">
              <thead>
                <th scope="col">Type</th>
                <th scope="col">Value</th>
              </thead>
              <tbody>
                <tr *ngFor="let row of selected.result.coa | keyvalue">
                  <td>{{row.key}}</td>
                  <td *ngIf="isNumber(row.value)">{{row.value | number}}</td>
                </tr>
                <tr *ngFor="let row of toPlainObject(selected.result) | keyvalue">
                  <ng-container *ngIf="!(['coa','createdAt','cloudInasReponse','cloudInasRequest'].includes(row.key.toString()))">
                    <td>{{row.key}}</td>
                    <td *ngIf="isNumber(row.value)">{{row.value.toString() | number}}</td>
                    <td *ngIf="!isNumber(row.value)">{{row.value.toString()}}</td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </mat-card>
        </div>
      </mat-tab>
    </ng-container>
    <mat-tab label="INAS Request">
      <mat-card-title>
        <h2>INAS Request</h2>
      </mat-card-title>
      <mat-card appearance="outlined">
        <pre>{{selected.result.cloudInasRequest | json}}</pre>
      </mat-card>
    </mat-tab>
    <mat-tab label="INAS Response">
      <mat-card-title>
        <h2>INAS Response</h2>
      </mat-card-title>
      <mat-card appearance="outlined">
        <pre>{{selected.result.cloudInasReponse | json}}</pre>
      </mat-card>
    </mat-tab>
    <mat-tab label="answers">
      <mat-card appearance="outlined">
        <mat-card-title>
          <h2>Answers</h2>
        </mat-card-title>
        <table class="table" style="width:auto;">
          <thead>
            <th scope="col">Question ID</th>
            <th scope="col">Value</th>
          </thead>
          <tbody>
            <tr *ngFor="let answer of selected.answers">
              <ng-container>
                <td>{{answer.questionId}}</td>
                <td>{{answer.questionId.toLowerCase().includes('date') ? (answer.value | date) : answer.value}}</td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</div>