import { Injectable } from '@angular/core';
import { QuestionsService } from '../_services/questions.service';
import { findIndex, filter } from 'lodash-es';

export interface Selector {
  id: string;
  label: string;
  group: string;
  isQuestion?: boolean;
}
export interface KeyGroup {
  section: string;
  selectors: Selector[];
}

export const SelectorGroups = ['Costs', 'Awards', 'Computed', 'IM Parent', 'FM Parent', 'IM Student', 'FM Student'];

const pre2425Selectors: Selector[] = [
  // StudentCalculatedAge is the label we use in NPC Admin - it is mapped from AwardYearCalculatedAge in the API
  { id: 'inarStudentData.StudentCalculatedAge', group: 'Computed', label: 'Student Age' },
  { id: 'inarStudentData.MembersInFamily', group: 'Computed', label: 'Number in Family' },
  { id: 'inarStudentData.NumberInCollege', group: 'Computed', label: 'Number in College' },
  { id: 'inarParentIMResults.ContributionForStudent', group: 'IM Parent', label: 'IM Parent Contribution for the student' },
  { id: 'inarParentIMResults.AdjustedGrossIncome', group: 'IM Parent', label: 'IM Parent AGI' },
  { id: 'inarParentIMResults.TotalIncome', group: 'IM Parent', label: 'IM Parent Total income' },
  { id: 'inarParentIMResults.AvailableIncome', group: 'IM Parent', label: 'IM Parent Available income' },
  { id: 'inarParentIMResults.NetWorth', group: 'IM Parent', label: 'IM Parent Net worth' },
  { id: 'inarParentIMResults.DiscretionaryNetWorth', group: 'IM Parent', label: 'IM Parent Discretionary net worth' },
  { id: 'inarParentIMResults.CashSavingsChecking', group: 'IM Parent', label: 'IM Parent Cash & bank accounts' },
  { id: 'inarParentIMResults.HomeEquity', group: 'IM Parent', label: 'IM Parent Home equity' },
  { id: 'inarParentIMResults.RealEstateInvestmentEquity', group: 'IM Parent', label: 'IM Parent Other real estate & investment equity' },
  { id: 'inarParentIMResults.BusinessFarmEquity', group: 'IM Parent', label: 'IM Parent Adjusted business & farm net worth' },
  { id: 'inarParentIMResults.TotalContribution', group: 'IM Parent', label: 'IM Parent Total contribution' },
  { id: 'inarParentIMResults.ContributionFromIncome', group: 'IM Parent', label: 'IM Parent Contribution from income' },
  { id: 'inarParentIMResults.ContributionFromAssets', group: 'IM Parent', label: 'IM Parent Contribution from assets' },
  { id: 'inarParentIMResults.LowIncomeAllowance', group: 'IM Parent', label: 'IM Parent Low income allowance' },
  { id: 'inarParentIMResults.MedicalDentalExpenses', group: 'IM Parent', label: 'IM Parent Medical and Dental Expenses' },
  { id: 'inarParentEfmResults.ContributionForStudent', group: 'FM Parent', label: 'FM Parent Contribution for the student' }, // Remove for 2425
  { id: 'inarParentEfmResults.AdjustedGrossIncome', group: 'FM Parent', label: 'FM Parent AGI' },  // Remove for 2425
  { id: 'inarParentEfmResults.TotalIncome', group: 'FM Parent', label: 'FM Parent Total income' },
  { id: 'inarParentEfmResults.AvailableIncome', group: 'FM Parent', label: 'FM Parent Available income' },
  { id: 'inarParentEfmResults.DiscretionaryNetWorth', group: 'FM Parent', label: 'FM Parent Discretionary net worth' },
  { id: 'inarParentEfmResults.ContributionFromIncome', group: 'FM Parent', label: 'FM Parent Contribution from income' },
  { id: 'inarParentEfmResults.ContributionFromAssets', group: 'FM Parent', label: 'FM Parent Contribution from assets' },
  { id: 'inarParentEfmResults.TotalContribution', group: 'FM Parent', label: 'FM Parent Total contribution ' }, // Remove for 2425
  { id: 'inarStudentIMResults.ContributionForStudent', group: 'IM Student', label: 'IM Student Contribution' },
  { id: 'inarStudentIMResults.AdjustedGrossIncome', group: 'IM Student', label: 'IM Student AGI' },
  { id: 'inarStudentIMResults.TotalIncome', group: 'IM Student', label: 'IM Student Total income' },
  { id: 'inarStudentIMResults.AvailableIncome', group: 'IM Student', label: 'IM Student Available income' },
  { id: 'inarStudentIMResults.NetWorth', group: 'IM Student', label: 'IM Student Net worth' },
  { id: 'inarStudentIMResults.CashSavingsChecking', group: 'IM Student', label: 'IM Student Cash & bank accounts' },
  { id: 'inarStudentIMResults.ContributionFromIncome', group: 'IM Student', label: 'IM Student Contribution from income' },
  { id: 'inarStudentIMResults.ContributionFromAssets', group: 'IM Student', label: 'IM Student Contribution from assets' },
  { id: 'inarStudentEfmResults.ContributionForStudent', group: 'FM Student', label: 'FM Student Contribution ' }, // Remove for 2425
  { id: 'inarStudentEfmResults.AdjustedGrossIncome', group: 'FM Student', label: 'FM Student AGI' }, // Remove for 2425
  { id: 'inarStudentEfmResults.TotalIncome', group: 'FM Student', label: 'FM Student Total income' },
  { id: 'inarStudentEfmResults.AvailableIncome', group: 'FM Student', label: 'FM Student Available income' },
  { id: 'inarStudentEfmResults.DiscretionaryNetWorth', group: 'FM Student', label: 'FM Student Discretionary net worth' },
  { id: 'inarStudentEfmResults.ContributionFromIncome', group: 'FM Student', label: 'FM-Student Contribution from income' },
  { id: 'inarStudentEfmResults.ContributionFromAssets', group: 'FM Student', label: 'FM Student Contribution from assets' },
  { id: 'inarStudentEfmResults.TotalContribution', group: 'FM Student', label: 'FM Student Total contribution' }, // Remove for 2425
  { id: 'inarStudentEfmResults.UsIncomeTax', group: 'FM Student', label: 'FM Student U.S. income tax' },
  { id: 'rt.FmEffectiveFamilyContribution', group: 'Computed', label: 'FM EFC' },
  { id: 'rt.ImEffectiveFamilyContribution', group: 'Computed', label: 'IM EFC' },
  { id: 'rt.FmNeed', group: 'Computed', label: 'FM Original Need' },
  { id: 'rt.AdjustedEffectiveFamilyContribution', group: 'Computed', label: 'Adjusted EFC ' },
  { id: 'rt.AdjustedNeed', group: 'Computed', label: 'Adjusted Original Need' },
  { id: 'rt.AdjustedParentContribution', group: 'Computed', label: 'Adjusted Parent Contribution' },
  { id: 'rt.AdjustedStudentContribution', group: 'Computed', label: 'Adjusted Student Contribution' },
  { id: 'rt.NetPrice', group: 'Computed', label: 'Computed Net Price' },
  { id: 'rt.ImNeed', group: 'Computed', label: 'IM Original Need' },
  { id: 'rt.ImFmNeedDelta', group: 'Computed', label: 'IM to FM Need Difference' },
  { id: 'rt.PellGrant', group: 'Awards', label: 'Pell Grant Award' },
  { id: 'rt.TotalAid', group: 'Awards', label: 'Total Aid' },
  { id: 'rt.FmRemainingNeed', group: 'Computed', label: 'FM Remaining Need' },
  { id: 'rt.ImRemainingNeed', group: 'Computed', label: 'IM Remaining Need' },
  { id: 'rt.AdjustedRemainingNeed', group: 'Computed', label: 'Adjusted Remaining Need' },
  { id: 'rt.TotalCoa', group: 'Costs', label: 'Total Cost of Attendance' },
  { id: 'inarParentEfmResults.NetWorth', group: 'Computed', label: 'FM Parent Net Worth' },
  { id: 'rt.BooksAndSupplies', group: 'Costs', label: 'Books and Supplies' },
  { id: 'rt.ConsolidatedSelfHelp', group: 'Awards', label: 'Consolidated Self Help' },
  { id: 'rt.InstitutionalGrant', group: 'Awards', label: 'Institutional Grant' },
  { id: 'rt.InstitutionalGrantA', group: 'Awards', label: 'Institutional Grant A' },
  { id: 'rt.InstitutionalGrantB', group: 'Awards', label: 'Institutional Grant B' },
  { id: 'rt.InstitutionalGrantC', group: 'Awards', label: 'Institutional Grant C' },
  { id: 'rt.InstitutionalScholarship', group: 'Awards', label: 'Institutional Scholarship' },
  { id: 'rt.InstitutionalScholarshipA', group: 'Awards', label: 'Institutional Scholarship A' },
  { id: 'rt.InstitutionalScholarshipB', group: 'Awards', label: 'Institutional Scholarship B' },
  { id: 'rt.InstitutionalScholarshipC', group: 'Awards', label: 'Institutional Scholarship C' },
  { id: 'rt.ParentLoan', group: 'Awards', label: 'Parent Loan' },
  { id: 'rt.PersonalExpenses', group: 'Costs', label: 'Personal Expenses' },
  { id: 'rt.RoomAndBoard', group: 'Costs', label: 'Room and Board' },
  { id: 'rt.StateGrant', group: 'Awards', label: 'State Grant' },
  { id: 'rt.StateGrantA', group: 'Awards', label: 'State Grant A' },
  { id: 'rt.StateGrantB', group: 'Awards', label: 'State Grant B' },
  { id: 'rt.StateGrantC', group: 'Awards', label: 'State Grant C' },
  { id: 'rt.StudentLoan', group: 'Awards', label: 'Student Loan' },
  { id: 'rt.StudentLoanA', group: 'Awards', label: 'Student Loan A' },
  { id: 'rt.StudentLoanB', group: 'Awards', label: 'Student Loan B' },
  { id: 'rt.StudentLoanC', group: 'Awards', label: 'Student Loan C' },
  { id: 'rt.StudentWork', group: 'Awards', label: 'Student Work' },
  { id: 'rt.TotalGrantAid', group: 'Awards', label: 'Total Grant Aid' },
  { id: 'rt.TotalSelfHelp', group: 'Awards', label: 'Total Self Help' },
  { id: 'rt.Transportation', group: 'Costs', label: 'Transportation' },
  { id: 'rt.TuitionAndFees', group: 'Costs', label: 'Tuition and Expenses' },
];

@Injectable({
  providedIn: 'root'
})
export class SelectorsService {
  groupedKeys: KeyGroup[] = [];
  activeSelectors: Selector[] = pre2425Selectors;

  constructor(private qs: QuestionsService) {
    this.createGroupedKeys(pre2425Selectors);
  }

  private createGroupedKeys(selectors: Selector[]) {
    this.groupedKeys = [];
    for (const group of SelectorGroups) {
      this.groupedKeys.push({ section: group, selectors: filter(selectors, ['group', group]) });
    }
  }

  // This gets called by the Calc Config Service when we load draft rules
  public changeGroupKeysByAidYear(aidYear: number): void {
    if (aidYear >= 2425) {
      // Remove obsolete keys
      const keysToRemove2425 = ['inarParentEfmResults.ContributionForStudent', 'inarParentEfmResults.AdjustedGrossIncome', 'inarParentEfmResults.TotalContribution', 'inarStudentEfmResults.ContributionForStudent', 'inarStudentEfmResults.AdjustedGrossIncome', 'inarStudentEfmResults.TotalContribution'];
      const newSelectors = filter(pre2425Selectors, (selector) => !keysToRemove2425.includes(selector.id));
      // Add new keys
      const selectors2425: Selector[] = [
        { id: 'inarMiscCalculations.efmSAI', group: 'Computed', label: 'SAI' }, // New for 2425
        { id: 'inarMiscCalculations.efmSAINoNegative', group: 'Computed', label: 'SAI (no negative values)' }, // New for 2425
        { id: 'inarStudentEfmResults.IncomeAdditions', group: 'FM Student', label: 'FM Student Income additions' },  // New for 2425
        { id: 'inarParentEfmResults.IncomeAdditions', group: 'FM Parent', label: 'FM Parent Income additions' }, // New for 2425
      ]
      this.activeSelectors = [...newSelectors, ...selectors2425];
    } else {
      this.activeSelectors = pre2425Selectors;
    }
    this.createGroupedKeys(this.activeSelectors);
  }


  public getSelector(keyId: string): Selector {
    if (keyId) {
      const setName = keyId.split('.', 2)[0];
      switch (setName.toLowerCase()) {
        case 'student': case 'parent':
          const question = this.qs.getQuestion(keyId);
          if (question !== undefined) {
            const qSelector: Selector = {
              id: question.id,
              group: question.section_id,
              label: question.label,
              isQuestion: true
            };
            return qSelector;
          } else {
            console.log('Question ', keyId, ' not found');
          }
          break;
        default:
          // Keys are not sent in camel case - object is in camel case
          // keyName = keyName[0].toLowerCase() + keyName.slice(1);
          const index = findIndex(this.activeSelectors, ['id', keyId]);
          if (index >= 0) {
            return this.activeSelectors[index];
          }
          break;
      }
      console.log('ID not found ', keyId);
    }
    return { id: '', group: '', label: '' };
  }
}
