import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";

import { AppModule } from "./app/app.module";

import { environment } from './environments/environment';
import packageInfo from '../package.json';

if (environment.production) {
  enableProdMode();
}



Sentry.init({
  environment: environment.production ? 'production' : 'development',
  release: "npc-admin@" + packageInfo.version,
  dsn: "https://9ed36c977749f865fb59a69d6458827a@o114829.ingest.sentry.io/4505874267963392",
  integrations: [Sentry.browserTracingIntegration, Sentry.replayIntegration()],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Performance Monitoring
  tracesSampleRate: environment.production ? 0 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => console.error(err));


