<h1 mat-dialog-title id="helpTitle">{{data.title}}</h1>
<mat-dialog-content>
  <video aria-labelledby="helpTitle" width="100%" src="{{helpVideoUrl | async}}" controls autoplay preload="metadata">
    <track label="English" kind="subtitles" srclang="en" [src]="'../../../assets/helpCaptions/' + this.data.helpVideoName + '.vtt'">
    Your browser does not support the video tag.
  </video>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial>close</button>
</mat-dialog-actions>
