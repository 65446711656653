import { Injectable } from '@angular/core';
import { Expense, ExpenseNameMap } from '../_models/expense';
import { Table } from '../_models/table';
import { Formula } from '../_models/formula';
import { Package } from '../_models/package';
import { Population } from '../_models/population';
import { AidYearChange, CalcRules } from '../_models/calcPackage';

import { convertTimestampsPipe } from '../_pipes/convert-firestore-timestamp.pipe';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subscription, firstValueFrom } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

import { uniqWith, findIndex, isEqual, find } from 'lodash-es';
import { AuthService } from './auth.service';
import { QuestionsService } from './questions.service';
import { SelectorsService } from './selectors.service';
import { UnivPrefsService } from './univ-prefs.service';




export interface UsedBy {
  entityType: 'Population' | 'Table' | 'Formula' | 'Question';
  entityId: string;
  name: string;
  useType: 'Package' | 'Cost' | 'Formula' | 'Table' | 'Population' | 'Next Step';
  disabled?: boolean;
}

export interface RuleInfo {
  lastUpdated: Date;
  lastUpdatedBy: string;
  publishedDate: Date;
  aidYear: number;
}

@Injectable({
  providedIn: 'root'
})
export class CalcConfigService {
  expenses = new BehaviorSubject<Expense[]>([]);
  packages = new BehaviorSubject<Package[]>([]);
  populations = new BehaviorSubject<Population[]>([]);
  tables = new BehaviorSubject<Table[]>([]);
  formulas = new BehaviorSubject<Formula[]>([]);
  aidYearChanges = new BehaviorSubject<AidYearChange[] | null>(null);
  loadedSchoolId = '';
  loadedDraftSchoolId = '';
  loadedPublishedSchoolId = '';

  draftRules = new BehaviorSubject<CalcRules>(null);
  publishedRules = new BehaviorSubject<CalcRules>(null);
  draftSubscription: Subscription;
  publishSubscription: Subscription;
  draftRuleInfo: Observable<RuleInfo>;
  publishedRuleInfo: Observable<RuleInfo>;

  usedBy = new BehaviorSubject<UsedBy[]>([]);

  constructor(
    private ups: UnivPrefsService,
    private afs: AngularFirestore,
    private auth: AuthService,
    private qs: QuestionsService,
    private ss: SelectorsService,
  ) {
    this.draftRuleInfo = this.draftRules.pipe(
      filter(rules => !!rules),
      convertTimestampsPipe(),
      map(rules => ({
        publishedDate: null,
        lastUpdated: rules?.lastUpdated,
        lastUpdatedBy: rules?.lastUpdatedBy,
        aidYear: rules?.schoolProperties?.aidYear
      })));

    this.publishedRuleInfo = this.publishedRules.pipe(
      filter(rules => !!rules),
      convertTimestampsPipe(),
      map(rules => ({
        publishedDate: rules?.publishedDate,
        lastUpdated: rules?.lastUpdated,
        lastUpdatedBy: rules?.lastUpdatedBy,
        aidYear: rules?.schoolProperties?.aidYear
      })));
  }


  //#region Load collections
  loadSchool(schoolId: string) {
    if (schoolId !== this.loadedSchoolId) {
      this.populations.next([]);
      this.expenses.next([]);
      this.packages.next([]);
      this.tables.next([]);
      this.formulas.next([]);
      this.usedBy.next([]);
      this.loadDraftCalcRules(schoolId);
    }
  }

  loadPublishedCalcRules(schoolId: string) {
    if (schoolId === this.loadedPublishedSchoolId) { return; }

    if (this.publishSubscription) { this.publishSubscription.unsubscribe(); }

    this.publishSubscription = this.afs.doc<CalcRules>('calcConfig/' + schoolId + '/calcRules/published').valueChanges().pipe(
      tap(res => {
        if (res) {
          console.log('Published Rule Set loaded (or-reloaded)', schoolId);
          this.publishedRules.next(res);
          this.loadedPublishedSchoolId = schoolId;
        } else {
          console.log('No Published Rule Set exists for', schoolId);
        }
      })
    ).subscribe();

  }

  loadDraftCalcRules(schoolId: string, forceReload = false) {
    if (schoolId === this.loadedDraftSchoolId && !forceReload) { return; }

    if (this.draftSubscription) { this.draftSubscription.unsubscribe(); }

    this.draftSubscription = this.afs.doc<CalcRules>('calcConfig/' + schoolId + '/calcRules/draft').valueChanges().pipe(
      tap(res => {
        if (res) {
          const aidYear = res.schoolProperties?.aidYear || 2324;
          console.log('Draft Rule Set for aid year', aidYear, 'loaded (or-reloaded)', schoolId, JSON.stringify(res).length.toLocaleString('en'));
          this.draftRules.next(res);

          this.tables.next(res.tables || []);
          this.expenses.next(res.expenses || []);
          this.packages.next(res.packages || []);
          this.populations.next(res.populations || []);
          this.formulas.next(res.formulas || []);
          this.mapFormulaObject();
          this.mapTableObject();
          this.mapPopulations();
          this.createTags();

          this.loadedDraftSchoolId = schoolId;
          this.loadedSchoolId = schoolId;

          this.qs.loadQuestions(schoolId, aidYear);
          this.ss.changeGroupKeysByAidYear(aidYear);
          this.loadAidYearChanges(schoolId, aidYear);
        } else {
          console.log('No Draft Rule Set exists for', schoolId);
        }
      })
    ).subscribe();


  }

  private async loadAidYearChanges(schoolId: string, aidYear: number) {
    this.aidYearChanges.next(null);
    // Aid year changes started after 2324
    if (aidYear < 2425) { return; }

    const changes = await firstValueFrom(this.afs.doc<{ aidYearChanges: AidYearChange[], lastUpdated: Date }>('calcConfig/' + schoolId + '/aidYearChanges/' + aidYear).valueChanges().pipe(convertTimestampsPipe()));
    if (changes) {
      this.aidYearChanges.next(changes.aidYearChanges);
    }
  }

  // Attach populations to expenses and packages
  private mapPopulations() {
    const pops = this.populations.value;
    if (pops && pops.length > 0) {

      if (this.expenses.value.length > 0) {
        // Tie populations to expense rules
        for (const cost of this.expenses.value) {
          for (const rule of cost.rules) {
            if (rule.populationId !== '1') {
              const index = findIndex(pops, ['id', rule.populationId]);
              if (index !== -1) {
                // Attach population to expense rule
                rule.population = pops[index];
              } else {
                console.log('Population ID: ', rule.populationId, ' for expense ', cost.name, ' not found');
              }
            }
          }
        }
      }

      if (this.packages.value.length > 0) {
        // Tie populations to package rules
        for (const pack of this.packages.value) {
          for (const rule of pack.rules) {
            if (rule.populationId !== '1') {
              const index = findIndex(pops, ['id', rule.populationId]);
              if (index !== -1) {
                // Attach population to package rule
                rule.population = pops[index];
              } else {
                console.log('Population ID: ', rule.populationId, ' for package ', pack.id, ' not found');
              }
            }
          }
        }
      }
      // this.tagPopulations();
    }
  }

  private mapTableObject() {
    // Check if datasets have been loaded and have items
    if (this.tables.value.length > 0) {
      if (this.expenses.value.length > 0) {
        for (const e of this.expenses.value) {
          for (const r of e.rules) {
            if (r.tableId) {
              r.table = this.getTable(r.tableId);
              if (!r.table) { console.log('Table ID:', r.tableId, 'not found for expense:', e.name); }
            }
          }
        }
      }
      if (this.packages.value.length > 0) {
        for (const p of this.packages.value) {
          for (const r of p.rules) {
            if (r.tableId) {
              r.table = this.getTable(r.tableId);
              if (!r.table) { console.log('Table ID:', r.tableId, 'not found for package:', p.id); }
            }
          }
        }
      }
    }
  }

  private mapFormulaObject() {
    // Check if datasets have been loaded and have items
    if (this.formulas.value.length > 0) {
      if (this.packages.value.length > 0) {
        for (const p of this.packages.value) {
          for (const r of p.rules) {
            if (r.formulaId) {
              r.formula = this.getFormula(r.formulaId);
              if (!r.formula) { console.log('Formula ID:', r.formulaId, 'not found for package:', p.id); }
            }
          }
        }
      }
      if (this.populations.value.length > 0) {
        for (const pop of this.populations.value) {
          for (const block of pop.blocks) {
            for (const cond of block.conditions) {
              if (cond.formulaId) {
                cond.formula = this.getFormula(cond.formulaId);
                if (!cond.formula) { console.log('Formula ID:', cond.formulaId, 'not found for population:', pop.name); }
              }
            }
          }
        }
      }
      if (this.tables.value.length > 0) {
        for (const t of this.tables.value) {
          if (t.formulaId) {
            t.formula = this.getFormula(t.formulaId);
            if (!t.formula) { console.log('Formula ID:', t.formulaId, 'not found for table:', t.name); }
          }
        }
      }
    }
  }
  //#endregion

  //#region SAVING
  // Null out any attached objects prior to saving
  saveTable(schoolId: string, table: Table) {
    // table.formula = null // Formulas are still attached
    const tables = this.tables.value;
    const tableIndex = findIndex(tables, { 'tableId': table.tableId });
    if (tableIndex === -1) {
      // New Table
      tables.push(table);
    } else {
      tables[tableIndex] = table;
    }
    this.saveDraftRules(schoolId, { 'tables': tables, 'tablesLastModified': new Date() });
    this.afs.doc<Table>('calcConfig/' + schoolId + '/tables/' + table.tableId).set(table);

  }

  saveFormula(schoolId: string, formula: Formula) {
    const formulas = this.formulas.value;
    const formulaIndex = findIndex(formulas, { 'formulaId': formula.formulaId });
    if (formulaIndex === -1) {
      // New Formula
      formulas.push(formula);
    } else {
      formulas[formulaIndex] = formula;
    }
    this.saveDraftRules(schoolId, { 'formulas': formulas, 'formulasLastModified': new Date() });
    this.afs.doc<Formula>('calcConfig/' + schoolId + '/formulas/' + formula.formulaId).set(formula);

  }

  savePopulation(schoolId: string, population: Population) {
    population.blocks.forEach(block => {
      block.conditions.forEach(cond => {
        cond.question = null;
        // cond.formula = null; // Formulas are still attached
      });
    });
    const populations = this.populations.value;
    const popIndex = findIndex(populations, { 'id': population.id });
    if (popIndex === -1) {
      // New Table
      populations.push(population);
    } else {
      populations[popIndex] = population;
    }
    this.saveDraftRules(schoolId, { 'populations': populations, 'populationsLastModified': new Date() });
    this.afs.doc<Population>('calcConfig/' + schoolId + '/populations/' + population.id).set(population);
  }

  saveExpenses(schoolId: string, expenses: Expense[]) {
    expenses.forEach(expense => {
      expense.rules.forEach(rule => {
        rule.population = null;
        rule.table = null;
      });
    });
    this.saveDraftRules(schoolId, { 'expenses': expenses, 'expensesLastModified': new Date() });
    expenses.forEach(expense => {
      this.afs.doc<Expense>('calcConfig/' + schoolId + '/expenses/' + expense.type).set(expense);
    });
  }

  async savePackages(schoolId: string, packages: Package[], deleteCollection: boolean) {
    const path = 'calcConfig/' + schoolId + '/packages';
    if (deleteCollection) {
      await this.deleteCollection(path);
    }
    packages.forEach(pack => {
      pack.rules.forEach(rule => {
        rule.population = null;
        rule.table = null;
        // rule.formula = null;  // Formulas are still attached
      });
    });
    packages = packages.sort((n1, n2) => n1.order - n2.order);
    this.saveDraftRules(schoolId, { 'packages': packages, 'packagesLastModified': new Date() });
    packages.forEach(pack => {
      this.afs.doc<Package>(path + '/' + pack.id).set(pack);
    });
  }

  async saveDraftRules(schoolId: string, updateObject: Partial<CalcRules>) {
    const user = await this.auth.currentUser();

    updateObject.lastUpdated = new Date();
    updateObject.lastUpdatedBy = user.uid;
    this.afs.doc<CalcRules>('calcConfig/' + schoolId + '/calcRules/draft').set(updateObject as CalcRules, { merge: true });

  }

  deleteTable(schoolId: string, table: Table) {
    const tables = this.tables.value;
    const tableIndex = findIndex(tables, { 'tableId': table.tableId });
    if (tableIndex !== -1) {
      tables.splice(tableIndex, 1);
      this.saveDraftRules(schoolId, { 'tables': tables });
    }
    this.afs.doc<Table>('calcConfig/' + schoolId + '/tables/' + table.tableId).delete();
  }

  deleteFormula(schoolId: string, formula: Formula) {
    const formulas = this.formulas.value;
    const formulaIndex = findIndex(formulas, { 'formulaId': formula.formulaId });
    if (formulaIndex !== -1) {
      formulas.splice(formulaIndex, 1);
      this.saveDraftRules(schoolId, { 'formulas': formulas });
    }
    this.afs.doc<Formula>('calcConfig/' + schoolId + '/formulas/' + formula.formulaId).delete();
  }

  deletePopulation(schoolId: string, pop: Population) {
    const pops = this.populations.value;
    const popIndex = findIndex(pops, { 'id': pop.id });
    if (popIndex !== -1) {
      pops.splice(popIndex, 1);
      this.saveDraftRules(schoolId, { 'populations': pops });
    }
    this.afs.doc<Population>('calcConfig/' + schoolId + '/populations/' + pop.id).delete();
  }

  async deleteCollection(path: string): Promise<any> {
    // Firestore has a max batch size of around 500
    // Should not be an issue for our usage
    const qry = await this.afs.collection(path).ref.get();
    const batch = this.afs.firestore.batch();

    qry.forEach(doc => {
      batch.delete(doc.ref);
    });

    return batch.commit();
  }

  async publishRules(schoolId: string): Promise<string> {
    // Store a backup copy of the rules
    const pubRules = this.publishedRules.value;
    if (pubRules) {
      pubRules.publishedDate = pubRules.publishedDate || new Date();
      // TODO: Wrap try catches around each database write and return error message to publishRules caller.
      try {
        await this.afs.collection<CalcRules>('calcConfig_backup/' + schoolId + '/calcRules/').add(pubRules);
      } catch (err) {
        return 'Contact Support - Rules not published: could not backup Calculation Rules ' + err;
      }
    }

    // Copy draft rules to published rules - get a fresj copy of the draft rules
    const draftDoc = await this.afs.doc<CalcRules>('calcConfig/' + schoolId + '/calcRules/draft').ref.get();
    const rules = draftDoc.data();
    const size = JSON.stringify(rules).length.toLocaleString('en');
    console.log('pub size', size);
    rules.publishedDate = new Date();

    try {
      await this.afs.doc('calcConfig/' + schoolId + '/calcRules/published').set(rules);
      // Log last published date and set the published aid year
      const aidYear = rules.schoolProperties.aidYear;
      await this.afs.doc('schools/' + schoolId).set({ lastPublished: new Date(Date.now()), schoolProperties: { aidYear: aidYear } }, { merge: true });
      // Update the school preferences with the aid year
      const stringAidYear = `20${aidYear.toString().slice(0, 2)}-20${aidYear.toString().slice(2)}`;
      this.ups.savePreferences({ aidYear: stringAidYear }, false);
    } catch (err) {
      return 'Contact Support - Rules not published: could not publish rules (size ' + size + ') ' + err;
    }

    return 'Rules succesfully published';
  }

  async resetRules(schoolId: string) {
    const pubRules = this.publishedRules.value;
    if (pubRules) {
      // Make a backup copy of the draft rules (should have the lastest date updated in hte backup folder )
      const draftRules = this.draftRules.value;
      await this.afs.collection<CalcRules>('calcConfig_backup').doc(schoolId).collection('calcRules').add(draftRules);

      await this.afs.doc<CalcRules>('calcConfig/' + schoolId + '/calcRules/draft').set(pubRules);
    }
  }




  //#endregion


  private createTags() {
    const usedByList: UsedBy[] = [];
    for (const cost of this.expenses.value) {
      for (const rule of cost.rules) {
        if (rule.populationId !== '1') {
          usedByList.push({ useType: 'Cost', name: ExpenseNameMap[cost.type], entityType: 'Population', entityId: rule.populationId });
        }
        if (rule.tableId) {
          usedByList.push({ useType: 'Cost', name: ExpenseNameMap[cost.type], entityType: 'Table', entityId: rule.tableId });
        }
      }
    }

    for (const pack of this.packages.value) {
      for (const rule of pack.rules) {
        if (rule.populationId !== '1') {
          // TODO: Use package "name map"
          usedByList.push({
            useType: 'Package', name: pack.id, entityType: 'Population',
            entityId: rule.populationId, disabled: !pack.enabled
          });
        }
        if (rule.tableId) {
          usedByList.push({ useType: 'Package', name: pack.id, entityType: 'Table', entityId: rule.tableId, disabled: !pack.enabled });
        }
        if (rule.type === 'Formula' && rule.formulaId) {
          usedByList.push({ useType: 'Package', name: pack.id, entityType: 'Formula', entityId: rule.formulaId, disabled: !pack.enabled });
        }
        if (rule.type === 'Question') {
          usedByList.push({ useType: 'Package', name: pack.id, entityType: 'Question', entityId: rule.keyId, disabled: !pack.enabled });
        }
      }
    }

    for (const pop of this.populations.value) {
      if (pop.nextStep) {
        usedByList.push({ useType: 'Next Step', name: 'Next Step', entityType: 'Population', entityId: pop.id });
      }
      for (const block of pop.blocks) {
        for (const cond of block.conditions) {
          if (cond.type === 'Formula') {
            usedByList.push({ useType: 'Population', name: pop.name, entityType: 'Formula', entityId: cond.formulaId });
          }
          if (cond.type === 'Question') {
            usedByList.push({ useType: 'Population', name: pop.name, entityType: 'Question', entityId: cond.keyId });
          }
        }
      }
    }


    for (const formula of this.formulas.value) {
      const formulaSplit = formula.formula.split('|');
      for (const sub of formulaSplit) {
        const prefix = sub.split('.')[0];
        if (prefix === 'tab') {
          const tableId = sub.split('.')[1];
          usedByList.push({ useType: 'Formula', name: formula.name, entityType: 'Table', entityId: tableId });
        }
        if (prefix === 'student' || prefix === 'parent') {
          usedByList.push({ useType: 'Formula', name: formula.name, entityType: 'Question', entityId: sub });
        }
      }
    }


    for (const table of this.tables.value) {
      if (table.type === 'Formula' && table.formulaId) {
        usedByList.push({ useType: 'Table', name: table.name, entityType: 'Formula', entityId: table.formulaId });
      }
      if (table.type === 'Question') {
        usedByList.push({ useType: 'Table', name: table.name, entityType: 'Question', entityId: table.keyId });
      }
    }

    this.usedBy.next(uniqWith(usedByList, isEqual));
  }

  getFormula(formulaId: string): Formula {
    const formula = find(this.formulas.value, { 'formulaId': formulaId });
    return formula || null;
  }

  getTable(tableId: string): Table {
    const table = find(this.tables.value, { 'tableId': tableId });
    return table || null;
  }

  getUniqueId(): string {
    return this.afs.createId();
  }





}
