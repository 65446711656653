<form>
  <mat-form-field style="width: 20rem;">
    <input type="text" matInput placeholder="Calculated Input" [formControl]="keySelect" [matAutocomplete]="autoGroup" (blur)="checkField(autoGroup.isOpen)">
    <mat-autocomplete #autoGroup="matAutocomplete"  [displayWith]="displayFn" (optionSelected)="emitChange($event)">
      <mat-optgroup *ngFor="let group of filteredKeys | async" [label]="group.section">
        <mat-option *ngFor="let key of group.selectors" [value]="key">
          {{key.label}}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</form>