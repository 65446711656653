<div class="title-bar">
  <h1>DASHBOARD</h1>
  <app-help screen="dashboard"></app-help>
</div>

<ng-container *ngIf="auth.user | async as user">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Welcome to the Net Price Calculator customization application.</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <!-- <div *ngIf="user.admin"> -->
      <ng-container *ngIf="cc.draftRuleInfo | async as draft">
        <ng-container *ngIf="cc.publishedRuleInfo | async as published">
          <ng-container *ngIf="draft.lastUpdated > published.publishedDate || draft.aidYear > published.aidYear; else norulechanges">
            <div style="flex-direction: column">
              <div *ngIf="draft.lastUpdated">Your rules were last modified {{ draft.lastUpdated | date : "short" }}</div>
              <div>The last time your rules were published was {{ published.publishedDate | date : "short" }} and they represent aid year {{ published.aidYear }}</div>
              <p>Please review your rules in the <a routerLink="../inspector" routerLinkActive="active">INSPECTOR</a> and publish when ready.</p>
            </div>
          </ng-container>
          <ng-template #norulechanges>
            <div>All changes have been published for aid year {{ published.aidYear }} on {{ published.publishedDate | date : "short" }}</div>
          </ng-template>
        </ng-container>
      </ng-container>
      <!-- </div> -->
      <p class="have-questions">Have questions? Please contact us at <a href="mailto:npc_support@rewiretech.com">npc_support&#64;rewiretech.com</a>.</p>
    </mat-card-content>
  </mat-card>
  <p></p>
  <!-- <div *ngIf="user.admin"> -->
  <mat-card appearance="outlined" *ngIf="ts.isLoaded | async">
    <mat-card-header>
      <mat-card-title>
        <h2>Net Price Calculator Usage</h2>
      </mat-card-title>
      <mat-card-subtitle>Completion percent is the ratio of results served over total number of visitors</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <app-traffic-chart></app-traffic-chart>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button (click)="ts.populateChartData()">All periods</button>
      <button mat-button (click)="ts.populateChartData(12)">Latest 12 months</button>
      <button mat-button (click)="ts.downloadButtonPush()">Download Data</button>
    </mat-card-actions>
  </mat-card>
  <p></p>
  <!-- </div> -->
  <div class="dashboard">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>
          <h2>Look & Feel</h2>
        </mat-card-title>
        <mat-card-subtitle>The first three sections focus on the presentation aspects of your net price calculator. Any changes that you make are considered <em>draft</em> settings. You can view these changes safely by "launching in draft mode".</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <a class="nav-link" routerLink="../branding" routerLinkActive="active"> <img class="icon" src="./assets/images/icons8-rgb_circle_3.svg" alt="" /> BRANDING - change logo, background images, header properties and colors </a>
        <a class="nav-link" routerLink="../configuration" routerLinkActive="active"> <img class="icon" src="./assets/images/icons8-settings.svg" alt="" />CONFIGURATION - change school properties, rounding settings and package names </a>
        <a class="nav-link" routerLink="../content" routerLinkActive="active"> <img class="icon" src="./assets/images/icons8-web_design.svg" alt="" />CONTENT - modify the welcome page, please read, and next steps text </a>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>
          <h2>Deep Dive</h2>
        </mat-card-title>
        <mat-card-subtitle>This is the new preview mode that shows you how your changes will effect the calculations. While we go through the transition you can also see how changes in the new system may differ from the legacy application. </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <a class="nav-link" routerLink="../inspector" routerLinkActive="active"> <img class="icon" src="./assets/images/icons8-telescope.svg" alt="" />INSPECTOR - review results from stored sessions and preview calculation changes </a>
        <div *ngIf="(cc.draftRules | async)?.schoolProperties?.institutionalMethod">
          <a class="nav-link" href="https://highered.collegeboard.org/need-analysis-options" target="_blank"> <img class="icon" src="./assets/images/collegeboard-acorn.svg" alt="" />NEED ANALYSIS OPTIONS – update your IM options for your NPC calculations </a>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>
          <h2>Calculation Settings</h2>
        </mat-card-title>
        <mat-card-subtitle
          >Setup and change the rules to provide high quality estimates to students using the net price calculator. Any changes that you make are <em>draft</em> calculation rules. You can inspect the impact of these changes in the inspector and then publish them to your site when validated.
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <a class="nav-link" routerLink="../questions" routerLinkActive="active"> <img class="icon" src="./assets/images/icons8-ask_question.svg" alt="" />QUESTIONS - view your active questions and remove any unused </a>

        <a class="nav-link" routerLink="../costs" routerLinkActive="active"> <img class="icon" src="./assets/images/icons8-money.svg" alt="" />COSTS - assign values and tables to the costs of attendance </a>

        <a class="nav-link" routerLink="../packaging" routerLinkActive="active"> <img class="icon" src="./assets/images/icons8-open_box.svg" alt="" />PACKAGING - set up rules to for grand and aid package offerrings </a>

        <a class="nav-link" routerLink="../populations" routerLinkActive="active"> <img class="icon" src="./assets/images/icons8-groups.svg" alt="" />POPULATIONS - use populations to bucket students based on questions or calculations </a>

        <a class="nav-link" routerLink="../tables" routerLinkActive="active"> <img class="icon" src="./assets/images/icons8-data_sheet_filled.svg" alt="" />TABLES - organize matrix like information into lookup or range tables </a>

        <a class="nav-link" routerLink="../formulas" routerLinkActive="active"> <img class="icon" src="./assets/images/icons8-math.svg" alt="" />FORMULAS - use formulas for more complex calculatons </a>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
