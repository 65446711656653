import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CalcConfigService } from '_services/calc-config.service';
import { SelectorsService } from '_services/selectors.service';
import { Package, Rule, PackageParent } from '../../_models/package';
import { Table } from '../../_models/table';
import { find, differenceBy, remove, isEqual } from 'lodash-es';

import { CdkDragDrop, moveItemInArray, CdkDrag } from '@angular/cdk/drag-drop';
import { AuthService } from '_services/auth.service';

@Component({
  selector: 'app-packaging',
  templateUrl: './packaging.component.html',
  styleUrls: ['./packaging.component.css']
})

export class PackagingComponent implements OnInit, OnDestroy {
  selectedRow = -1;
  tabIndex = 0;
  tableName = '';
  table: Table;
  editMode = false;
  editRule: Rule = null;
  schoolId = '';
  packagesCopy: Package[];


  PackageNames = [
    { id: 'AdjustedParentContribution', name: 'Adjusted Parent Contribution', required: true, defaultOrder: 1 },
    { id: 'AdjustedStudentContribution', name: 'Adjusted Student Contribution', required: true, defaultOrder: 2 },
    { id: 'PellGrant', name: 'Pell Grant', required: true, defaultOrder: 3 },
    { id: 'InstitutionalGrantA', name: 'Institutional Grant A', required: false, defaultOrder: 4 },
    { id: 'InstitutionalGrantB', name: 'Institutional Grant B', required: false, defaultOrder: 5 },
    { id: 'InstitutionalGrantC', name: 'Institutional Grant C', required: false, defaultOrder: 6 },
    { id: 'InstitutionalScholarshipA', name: 'Institutional Scholarship A', required: false, defaultOrder: 7 },
    { id: 'InstitutionalScholarshipB', name: 'Institutional Scholarship B', required: false, defaultOrder: 8 },
    { id: 'InstitutionalScholarshipC', name: 'Institutional Scholarship C', required: false, defaultOrder: 9 },
    { id: 'StateGrantA', name: 'State Grant A', required: false, defaultOrder: 10 },
    { id: 'StateGrantB', name: 'State Grant B', required: false, defaultOrder: 11 },
    { id: 'StateGrantC', name: 'State Grant C', required: false, defaultOrder: 12 },
    { id: 'StudentLoanA', name: 'Student Loan A', required: false, defaultOrder: 13 },
    { id: 'StudentLoanB', name: 'Student Loan B', required: false, defaultOrder: 14 },
    { id: 'StudentLoanC', name: 'Student Loan C', required: false, defaultOrder: 15 },
    { id: 'StudentWork', name: 'Student Work', required: false, defaultOrder: 16 },
    { id: 'ParentLoan', name: 'Parent Loan', required: false, defaultOrder: 17 },
    { id: 'ConsolidatedSelfHelp', name: 'Consolidated Self Help', required: false, defaultOrder: 18 },
    { id: 'SchoolDefinedFund1', name: 'School Defined Fund 1', required: false, defaultOrder: 19 },
    { id: 'SchoolDefinedFund2', name: 'School Defined Fund 2', required: false, defaultOrder: 20 },
  ];
  PackageNamesMap = this.PackageNames.reduce((acc, item) => acc.set(item.id, item), new Map());

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public cc: CalcConfigService,
    private ss: SelectorsService,
    public auth: AuthService
  ) {
    this.titleService.setTitle('Packaging' + ' | NPC Admin');
  }

  ngOnInit() {
    this.schoolId = this.route.snapshot.parent?.paramMap.get('schoolId') ?? '';
    // this.cc.loadSchool(schoolId);
  }

  // TODO: Does not show packages with no rules (often disabled)
  clickedRow(event: any, rule: Rule) {

    if (event instanceof KeyboardEvent && !(event.code === 'Space' || event.code === 'Enter')) {
    } else {
      this.tableName = '';
      this.tabIndex = 0;

      if (this.editMode) {
        this.editRule = rule;
      } else {
        if (rule.table) {
          this.tableName = rule.table.name;
          this.table = rule.table;
          this.tabIndex = 2;
        }
      }
    }
  }

  setMode(mode: 'edit' | 'save' | 'cancel') {
    switch (mode) {
      case 'edit':
        this.packagesCopy = JSON.parse(JSON.stringify(this.cc.packages.value)); // lodash has a deep clone function
        this.editMode = true;
        break;
      case 'cancel':
        this.cc.packages.next(this.packagesCopy);
        this.editMode = false;
        break;
      case 'save':
        const packages = this.cc.packages.value;
        this.addMissingMandatoryPacakages(packages);
        if (!isEqual(this.packagesCopy, packages)) {
          // Remove unfinished rules
          packages.forEach(pack => {
            pack.rules = pack.rules.filter(rule =>
            (
              (
                rule.assignType === 'Factor' && rule.factor
                && ((rule.type === 'Formula' && rule.formulaId) || (rule.type !== 'Formula' && rule.keyId))
              )
              || (rule.assignType === 'Fixed' && (rule.amount || rule.amount === 0 || pack.id === 'PellGrant'))
              || (rule.assignType === 'Table' && rule.tableId)
            ));
          });
          let deleteFlag = this.removeUnusedPackages(packages);
          deleteFlag = deleteFlag || packages.length !== this.packagesCopy.length;
          // Call add Missing Mandatory Packages again in case one was removed
          this.addMissingMandatoryPacakages(packages);
          let order = 1;
          packages.forEach(pack => {
            pack.order = order++;
          });

          this.cc.savePackages(this.schoolId, packages, deleteFlag);
        }
        this.editMode = false;
        this.editRule = null;
        break;
    }
  }

  private addMissingMandatoryPacakages(packages: Package[]) {
    // If pack is Adjusted Parent Contribution, Adjused Student Contribution, or Pell Grant and NO Rule exists...
    // Then add default rules for each that are set as factors in the right place.
    const draftRules = this.cc.draftRules.value;
    const institutionalMethod = draftRules.schoolProperties?.institutionalMethod;

    if (packages.findIndex(pack => pack.id === 'AdjustedParentContribution') === -1) {
      packages.push(addRequiredPackage('AdjustedParentContribution', institutionalMethod ? 'inarParentIMResults.ContributionForStudent' : 'inarParentEfmResults.ContributionForStudent'));
      moveItemInArray(packages, packages.length - 1, 0);
    }
    if (packages.findIndex(pack => pack.id === 'AdjustedStudentContribution') === -1) {
      packages.push(addRequiredPackage('AdjustedStudentContribution', institutionalMethod ? 'inarStudentIMResults.ContributionForStudent' : 'inarStudentEfmResults.ContributionForStudent'));
      moveItemInArray(packages, packages.length - 1, 1);
    }
    if (packages.findIndex(pack => pack.id === 'PellGrant') === -1) {
      // Exclude any schools that do not require Pell Grant
      if (this.schoolId !== 'principia' && this.schoolId !== 'sattler') {
        packages.push(addRequiredPackage('PellGrant', 'rt.PellGrant'));
        moveItemInArray(packages, packages.length - 1, 2);
      }
    }

    // It's possible that a required package is altered in a way that it doesn't meet the filter criteria, so we will add it back in.
    function addRequiredPackage(packName: string, keyId: string): Package {
      const newRule: Rule = {
        amount: null,
        assignType: 'Factor',
        category: 'Assignment',
        enabled: true,
        factor: 100,
        formulaId: null,
        keyId: keyId,
        need_based: true,
        order: 1,
        population: null,
        populationId: '1',
        populationOperator: 'EQ',
        table: null,
        tableId: null,
        type: 'Calculation'
      };

      const newPackage: Package = {
        enabled: true,
        id: packName,
        order: packName === 'AdjustedParentContribution' ? 1 : packName === 'AdjustedStudentContribution' ? 2 : 3,
        parent: null,
        rules: [newRule]
      };
      return newPackage;
    }

  }



  drop(event: CdkDragDrop<string[]>, packages: Package[], isAdmin: boolean) {
    if (!this.PackageNamesMap.get(packages[event.currentIndex].id).required || isAdmin) {
      console.log(event.previousIndex, event.currentIndex);
      moveItemInArray(packages, event.previousIndex, event.currentIndex);
    }
  }

  getSelectorName(id: string): string {
    const selector = this.ss.getSelector(id);
    return selector ? selector.label : '';
  }

  setRulePopulation(r: Rule) {
    r.population = r.populationId === '1' ? null : find(this.cc.populations.value, { 'id': r.populationId });
  }

  movePackage(direction: 'up' | 'down', packages: Package[], index: number) {
    const temp = packages[index];
    if (direction === 'up') {
      packages[index] = packages[index - 1];
      packages[index - 1] = temp;
    } else {
      packages[index] = packages[index + 1];
      packages[index + 1] = temp;
    }
  }

  addRule(pack: Package) {
    const ruleOrder = pack.rules.length > 0 ? pack.rules[pack.rules.length - 1].order + 1 : 1;
    const aRule: Rule = {
      assignType: 'Fixed',
      need_based: false,
      enabled: true,
      populationId: '1',
      populationOperator: 'EQ',
      category: 'Assignment',
      amount: 0,
      type: 'Calculation',
      order: ruleOrder
    };
    pack.rules.push(aRule);
    this.clickedRow(null, aRule);
  }
  addPackage(id: string, packages: Package[]) {
    if (!find(packages, { 'id': id })) {
      const packOrder = packages.length > 0 ? packages[packages.length - 1].order + 1 : 1;
      const newPackage: Package = {
        id: id,
        enabled: true,
        order: packOrder,
        parent: this.getPackageParent(id),
        rules: []
      };
      packages.push(newPackage);
    }
  }

  private getPackageParent(id: string): PackageParent {
    const lastCharId = id[id.length - 1];
    if (lastCharId === 'A' || lastCharId === 'B' || lastCharId === 'C') {
      const parent = id.slice(0, id.length - 1);
      return { id: parent };
    } else {
      return null;
    }
  }

  getPopulationName(popId: string): string {
    const pop = popId === '1' ? null : find(this.cc.populations.value, { 'id': popId });
    if (pop) {
      return pop.name;
    } else {
      return 'All';
    }
  }

  removeUnusedPackages(packages: Package[]): boolean {
    const removedItems = remove(packages, pack => pack.rules.length === 0);
    return removedItems.length === 0 ? false : true;
  }
  getUnusedPackages(packages: Package[]) {
    return differenceBy(this.PackageNames, packages, 'id');
  }

  ngOnDestroy() {
    if (this.editMode) {
      this.setMode('cancel');
    }
  }

  onAssignTypeChange(rule: any) {
    if (rule.assignType === 'Fixed') {
      // Remove the selector if it was set before
      rule.keyId = null;
      rule.formulaId = null;
    } else if (rule.assignType === 'Factor') {
      // Reset the amount if it was set before
      rule.amount = null;
    } else if (rule.assignType === 'Table') {
      // Reset both amount and selector
      rule.amount = null;
      rule.keyId = null;
      rule.formulaId = null;
    }
  }

}
