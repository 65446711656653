import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { CalcConfigService } from '../../_services/calc-config.service';
import { UnivPrefsService } from '../../_services/univ-prefs.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  documentPath: string;
  configForm: UntypedFormGroup;
  state: string;
  schoolId: string;

  constructor(
    private titleService: Title,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private cc: CalcConfigService,
    public ups: UnivPrefsService
  ) {
    this.titleService.setTitle('Configuration' + ' | NPC Admin');
  }

  ngOnInit() {
    this.configForm = this.fb.group({
      universityName: ['', Validators.required],
      shortName: ['', Validators.required],
      cohortAidPercentYear: ['', Validators.required],
      cohortAidPercent: ['', Validators.required],
      npcExitUrl: [''],
      showEFC: [''],
      useRounding: [''],
      roundingValue: [''],
      efcRounding: [''],
      stateGrant: [''],
      institutionalGrant: [''],
      institutionalScholarship: [''],
      consolidatedSelfHelp: [''],
      schoolDefinedFund1: [''],
      schoolDefinedFund2: ['']
    }
    );
    this.schoolId = this.route.snapshot.parent?.paramMap.get('schoolId') ?? '';
    this.documentPath = 'preferences_draft/' + this.schoolId;
  }


  changeHandler(e) {
    this.state = e;
  }

  changeRounding() {
    const useRounding = this.configForm.controls.useRounding.value;
    const efcRounding = this.configForm.controls.efcRounding.value;
    const roundingValue = this.configForm.controls.roundingValue.value || 50;
    this.cc.saveDraftRules(this.schoolId, { 'useRounding': useRounding, 'efcRounding': efcRounding, 'roundingValue': roundingValue });
  }

}
