import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UnivPrefsService } from '_services/univ-prefs.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  documentPath: string;
  contentForm: UntypedFormGroup;
  state: string;
  modules = {
    clipboard: {
      matchVisual: false
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      ['link', 'image', 'video']                         // link and image, video
    ]
  };

  constructor(
    private titleService: Title,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    public ups: UnivPrefsService
  ) {
    this.titleService.setTitle('Content' + ' | NPC Admin');
  }

  ngOnInit() {
    this.contentForm = this.fb.group({
      content: new UntypedFormGroup({
        welcomeIntro: new UntypedFormControl(),
        welcomeDisclaimer: new UntypedFormControl(),
        nextSteps: new UntypedFormControl(),
        schoolQuestionHeader: new UntypedFormControl()
      })
    });
    const schoolId = this.route.snapshot.parent?.paramMap.get('schoolId') ?? '';
    this.documentPath = 'preferences_draft/' + schoolId;

  }

  changeHandler(e) {
    this.state = e;
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    // Fixes issue with Chrome where HTML formatting is stripped when using material tab control
    const fg: UntypedFormGroup = this.contentForm.get(['content']) as UntypedFormGroup;
    let i = 0;
    for (const field in fg.controls) {
      if (i++ === tabChangeEvent.index) {
        // Relies on the form controls being initialized in same order as displayed on HTML tabs
        const fc: UntypedFormControl = fg.get(field) as UntypedFormControl;
        fc.patchValue(this.ups.draftPrefs.value['content'][field]);
      }
    }
  }

}
