import { Component, Input } from '@angular/core';
import { QuestionsService } from '_services/questions.service';
import { CalcConfigService } from '_services/calc-config.service';
import { Condition } from '../../_models/population';
import { Table } from '../../_models/table';
import { Rule } from '../../_models/package';
import { isString } from 'lodash-es';
import { Question } from 'app/_models/question';

// Used by Formula
export interface Part {
  math: string;
  type: 'Question' | 'Calculation' | 'Table' | 'New';
  question?: Question;
  keyId?: string; // e.x. maps to questionId if NPCInput
  tableId?: string;
  value: number;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'input-selector',
  templateUrl: './input-selector.component.html',
  styleUrls: ['./input-selector.component.css']
})
export class InputSelectorComponent {

  @Input() key: Part | Condition | Table | Rule;
  @Input() caller: 'Population' | 'Package' | 'Table' | 'Formula';
  // Formula & Package use numeric questions only

  asPart(val): Part { return val; }
  asTable(val): Table { return val; }
  asRule(val): Rule { return val; }

  constructor(
    public qs: QuestionsService,
    public cc: CalcConfigService
  ) { }

  setKey(event: any) {
    if (isString(event)) {
      this.key.keyId = event;
    }
  }

  getQuestionName(qId: string): string {
    const q = this.qs.getQuestion(qId);
    return q ? q.label : '';
  }

  getFormulaName(formulaId: string): string {
    const f = this.cc.getFormula(formulaId);
    return f ? f.name : '';
  }

  getTableName(tableId: string): string {
    const t = this.cc.getTable(tableId);
    return t ? t.name : '';
  }


  setFormula(formulaId: string) {
    (this.key as Rule).formula = this.cc.getFormula(formulaId);
  }

}
