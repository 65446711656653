import { Component, Input, OnChanges } from '@angular/core';
import { Population, Block, Condition, ConditionMap } from '../../_models/population';
import { QuestionsService } from '_services/questions.service';
import { SelectorsService } from '_services/selectors.service';
import { CalcConfigService } from '_services/calc-config.service';

@Component({
  selector: 'app-population',
  templateUrl: './population.component.html',
  styleUrls: ['./population.component.css']
})
export class PopulationComponent implements OnChanges {


  bindings = {
    // This will overwrite the default binding also named 'tab'
    tab: {
      key: 9,
      handler: function() {
        return true;
      }
    }
  };

  modules = {
    clipboard: {
      matchVisual: false
    },
    keyboard: {
      bindings: this.bindings
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      ['link']                         // link and image, video
    ]
  };

  constructor(
    public qs: QuestionsService,
    public cc: CalcConfigService,
    private ss: SelectorsService,
  ) { }
  conditionMap = ConditionMap;
  conditionColor = ['#28a745', '#3f51b5'];
  editCondition = null;

  @Input() pop: Population;
  @Input() editMode: boolean;

  ngOnChanges() {
    // Population Questions
    for (const block of this.pop.blocks) {
      for (const cond of block.conditions) {
        if (cond.type === 'Question') {
          cond.question = this.qs.getQuestion(cond.keyId);
        }
      }
    }
  }

  getSelectorName(id: string): string {
    const selector = this.ss.getSelector(id);
    return selector ? selector.label : '';
  }

  addCondition(block: Block) {
    const ruleOrder = block.conditions.length > 0 ? block.conditions[block.conditions.length - 1].order + 1 : 1;
    const aCond: Condition = {
      type: 'Question',
      // keyId?: string; // e.x. maps to questionId if NPCInput
      // formulaId?: string;
      // formula?: Formula;
      // question?: Question;
      format: 'Number',
      operator: 0,
      value: '',
      // value2?: string;     // used for "between" comparisons as upper bound
      // values?: string[];  // If value has comma separated values than split them into this array
      order: ruleOrder
    };
    block.conditions.push(aCond);
    this.editCondition = aCond;
  }
  removeCondition(pop: Population, blockIndex: number, condIndex: number) {
    pop.blocks[blockIndex].conditions.splice(condIndex, 1);
    if (pop.blocks[blockIndex].conditions.length === 0) {
      pop.blocks.splice(blockIndex, 1);
    }
  }
  addBlock(pop: Population) {
    // const blockOrder = pop.blocks.length > 0 ? pop.blocks[pop.blocks.length - 1].order + 1 : 1;
    const aCond: Condition = {
      type: 'Question',
      // keyId?: string; // e.x. maps to questionId if NPCInput
      // formulaId?: string;
      // formula?: Formula;
      // question?: Question;
      format: 'Number',
      operator: 0,
      value: '',
      // value2?: string;     // used for "between" comparisons as upper bound
      // values?: string[];  // If value has comma separated values than split them into this array
      order: 1
    };
    const aBlock: Block = {
      blockOperatorAnd: true,
      conditions: [aCond]
    };
    pop.blocks.push(aBlock);
    this.editCondition = aCond;
  }
}
