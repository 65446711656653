<div class="title-bar">
  <h1>POPULATIONS</h1>
  <app-help screen="populations"></app-help>
  <ng-container *ngIf="!editMode">
    <button mat-flat-button color="primary" [disabled]="tabIndex === 0" (click)="setMode('edit')">{{tabIndex == 0 ? 'Select Population to Edit' : 'Edit Population'}}</button>
  </ng-container>
  <ng-container *ngIf="editMode">
    <button mat-flat-button color="accent" [disabled]="tabIndex === 0" (click)="setMode(delete.checked ? 'delete' : 'save')">
      <span *ngIf="delete.checked">Delete Population</span>
      <span *ngIf="!delete.checked">Save Population</span>
    </button>
    <button mat-flat-button color="warn" (click)="setMode('cancel')">Cancel</button>
    <mat-checkbox style="margin-left: 2rem;" #delete>Delete Population</mat-checkbox>
  </ng-container>
  <app-aid-year style="margin-left:auto;" [disabled]="editMode"></app-aid-year>
</div>


<div *ngIf="cc.populations | async as populations">
  <mat-tab-group mat-stretch-tabs="false" dynamicHeight [selectedIndex]="tabIndex" (selectedIndexChange)="changeTab($event)">
    <mat-tab label="Listing">
      <mat-card appearance="outlined">
        <div *ngIf="cc.populations | async as populations">
          <div *ngIf="populations.length > 0">

            <table class="table" style="width:auto">
              <thead>

                <th scope="col">Name/Description</th>
                <th scope="col">Usage
                  <br>
                  <span class="legend-package">Package</span>
                  <span class="legend-cost">Cost</span>
                  <span class="legend-next-step">Next Step</span>
                </th>

              </thead>
              <tbody>

                <tr *ngFor="let pop of populations" (keypress)="clickedRow($event, pop)" (click)="clickedRow($event, pop)" tabindex="0">
                  <th scope="row">
                    {{pop.name}}
                    <div class="description" [innerHTML]="pop.description"></div>
                  </th>
                  <td>
                    <app-usage entityType="Population" [entityId]="pop.id"></app-usage>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button mat-button color="secondary" (click)="addPopulation(populations)">add new population</button>
                  </td>
                  <td></td>
                  <td></td>
                </tr>

              </tbody>
            </table>
          </div>
          <br>
          <div *ngIf="populations.length === 0">
            There are no populations <button mat-button color="secondary" (click)="addPopulation(populations)">add new
              population</button>
          </div>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab [label]="popName" [disabled]="popName === ''">
      <app-population *ngIf="popName !== ''  && !editMode" [pop]="pop" [editMode]="editMode"></app-population>
      <app-population *ngIf="popName !== ''  && editMode" [pop]="popCopy" [editMode]="editMode"></app-population>
    </mat-tab>

  </mat-tab-group>
</div>