// Core Module
// ONLY provide services from this module

import { NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// @angular/fire/ imports
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule, USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { AngularFireStorageModule, USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { AngularFireFunctionsModule, REGION, USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';

// Custom services
import { UnivPrefsService } from './univ-prefs.service';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { QuestionsService } from './questions.service';
import { PublishingService } from './publishing.service';
import { InspectorService } from './inspector.service';
import { CalcConfigService } from './calc-config.service';
import { SelectorsService } from './selectors.service';
import { LoggingService } from './logging.service';

@NgModule({
    imports:
        [
            AngularFireModule.initializeApp(environment.firebaseEnv),
            AngularFirestoreModule, // .enablePersistence(),
            AngularFireAuthModule,
            AngularFireStorageModule,
            AngularFireFunctionsModule
        ],
    providers:
        [
            AuthService,
            UnivPrefsService,
            AuthGuard,
            QuestionsService,
            PublishingService,
            InspectorService,
            CalcConfigService,
            SelectorsService,
            LoggingService,
            provideHttpClient(withInterceptorsFromDi()),
            { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined },
            { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', '9001'] : undefined },
            { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', '5001'] : undefined },
            { provide: USE_STORAGE_EMULATOR, useValue: environment.useEmulators ? ['localhost', '9199'] : undefined },
            { provide: REGION, useValue: environment.functionsURL },
        ]
})
export class CoreModule { }
