<div class="form">
  <form [formGroup]="userForm">
    <h3>NPC Admin</h3>
    <hr>
    <!-- <p>First time user <a href="">click here</a></p> -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          First time users
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        First time users can enter a <strong>school email</strong> and a password then press "Request Access".
      </p>
      <p>
        The NPC Support team will be notified and authorize access to the appropriate school.
        Next time you access the site you will be in your new admin application.
      </p>
    </mat-expansion-panel>
    <mat-form-field [hideRequiredMarker]="true" style="margin-top:1rem;width:100%">
      <input matInput placeholder="school email" type="email" formControlName="email">
      <mat-error>Please enter a valid school email</mat-error>
    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" style="width:100%">
      <input matInput placeholder="password" type="password" formControlName="password">
      <mat-hint align="start">6 character minimum</mat-hint>
      <mat-error>Please enter a valid password (at least 6 characters)</mat-error>
    </mat-form-field>

    <div>
      <button mat-flat-button type="submit" color="primary" (click)="login()">Login</button>
      <button mat-flat-button type="submit" color="accent" (click)="signup()" style="margin-left: 1rem;margin-right: 1rem;">Request
        Access</button>
      <button *ngIf="!email.invalid && (email.dirty || email.touched) && !passReset" mat-stroked-button color="accent"
        (click)="resetPassword()">Reset
        Password</button>
    </div>
    <div *ngIf="passReset" style="margin-top:1rem;">
      Password reset sent to your email.<br>Contact <a href="mailto:npc_support@rewiretech.com">npc_support&#64;rewiretech.com</a>
      for help.
    </div>
  </form>
</div>
