import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Formula } from '../../_models/formula';
import { SelectorsService, Selector } from '../../_services/selectors.service';
import { CalcConfigService } from '../../_services/calc-config.service';
import { Part } from '../../_dashboard/input-selector/input-selector.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.css']
})
export class FormulaComponent implements OnChanges {

  constructor(
    private ss: SelectorsService,
    private cc: CalcConfigService
  ) { }

  @Input() formula: Formula;
  @Input() editMode: boolean;

  formulaParts: Part[] = [];
  solution = new BehaviorSubject(null);

  ngOnChanges() {
    const formulaSplit = this.formula.formula.split('|');
    this.formulaParts = [];
    let math = '';

    for (const sub of formulaSplit) {
      const prefix = sub.split('.')[0];
      // Switch to case and add question
      switch (prefix) {
        case 'tab':
          const table = this.cc.getTable(sub.split('.')[1]);
          if (table) {
            this.formulaParts.push({ math: math, type: 'Table', tableId: sub.split('.')[1], value: 0 });
          } else {
            console.log('Table ', sub, ' not found for Formula ID', this.formula.formulaId);
            math = sub;
          }
          break;
        case 'student':
        case 'parent':
          this.formulaParts.push({ math: math, type: 'Question', keyId: sub, value: 0 });
          break;
        case 'rt':
        case 'inarParentEfmResults':
        case 'inarParentIMResults':
        case 'inarStudentEfmResults':
        case 'inarStudentIMResults':
        case 'inarStudentData':
        case 'inarMiscCalculations':
          this.formulaParts.push({ math: math, type: 'Calculation', keyId: sub, value: 0 });
          break;
        default:
          // If not a key value or table, then it is the start of a new part
          math = sub;
          break;
      }
    }
    this.formulaParts.push({ math: math, type: 'New', value: 0 });
  }

  formulaSolve(): string {
    let formulaString = '';
    let newFormula = '';
    this.formulaParts.forEach(part => {
      formulaString += part.math + (part.type !== 'New' ? ' ' + part.value + ' ' : '');
      newFormula += part.math;
      switch (part.type) {
        case 'Calculation':
        case 'Question':
          if (part.keyId) {
            newFormula += '|' + part.keyId + '|';
          }
          break;
        case 'Table':
          if (part.tableId) {
            newFormula += '|tab.' + part.tableId + '|';
          }
          break;
        default:
          break;
      }
    });

    try {
      const answer = eval(formulaString);
      this.formula.formula = newFormula;
      this.formula.valid = true;
      this.solution.next(answer);
      return formulaString + ' equals ';
    } catch {
      this.solution.next(null);
      this.formula.valid = false;
      return formulaString + ' is not a formula';
    }
  }

  deletePart(index: number) {
    if (this.formulaParts.length > 1) {
      this.formulaParts.splice(index, 1);
    } else {
      // Remove any Input selections
      this.formulaParts[index].type = 'New';
      this.formulaParts[index].keyId = null;
      this.formulaParts[index].tableId = null;
    }
  }

  getName(part: Part) {
    switch (part.type) {
      case 'Table':
        const table = this.cc.getTable(part.tableId);
        if (table) {
          return table.name;
        } else {
          return part.tableId + ' Not Found ';
        }
      case 'Calculation':
      case 'Question':
        return this.ss.getSelector(part.keyId).label;
      default:
        return '';
    }
  }

}
