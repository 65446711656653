import { Component, Input } from '@angular/core';
import { CalcConfigService } from '_services/calc-config.service';
import { QuestionsService } from '_services/questions.service';

@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.css']
})
export class PublishComponent {
  @Input() schoolId: string;

  MAXDATE = new Date('2050-01-01');

  constructor(
    public cc: CalcConfigService,
    private qs: QuestionsService
  ) { }

  async publishRules() {
    const rulesMessage = await this.cc.publishRules(this.schoolId);
    const questionsMesssage = await this.qs.publishQuestions(this.schoolId);
    alert(rulesMessage + '\n' + questionsMesssage);
    // this.processSession(null);
  }

  async resetRules() {
    const confirmation = confirm(
      'Resetting rules will copy the rules that have been published to your draft rules. ' +
      'This will erase any recent changes you have made to your draft rules.\n' +
      'Are you sure you want to reset your rules?'
    )
    if (confirmation) {
      await this.qs.resetQuestions(this.schoolId);
      await this.cc.resetRules(this.schoolId);
      alert('Rules Reset');
    }
  }


}
