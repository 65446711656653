import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from '_services/auth.service';
import { Router } from '@angular/router';
import { EplService } from '_services/epl.service';
import { take, tap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public router: Router,
  ) {
  }

  ngOnInit() { }

  // HostListener when navigating away from page to logout auth
  // TODO: EPL - Enable to logout when navigating away from page since EPL will handle auth
  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler() {
  //   this.authService.user.pipe(
  //     take(1),
  //     tap(user => {
  //       if (user && !user.admin) {
  //         this.authService.logout(false);
  //       }
  //     })
  //   ).subscribe();
  // }

}
