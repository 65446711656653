import { Pipe, PipeTransform } from '@angular/core';

// TODO: Review this alternative: https://stackoverflow.com/questions/41672578/filter-on-multiple-columns-using-one-pipe-angular-2
@Pipe({
  name: 'filter',
  pure: true
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], filterFunction: any): any {
    if (!items || !filterFunction) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(filterFunction);
  }
}
