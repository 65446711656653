<div class="title-bar">
  <h1>COSTS</h1>
  <app-help screen="costs"></app-help>
  <ng-container *ngIf="!editMode">
    <button mat-flat-button color="primary" (click)="setMode('edit')" [disabled]="tabIndex !== 0">Edit Costs </button>
  </ng-container>
  <ng-container *ngIf="editMode">
    <button mat-flat-button color="accent" (click)="setMode('save')">Save Costs</button>
    <button mat-flat-button color="warn" (click)="setMode('cancel')">Cancel</button>
  </ng-container>
  <app-aid-year style="margin-left:auto;" [disabled]="editMode"></app-aid-year>
</div>

<div *ngIf="editMode" class="rounding-message">
  <p>In order to allow families to budget and plan for college, consider <strong>using round numbers</strong> to display a rough estimate for families.</p>
</div>

<mat-tab-group mat-stretch-tabs="false" dynamicHeight [selectedIndex]="tabIndex" (selectedIndexChange)="tabIndex = $event">
  <mat-tab label="Costs of Attendance">
    <div *ngIf="cc.expenses | async as expenses">
      <mat-card appearance="outlined">
        <table class="table table-hover" style="width:auto">
          <thead>
            <tr>
              <th scope="col">Expense</th>
              <th scope="col">Population</th>
              <th scope="col">Amount</th>
              <th scope="col" class="text-center" *ngIf="editMode">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let exp of expenses">
              <ng-container *ngFor="let r of exp.rules; let i = index">
                <tr *ngIf="!editMode">
                  <th scope="rowgroup" [attr.rowspan]="exp.rules.length" *ngIf="i === 0">{{expenseNameMap[exp.type]}}</th>
                  <td>
                    {{r.populationId === '1' ? 'All' : ''}}
                    <div *ngIf="r.population as pop">{{pop.name}}
                      <br><span class="description">{{pop.description}}</span>
                    </div>
                  </td>
                  <td>
                    <span *ngIf="!r.tableId">{{r.amount | number}}</span>
                    <span *ngIf="r.tableId" class="link" (click)="clickedRow(r)">{{r.table?.name}}</span>
                  </td>

                </tr>

                <!-- Edit Rules -->
                <tr *ngIf="editMode">
                  <td><span *ngIf="i === 0" [id]="expenseNameMap[exp.type]">{{expenseNameMap[exp.type]}}</span>
                    <!-- <div *ngIf="r.tableId"><button mat-button (click)="r.tableId = null">use amount</button></div>
                    <div *ngIf="!r.tableId"><button mat-button (click)="r.tableId = r.table?.tableId || '1'">use table</button></div> -->
                    <div style="font-size:10pt; margin-top: .5rem">
                      <mat-radio-group [(ngModel)]="r.tableId" role="radiogroup" [attr.aria-labelledby]="expenseNameMap[exp.type]">
                        <mat-radio-button aria-label="Use Amount" [value]="null" class="radio-option" style="margin-right: 1rem;">Use Amount</mat-radio-button>
                        <mat-radio-button aria-label="Use Table" [value]="r.table?.tableId || r.tableId || '1'" class="radio-option">Use Table</mat-radio-button>
                      </mat-radio-group>
                    </div>

                  </td>
                  <td>

                    <mat-form-field class="population-select">
                      <mat-select placeholder="Select Population" [(value)]="r.populationId" (selectionChange)="setRulePopulation(r)" [aria-labelledby]="'Select Population ' + getPopulationName(r.populationId)">
                        <mat-option value='1' id="All">All</mat-option>
                        <mat-option *ngFor="let pop of cc.populations | async" [value]=pop.id>
                          {{pop.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <div *ngIf="r.population as pop">{{pop.description}}</div>

                  </td>
                  <td>

                    <div *ngIf="!r.tableId">
                      <mat-form-field style="width: 5em;">
                        <input matInput placeholder="Amount" type="number" [(ngModel)]="r.amount">
                      </mat-form-field>
                    </div>

                    <div *ngIf="r.tableId">
                      <mat-form-field class="form-field">
                        <mat-select placeholder="Select Table" [(value)]="r.tableId" (selectionChange)="setRuleTable(r)" [aria-labelledby]="'Select Table ' + getTableName(r.tableId)">
                          <mat-option *ngFor="let table of cc.tables | async" [value]=table.tableId>
                            {{table.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                  </td>
                  <td>
                    <button mat-button color="warn" (click)="deleteRule(exp, i)">delete rule</button>
                    <button *ngIf="i === (exp.rules.length - 1)" mat-button color="secondary" (click)="addRule(exp)">add
                      rule</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </mat-card>
    </div>
  </mat-tab>
  <mat-tab [label]="tableName" [disabled]="tableName === ''">
    <app-table *ngIf="tableName !== ''" [table]="table"></app-table>
  </mat-tab>
</mat-tab-group>