import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { filter, sortBy } from 'lodash-es';
import { convertTimestampsPipe } from '../_pipes/convert-firestore-timestamp.pipe';

export interface School {
  id?: string;
  name: string;
  demo: boolean;
  live?: Date; // TODO: Deprecated - do we want this information before removing it?
  lastLogIn?: Date; // Date last logged in by a non-admin
  lastPublished?: Date; // Date Calculation Rules were last published (including admin)
  disabledDate?: Date;
  status: 'LIVE' | 'COMING' | 'DEACTIVATED' | 'HOLD';
  schoolProperties?: SchoolProperties;
  latestTraffic?: TrafficPeriod;
}
// TODO: Duplicated in CalcPackage.ts
export interface SchoolProperties {
  draftAidYear: number; // DRAFT - Year for which aid is calculated
  aidYear: number; // PUBLISHED - Year for which aid is calculated
  orgId: string;  // Organization ID used by INAS
  cssId: string; // College Board ID for IM Schools
  institutionalMethod: boolean; // True if using College Board propeitary methodology
}

export interface TrafficPeriod {
  date: Date;
  visitors: number;
  starts: number;
  results: number;
}

@Injectable({
  providedIn: 'root'
})

export class SchoolsService {
  allSchools = new BehaviorSubject<School[]>([]);
  liveSchools = new BehaviorSubject<School[]>([]);
  demoSchools = new BehaviorSubject<School[]>([]);
  notActiveSchools = new BehaviorSubject<School[]>([]);
  pausedSchools = new BehaviorSubject<School[]>([]);
  comingSchools = new BehaviorSubject<School[]>([]);


  constructor(
    private afs: AngularFirestore
  ) {
    const schoolCol = this.afs.collection<School>('schools', ref => ref.orderBy('name'));
    schoolCol.snapshotChanges().pipe(
      map(s => {
        return s.map(a => {
          const data = a.payload.doc.data(); // as School;
          const id = a.payload.doc.id; // This is needed if we remove "id" from the record
          return { id, ...data };
        });
      }),
      convertTimestampsPipe(),
      tap(docs => {
        this.filterSchools(docs);
        this.sortSchools({ active: 'name', direction: 'asc' }, docs);
      })
    ).subscribe();
  }

  newSchool(code: string, school: School): Promise<any> {
    const schoolCol = this.afs.collection('schools');
    return schoolCol.doc(code).set(school);
  }

  sortSchools(sort: Sort, schools: School[]) {

    // const schools = this.allSchools.value;
    const isAsc = sort.direction === 'asc';

    schools.sort((a, b) => {
      switch (sort.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'method': return this.compare(a.schoolProperties?.institutionalMethod ? 1 : 0, b.schoolProperties?.institutionalMethod ? 1 : 0, isAsc);
        case 'live': return this.compare(new Date(a.live) || 0, new Date(b.live) || 0, isAsc);
        case 'lastLogIn': return this.compare(a.lastLogIn || 0, b.lastLogIn || 0, isAsc);
        case 'lastPublished': return this.compare(a.lastPublished || 0, b.lastPublished || 0, isAsc);
        case 'disabledDate': return this.compare(a.disabledDate || 0, b.disabledDate || 0, isAsc);
        case 'aidYear': return this.compare(a.schoolProperties?.aidYear, b.schoolProperties?.aidYear, isAsc);
        case 'visitors': return this.compare(a.latestTraffic?.visitors || 0, b.latestTraffic?.visitors || 0, isAsc);
        case 'results': return this.compare(a.latestTraffic?.results || 0, b.latestTraffic?.results || 0, isAsc);
        default: return 0;
      }
    });

  }

  private compare(a: number | string | Date, b: number | string | Date, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  updateSchool(schoolId: string, updateObject: any) {
    this.afs.doc<School>('schools/' + schoolId).set(updateObject, { merge: true });
  }

  filterSchools(schools: School[]) {
    // const totalVisitors = schools.map(school => school.latestTraffic?.visitors || 0).reduce((prev, curr) => prev + curr, 0);
    // const totalResults = schools.map(school => school.latestTraffic?.results || 0).reduce((prev, curr) => prev + curr, 0);
    // console.log(totalVisitors, totalResults);
    this.allSchools.next(schools);
    this.liveSchools.next(filter(schools, o => o.demo === false && !o.disabledDate));
    this.demoSchools.next(filter(schools, o => o.demo === true && !o.disabledDate));
    this.notActiveSchools.next(filter(schools, o => o.disabledDate > '0' && (o.status === 'DEACTIVATED' || !o.status)));
    this.pausedSchools.next(filter(schools, o => o.disabledDate > '0' && o.status === 'HOLD'));
    this.comingSchools.next(filter(schools, o => o.disabledDate > '0' && o.status === 'COMING'));
    // console.log(JSON.stringify(this.allSchools.value));
  }

  buildAllSchoolsString(schools: School[]) {
    let schoolListString = '';
    if (schools) {
      schools.forEach(school => {
        schoolListString += school.id + ',';
      });
      // Remove last comma
      schoolListString = schoolListString.substring(0, schoolListString.length - 1);
    }
    return schoolListString;
  }

}



