import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CalcConfigService } from '_services/calc-config.service';
import { QuestionsService } from '_services/questions.service';
import { Population, Block } from '../../_models/population';

import { filter } from 'lodash-es';
@Component({
  selector: 'app-populations',
  templateUrl: './populations.component.html',
  styleUrls: ['./populations.component.css']
})
export class PopulationsComponent implements OnInit {
  tabIndex = 0;
  popName = '';
  pop: Population;
  popCopy: Population;
  editMode = false;
  schoolId: string;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public cc: CalcConfigService,
    public qs: QuestionsService
  ) {
    this.titleService.setTitle('Populations' + ' | NPC Admin');
  }

  ngOnInit() {
    this.schoolId = this.route.snapshot.parent?.paramMap.get('schoolId') ?? '';
  }

  setMode(mode: 'edit' | 'save' | 'cancel' | 'delete') {
    switch (mode) {
      case 'edit':
        this.popCopy = JSON.parse(JSON.stringify(this.pop)); // lodash has a deep clone function
        this.editMode = true;
        break;
      case 'cancel':
        // automatically loses the population copy
        this.editMode = false;
        if (this.popCopy.blocks[0].conditions.length === 0) {
          // Cancelled on a population that was not set up - remove population
          this.clickedRow(null);
        }
        break;
      case 'delete':
        this.editMode = false;
        // Check to see if table is being used before deletion
        if (filter(this.cc.usedBy.value, { 'entityId': this.pop.id, 'entityType': 'Population' }).length === 0) {
          this.cc.deletePopulation(this.schoolId, this.pop);
        } else {
          alert('This population is being used. You need to remove all uses before deleting the population (including next steps)');
        }
        this.clickedRow(null);
        break;
      case 'save':
        if (this.popCopy.nextStep) {
          console.log(this.popCopy.nextStep);
          // If the nextstep is just spaces and HTML then remove it
          if (this.popCopy.nextStep.replace(/<[^>]*>?/gm, '').trim().length === 0) {
            this.popCopy.nextStep = '';
          }
        }
        // Remove unfinished conditions
        this.popCopy.blocks.forEach(block => {
          block.conditions = block.conditions.filter(cond => (cond.keyId || cond.formulaId));
          block.conditions = block.conditions.filter(cond => (!!cond.value || cond.value === 0 || cond.values));
        });
        // Remove empty blocks
        this.popCopy.blocks = this.popCopy.blocks.filter(block => (block.conditions.length > 0));
        // Save the population
        this.pop = this.popCopy;
        if (this.popCopy.blocks.length > 0) {
          this.cc.savePopulation(this.schoolId, this.popCopy);
        } else {
          this.clickedRow(null);
        }
        this.editMode = false;
        break;
    }
  }

  changeTab(event: any) {
    this.tabIndex = event;
    if (this.editMode && this.tabIndex === 0) {
      this.setMode('cancel');
    }
  }

  clickedRow(event: any, pop?: Population) {

    if (event instanceof KeyboardEvent && !(event.code === 'Space' || event.code === 'Enter')) {
    } else {
      if (pop) {
        this.popName = pop.name;
        this.pop = pop;
        this.tabIndex = 1;
      } else {
        this.popName = '';
        this.tabIndex = 0;
      }
    }
  }

  addPopulation(pops: Population[]) {
    const aBlock: Block = {
      blockOperatorAnd: true,
      conditions: []
    };
    const aPopulation: Population = {
      id: this.cc.getUniqueId(),
      name: 'New Population',
      description: '',
      nextStep: '',
      groupOperatorAnd: true,
      blocks: [aBlock]
    };
    // Automatically edit this table
    this.clickedRow(null, aPopulation);
    this.setMode('edit');
  }


}
