import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[formulaOnly]'
})
export class FormulaOnlyDirective {
    inputElement: HTMLElement;
    constructor(public el: ElementRef) {
        this.inputElement = el.nativeElement;
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        // console.log(e.key);
        // Generic keys
        if (
            ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter'].indexOf(e.key) !== -1 || // Allow: Delete, Backspace, Tab, Escape, Enter
            ['Home', 'End', 'ArrowLeft', 'ArrowRight'].indexOf(e.key) !== -1 || // Allow: Home, End, Left, Right
            (e.key === 'v' && e.metaKey === true) // Allow: Cmd+V (Mac)

            // [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 || // Allow: Delete, Backspace, Tab, Escape, Enter
            // (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
            // (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
            // (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
            // (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
            // (e.keyCode === 65 && e.metaKey === true) || // Allow: Cmd+A (Mac)
            // (e.keyCode === 67 && e.metaKey === true) || // Allow: Cmd+C (Mac)
            // (e.keyCode === 86 && e.metaKey === true) || // Allow: Cmd+V (Mac)
            // (e.keyCode === 88 && e.metaKey === true) || // Allow: Cmd+X (Mac)
            // (e.keyCode >= 35 && e.keyCode <= 39) // Allow: Home, End, Left, Right
        ) {      // let it happen, don't do anything
            return;
        }
        // Specific keys - if not an approved key, stop the keypress.
        if (
            ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) !== -1 ||
            ['(', ')', '*', '/', '+', '-', '.'].indexOf(e.key) !== -1
        ) {
            return;
        } else {
            e.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        if (!event) {
            return;
        }

        event.preventDefault();
        const pastedInput: string = event.clipboardData
            .getData('text/plain')
            .replace(/\D/g, ''); // get a digit-only string
        document.execCommand('insertText', false, pastedInput);
    }

    @HostListener('drop', ['$event'])
    onDrop(event) {
        if (!event) {
            return;
        }

        event.preventDefault();
        const textData = event.dataTransfer.getData('text').replace(/\D/g, '');
        this.inputElement.focus();
        document.execCommand('insertText', false, textData);
    }
}
