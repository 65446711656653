<div class="title-bar">
  <h1>INSPECTOR</h1>
  <app-help screen="inspector"></app-help>
  <app-publish [schoolId]="schoolId"></app-publish>
</div>

<mat-card appearance="outlined" *ngIf="(cc.draftRules | async)?.schoolProperties?.institutionalMethod">
  <mat-card-content>
    <p>Your NPC uses the IM options you’ve selected on the <a href="https://highered.collegeboard.org/need-analysis-options" target="_blank">Need Analysis Option site</a> to calculate contributions for your students and their families.</p>
  </mat-card-content>
</mat-card>

<div *ngIf="is.sessions | async; let sessions" class="session-selector">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Select an NPC session to compare any rule changes</h2>
      </mat-card-title>
      <mat-card-subtitle>After affirming changes - publish the rules to your live Net Price Calculator.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="session-field">
        <mat-label>Session</mat-label>
        <mat-select [(value)]="selected" (selectionChange)="cloudSessions($event)">
          <mat-option value="new"> Create new session... </mat-option>
          <mat-option *ngFor="let session of sessions" [value]="session"> [{{ session.savedAt.toDate() | date : "MM/dd/yy" }}] {{ session.sessionName }} </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="selected">
        <a mat-flat-button color="primary" href="{{ draftModeUrl + schoolId + '?draft=' + selected.userId + '&sessionId=' + selected.id }}" target="_blank">Go to session</a>
        <button mat-button color="warn" style="margin-left: 1rem" (click)="deleteSession(selected.id)">Delete session</button>
      </ng-container>

      <!-- <button mat-button (click)="getCode(selected.answers)">get answers</button> -->
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Convert a student session</h2>
      </mat-card-title>
      <mat-card-subtitle>Use a student session ID to create a session to analyze</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="student-session-field">
        <input matInput placeholder="Student Session ID" #studentSessionId maxlength="20" minlength="10" />
      </mat-form-field>
      <button *ngIf="studentSessionId.value" mat-button color="primary" (click)="createSession(studentSessionId.value)">Create session</button>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #loading>
  {{ selected ? "Waiting on results from NPC Calculation Engine..." : "" }}
</ng-template>

<ng-container *ngIf="draftResults | async as draftResult; else loading">
  <ng-container *ngIf="baseResults | async as publishedResult; else loading">
    <mat-tab-group mat-stretch-tabs="false" dynamicHeight>
      <mat-tab label="Published vs. Draft">
        <div style="display: flex; flex-flow: row wrap">
          <mat-card appearance="outlined">
            <mat-card-header>
              <h2>Cost of Attendance</h2>
            </mat-card-header>
            <mat-card-content>
              <table class="table" style="width: auto">
                <thead>
                  <th scope="col">Type</th>
                  <th scope="col">Published<br />{{ (this.cc.publishedRules | async).schoolProperties?.aidYear }}</th>
                  <th scope="col">Draft<br />{{ (this.cc.draftRules | async).schoolProperties?.aidYear }}</th>
                  <th scope="col">Difference</th>
                </thead>
                <tbody>
                  <tr *ngFor="let comp of costComparison | async" [ngClass]="{ 'summary-row': comp.type === 'Total Cost' }">
                    <th scope="row" style="font-weight: normal">{{ comp.type }}</th>
                    <td>{{ comp.base | number }}</td>
                    <td>{{ comp.changed | number }}</td>
                    <td class="difference">{{ comp.diff > 0 ? "+" : "" }}{{ (comp.diff !== 0 ? comp.diff : "") | number }}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined">
            <mat-card-header>
              <h2>Aid & Self-Help</h2>
            </mat-card-header>
            <mat-card-content>
              <table class="table" style="width: auto">
                <thead>
                  <th scope="col">Type</th>
                  <th scope="col">Published</th>
                  <th scope="col">Draft</th>
                  <th scope="col">Difference</th>
                </thead>
                <tbody>
                  <tr *ngFor="let comp of aidComparison | async" [ngClass]="{ 'summary-row': comp.type === 'Total Grant Aid' || comp.type === 'Net Price' }">
                    <ng-container *ngIf="!(comp.base === 0 && comp.changed === 0)">
                      <th scope="row" style="font-weight: normal">{{ comp.type }}</th>
                      <td>{{ comp.base | number }}</td>
                      <td>{{ comp.changed | number }}</td>
                      <td class="difference">{{ comp.diff > 0 ? "+" : "" }}{{ (comp.diff !== 0 ? comp.diff : "") | number }}</td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab label="Populations">
        <mat-card appearance="outlined">
          <mat-card-header>
            <h2>Populations</h2>
          </mat-card-header>
          <mat-card-content>
            <table class="table" style="width: auto">
              <thead>
                <th scope="col">Type</th>
                <th scope="col">Published</th>
                <th scope="col">Draft</th>
              </thead>
              <tbody>
                <tr *ngFor="let popComp of popComparison | async">
                  <th scope="row" style="font-weight: normal">{{ popComp.name }}</th>
                  <td>{{ popComp.base ? "yes" : "no" }}</td>
                  <td [ngClass]="{ difference: popComp.base !== popComp.changed }">{{ popComp.changed ? "yes" : "no" }}</td>
                </tr>
              </tbody>
            </table>
          </mat-card-content>
        </mat-card>
      </mat-tab>

      <mat-tab label="Packaging">
        <div class="packaging">
          <mat-card appearance="outlined">
            <mat-card-header>
              <h2>PUBLISHED - Packaging using published rules</h2>
            </mat-card-header>
            <mat-card-content>
              <table class="table">
                <thead>
                  <th scope="col">Package</th>
                  <th scope="col">Rule Population</th>
                  <th scope="col">Rule Type</th>
                  <th scope="col">Amount</th>
                  <th scope="col">FM Need</th>
                  <th scope="col">IM Need</th>
                  <th scope="col">Adjusted<br />Need</th>
                  <th scope="col">Adjusted<br />Costs</th>
                </thead>
                <tbody>
                  <ng-container *ngFor="let pack of publishedResult.packagePreview">
                    <tr *ngIf="pack.adjCoa !== null" class="group-row">
                      <th scope="row" colspan="3">{{ pack.name }} Total</th>
                      <td>{{ pack.value | number }}</td>
                      <td>{{ pack.fmNeed | number }}</td>
                      <td>{{ pack.imNeed | number }}</td>
                      <td>{{ pack.adjNeed | number }}</td>
                      <td>{{ pack.adjCoa | number }}</td>
                    </tr>
                    <tr *ngIf="pack.adjCoa === null">
                      <td>{{ pack.name }}</td>
                      <td>{{ pack.rulePopName || "All" }}</td>
                      <td>{{ pack.ruleType }}</td>
                      <td>{{ pack.ruleAmount | number }}</td>
                      <td colspan="4"></td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined">
            <mat-card-header>
              <h2>DRAFT - Packaging using draft rules</h2>
            </mat-card-header>
            <mat-card-content>
              <table class="table">
                <thead>
                  <th scope="col">Package</th>
                  <th scope="col">Rule Population</th>
                  <th scope="col">Rule Type</th>
                  <th scope="col">Amount</th>
                  <th scope="col">FM Need</th>
                  <th scope="col">IM Need</th>
                  <th scope="col">Adjusted<br />Need</th>
                  <th scope="col">Adjusted<br />Costs</th>
                </thead>
                <tbody>
                  <ng-container *ngFor="let pack of draftResult.packagePreview">
                    <tr *ngIf="pack.adjCoa !== null" class="group-row">
                      <th scope="row" colspan="3">{{ pack.name }} Total</th>
                      <td>{{ pack.value | number }}</td>
                      <td>{{ pack.fmNeed | number }}</td>
                      <td>{{ pack.imNeed | number }}</td>
                      <td>{{ pack.adjNeed | number }}</td>
                      <td>{{ pack.adjCoa | number }}</td>
                    </tr>
                    <tr *ngIf="pack.adjCoa === null">
                      <td>{{ pack.name }}</td>
                      <td>{{ pack.rulePopName || "All" }}</td>
                      <td>{{ pack.ruleType }}</td>
                      <td>{{ pack.ruleAmount | number }}</td>
                      <td colspan="4"></td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab label="Need Analysis Results">
        <app-inas-comparison [draft]="draftResult.cloudInasReponse" [published]="publishedResult.cloudInasReponse" [institutionalMethod]="(cc.draftRules | async)?.schoolProperties?.institutionalMethod"></app-inas-comparison>
      </mat-tab>
      <ng-container *ngIf="(user | async)?.admin">
        <mat-tab label="raw request">
          <div class="raw-output">
            <mat-card appearance="outlined">
              <mat-card-header>
                <h2>Published INAS Request</h2>
              </mat-card-header>
              <mat-card-content>
                <pre>{{ publishedResult.cloudInasRequest | json }}</pre>
              </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined">
              <mat-card-header>
                <h2>Draft INAS Request</h2>
              </mat-card-header>
              <mat-card-content>
                <pre>{{ draftResult.cloudInasRequest | json }}</pre>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab label="raw response">
          <div class="raw-output">
            <mat-card appearance="outlined">
              <mat-card-header>
                <h2>Published INAS Response</h2>
              </mat-card-header>
              <mat-card-content>
                <pre>{{ publishedResult.cloudInasReponse | json }}</pre>
              </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined">
              <mat-card-header>
                <h2>Draft INAS Response</h2>
              </mat-card-header>
              <mat-card-content>
                <pre>{{ draftResult.cloudInasReponse | json }}</pre>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </ng-container>
</ng-container>
