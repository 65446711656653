import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CalcConfigService } from '_services/calc-config.service';
import { Expense, ExpenseRule, ExpenseNameMap } from '../../_models/expense';
import { Table } from '../../_models/table';

import { find, isEqual } from 'lodash-es';

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.css']
})
export class CostsComponent implements OnInit, OnDestroy {
  tableName = '';
  table: Table;
  tabIndex = 0;
  editMode = false;
  schoolId = '';
  costsCopy: Expense[];
  expenseNameMap = ExpenseNameMap;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public cc: CalcConfigService
  ) {
    this.titleService.setTitle('Costs' + ' | NPC Admin');
  }

  ngOnInit() {
    this.schoolId = this.route.snapshot.parent?.paramMap.get('schoolId') ?? '';
  }

  clickedRow(rule: ExpenseRule) {
    if (rule.table) {
      this.tableName = rule.table.name;
      this.table = rule.table;
      this.tabIndex = 1;
    } else {
      this.tableName = '';
      this.tabIndex = 0;
    }

  }

  setMode(mode: 'edit' | 'save' | 'cancel') {
    switch (mode) {
      case 'edit':
        this.costsCopy = JSON.parse(JSON.stringify(this.cc.expenses.value)); // lodash has a deep clone function
        this.editMode = true;
        break;
      case 'cancel':
        this.cc.expenses.next(this.costsCopy);
        this.editMode = false;
        break;
      case 'save':
        const expenses = this.cc.expenses.value;
        expenses.forEach(exp => {
          exp.rules.forEach(rule => {
            // If tableId is 1 set it to null
            rule.tableId = (rule.tableId === '1' || !rule.tableId) ? null : rule.tableId;
            // If saving a rule with a table, zero out it's amount
            rule.amount = rule.tableId ? 0 : rule.amount;
          });
        });
        // this.addMissingExpense(expenses);
        this.cc.expenses.next(expenses);
        if (!isEqual(this.costsCopy, this.cc.expenses.value)) {
          this.cc.saveExpenses(this.schoolId, this.cc.expenses.value);
        }
        this.editMode = false;
        break;
    }
  }

  // Unused code:  Used to add a missing expense to the list of expenses
  private addMissingExpense(expenses: Expense[]) {
    const travelExpense: Expense = {
      name: 'Transportation',
      order: 4,
      rules: [
        {
          populationId: '1',
          amount: 0
        }
      ],
      type: 'Transportation'
    };
    expenses.push(travelExpense);
  }

  addRule(exp: Expense) {
    const aRule: ExpenseRule = {
      populationId: '1',
      amount: 0
    };
    exp.rules.push(aRule);
  }

  deleteRule(exp: Expense, index: number) {
    if (exp.rules.length > 1) {
      exp.rules.splice(index, 1);
    } else {
      alert('Cannot delete last rule for ' + exp.name);
    }
  }

  setRulePopulation(r: ExpenseRule) {
    r.population = r.populationId === '1' ? null : find(this.cc.populations.value, { 'id': r.populationId });
  }

  setRuleTable(r: ExpenseRule) {
    r.table = find(this.cc.tables.value, { 'id': r.tableId });
  }

  getPopulationName(popId: string): string {
    const pop = popId === '1' ? null : find(this.cc.populations.value, { 'id': popId });
    if (pop) {
      return pop.name;
    } else {
      return 'All';
    }
  }
  getTableName(tableId: string): string {
    const table = find(this.cc.tables.value, { 'tableId': tableId });
    if (table) {
      return table.name;
    } else {
      return '';
    }
  }

  ngOnDestroy() {
    if (this.editMode) {
      this.setMode('cancel');
    }
  }
}


