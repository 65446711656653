<div class="cards">
    <ng-container *ngIf="imResults.length > 0">
        <mat-card appearance="outlined">
            <mat-card-header>
                <h2>IM Results</h2>
            </mat-card-header>
            <mat-card-content>
                <ng-container *ngTemplateOutlet="tableTemplate; context: { rows: imResults }"></ng-container>
            </mat-card-content>
        </mat-card>
    </ng-container>
    <ng-container *ngIf="miscCalcs.length > 0">
        <mat-card appearance="outlined">
            <mat-card-header>
                <h2>EFM Results</h2>
            </mat-card-header>
            <mat-card-content>
                <ng-container *ngTemplateOutlet="tableTemplate; context: { rows: miscCalcs }"></ng-container>
            </mat-card-content>
        </mat-card>
    </ng-container>
</div>


<div class="cards">
    <div *ngFor="let resultType of resultTypes">
        <mat-card appearance="outlined">
            <mat-card-header>
                <h2>{{resultType.typeName}}</h2>
            </mat-card-header>
            <mat-card-content>
                <ng-container *ngTemplateOutlet="tableTemplate; context: { rows: resultType.displayObjects }"></ng-container>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<ng-template #tableTemplate let-rows="rows">
    <table class="table table-sm" style="width:auto">
        <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col" class="text-right">Published</th>
                <th scope="col" class="text-right">Draft</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let row of rows">
                <tr [ngClass]="row.class">
                    <th scope="row">{{ row.key }}</th>
                    <td [ngClass]="{ 'different': row.draftValue !== row.publishedValue }" class="text-right">{{ row.publishedValue }}</td>
                    <td [ngClass]="{ 'different': row.draftValue !== row.publishedValue }" class="text-right">{{ row.draftValue }}</td>
                </tr>
                <tr *ngIf="row.class">
                    <td colspan="3">&nbsp;</td>
                </tr>
            </ng-container>

        </tbody>
    </table>
</ng-template>