<!-- 
<mat-chip-list *ngIf="cc.usedBy | async as usedBy">
  <mat-chip [ngStyle]="{'background': getColor(item)}" [disabled]="item.disabled" selected *ngFor="let item of filteredList(usedBy)">

  </mat-chip>
</mat-chip-list> -->

<ul class="md-chips" *ngIf="cc.usedBy | async as usedBy">
  <li  class="md-chip" [ngStyle]="{'background': getColor(item)}" *ngFor="let item of filteredList(usedBy)">   
      <span class="md-chip-icon"> <img class="icon" [src]="getIcon(item)" [alt]="item.useType"></span>
    {{item.name}}</li>
</ul> 