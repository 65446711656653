import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InspectorComponent } from './_dashboard/inspector/inspector.component';
import { BrandingComponent } from './_dashboard/branding/branding.component';
import { ConfigurationComponent } from './_dashboard/configuration/configuration.component';
import { ContentComponent } from './_dashboard/content/content.component';
import { QuestionsComponent } from './_dashboard/questions/questions.component';
import { CostsComponent } from './_dashboard/costs/costs.component';
import { PackagingComponent } from './_dashboard/packaging/packaging.component';
import { PopulationsComponent } from './_dashboard/populations/populations.component';
import { TablesComponent } from './_dashboard/tables/tables.component';
import { FormulasComponent } from './_dashboard/formulas/formulas.component';
import { SuperadminComponent } from './_dashboard/superadmin/superadmin.component';
import { AuthGuard } from './_services/auth.guard';
import { NoAccessComponent } from './no-access/no-access.component';
import { QuestionsAdminComponent } from './_dashboard/questions-admin/questions-admin.component';
import { DashboardComponent } from './_dashboard/dashboard/dashboard.component';
import { SessionsComponent } from './_dashboard/sessions/sessions.component';
import { ApiErrorsComponent } from './_dashboard/api-errors/api-errors.component';
import { SchoolsComponent } from './schools/schools.component';
import { externalLoginGuard } from '_services/external-login.guard';
import { EplLoginComponent } from './epl-login/epl-login.component';
import { UserFormComponent } from './user-form/user-form.component';
import { AuthenticatedLayoutComponent } from './layouts/authenticated-layout.component';
import { RedirectGuard } from './_services/redirect.guard';
import { LoadingGuard } from './_services/loading.guard';


const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [RedirectGuard],
        children: [{ path: '', component: NoAccessComponent }]// This is a placeholder and won't be shown
    },
    { path: 'no-access', component: NoAccessComponent },
    { path: 'epl-login', component: EplLoginComponent, canActivate: [externalLoginGuard] },
    { path: 'admin-login', component: UserFormComponent },
    { path: 'schools', component: SchoolsComponent, canActivate: [AuthGuard] },
    {
        path: ':schoolId',
        component: AuthenticatedLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardComponent, canActivate: [LoadingGuard] },
            { path: 'inspector', component: InspectorComponent, canActivate: [LoadingGuard] },
            { path: 'branding', component: BrandingComponent, canActivate: [LoadingGuard] },
            { path: 'content', component: ContentComponent, canActivate: [LoadingGuard] },
            { path: 'configuration', component: ConfigurationComponent, canActivate: [LoadingGuard] },
            { path: 'questions', component: QuestionsComponent, canActivate: [LoadingGuard] },
            { path: 'costs', component: CostsComponent, canActivate: [LoadingGuard] },
            { path: 'packaging', component: PackagingComponent, canActivate: [LoadingGuard] },
            { path: 'populations', component: PopulationsComponent, canActivate: [LoadingGuard] },
            { path: 'tables', component: TablesComponent, canActivate: [LoadingGuard] },
            { path: 'formulas', component: FormulasComponent, canActivate: [LoadingGuard] },
            { path: 'superadmin', component: SuperadminComponent, canActivate: [LoadingGuard] },
            { path: 'questions-admin', component: QuestionsAdminComponent, canActivate: [LoadingGuard] },
            { path: 'api-errors', component: ApiErrorsComponent, canActivate: [LoadingGuard] },
            { path: 'sessions', component: SessionsComponent, canActivate: [LoadingGuard] },
        ]
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
