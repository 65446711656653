import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { PublishingService } from '../../_services/publishing.service';
import { filter, tap } from 'rxjs/operators';

import { User } from '../../_models/user';

@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css']
})
export class NavmenuComponent implements OnInit {

  schoolId: string;
  user: Promise<User>;

  constructor(
    private router: Router,
    private ps: PublishingService,
    public auth: AuthService
  ) {
    router.events.pipe(
      filter(e => e instanceof ActivationEnd),
      tap(e => {
        this.schoolId = e['snapshot'].params.schoolId;
      })
    )
      .subscribe();
  }

  ngOnInit() {
    this.user = this.auth.currentUser();
  }

}
